<div class="d-flex flex-column w-100">  
  <div class="row no-gutters">
    <div class="col-12 col-xl-8">
      <div class="text-decoration-none">
        <div *ngIf="smallTitle; else largeTitle">
          <h6 class="mb-0">
            {{singleNews.title}}
          </h6>
        </div>
        <ng-template #largeTitle>
          <h4 class="mb-0">
            {{singleNews.title}}
          </h4>
        </ng-template>
      </div>
    </div>
    <div class="col-12 col-xl-4 text-xl-end">
      {{singleNews.updatedAtUtc | date: 'yyyy-MM-dd' }}
    </div>
  </div>
  <ng-template [ngIf]="truncateNews" [ngIfElse]="dontTruncateNews" class="d-flex align-items-baseline">
    <div class="container p-0 mt-2" [class.showMoreText]="showMoreText" [innerHTML]="singleNews.content | safeHtml"></div>

    <div class="d-flex mt-auto mobile-btn-bar">
      <button (click)="toggleShowMore()" type="button" class="c-btn c-btn--link c-btn--small user-details-edit">
        <span class="c-btn__text">{{ showMoreText ? "Visa mindre": "Visa mer" }}</span>
      </button>
    </div>
  </ng-template>
  <ng-template #dontTruncateNews class="d-flex align-items-baseline">
    <div class="mt-2" [innerHTML]="singleNews.content | safeHtml"></div>
  </ng-template>

</div>
