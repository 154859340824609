export class DriverCategory {

    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
            return;
        }

        this.id = jsonObj.id;
        this.name = jsonObj.name;
    }

    id: number;
    name: string;

}
