import { Part } from './part.model';

export class CustomerPart extends Part{
    
    constructor(jsonObj : any = null) {
        super(jsonObj);

        if(jsonObj == null)
        {
            return;
        }
        
        this.name = jsonObj.name;        
        this.organizationNumber = jsonObj.organizationNumber;        
        this.customerId = jsonObj.customerId;
    }

    name : string;
    organizationNumber: string;
    customerId: number;
}
