export class CustomerFilterItem {
  constructor(jsonObj: any = null) {

    if (jsonObj == null) {
      return;
    }

    this.name = jsonObj.name;
    this.organizationNumber = jsonObj.organizationNumber;
    this.customerId = jsonObj.customerId;
    this.partId = jsonObj.partId;
  }

  name: string;
  organizationNumber: string;
  customerId: number;
  partId: number;
}
