import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AvailableMonthReport } from '../../../domain/models/available-month-report.model';
import { MileageReportService } from '../../../domain/services/mileage-report.service';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { DialogService } from '@core/services/dialog.service';
import { NotificationGroup } from '@domain/models/notification-group.model';
import { NotificationType } from '@domain/models/notification-type.enum';

@Component({
    selector: 'app-notification-group-card',
    templateUrl: './notification-group-card.component.html',
    styleUrls: ['./notification-group-card.component.scss']
})
export class NotificationGroupCardComponent implements OnInit {

    @Input() notificationGroup: NotificationGroup;
    @Input() showSummary: boolean = false;

    validMonthMileage: AvailableMonthReport;
    
    private _loadingHelper = new LoadingHelper();
    navigationExtras: NavigationExtras;
    navigationUrl: string;

    NotificationType = NotificationType;

    constructor(private router: Router,
        private _mileageReportService: MileageReportService,
        private _dialogService: DialogService,        
        private _route: ActivatedRoute) { }

    ngOnInit() {
        if(this.notificationGroup.type == NotificationType.Mileage)
        {
            this.getValidMileageMonth();        
        }
    }

    navigate() {

        this.navigationUrl =
            this.notificationGroup.type == NotificationType.Mileage ? 
                `/cars/mileage/${this.notificationGroup.license}/${this.notificationGroup.accountNumber}` :
                `/cars/toll/${this.notificationGroup.license}/${this.notificationGroup.accountNumber}`;
        
        this.navigationExtras = {
            state: {
                selectedMonth: this.validMonthMileage ? this.validMonthMileage.month : null,
                returnRoute: "/" + this._route.snapshot.url.map(segment => segment.path).join("/"),
                returnRouteBreadcrumb: this._route.snapshot.data["breadcrumb"]
            }
        };
        
        this.router.navigateByUrl(this.navigationUrl, this.navigationExtras);
    }

    private getValidMileageMonth() {        
        this._loadingHelper.startLoading();

        this._mileageReportService.getAvailableMonths(this.notificationGroup.license).subscribe({
            next: data => {
                this.validMonthMileage = data.find(x => x.isValid);
            },
            error: error => {
                this._loadingHelper.stopLoading();
                this._dialogService.showError(error,  "Hämta tillgängliga månader för kilometerrapportering");
            },
            complete: () => {
                this._loadingHelper.stopLoading();
            }
        });
    }   
}
