export class Contact {
  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.id = jsonObj.id;
    this.socialSecurityNumber = jsonObj.socialSecurityNumber;
    this.employeeNumber = jsonObj.employeeNumber;
    this.firstName = jsonObj.firstName;
    this.lastName = jsonObj.lastName;
    this.address1 = jsonObj.address1;
    this.address2 = jsonObj.address2;
    this.zipCode = jsonObj.zipCode;
    this.city = jsonObj.city;
    this.email = jsonObj.email;
    this.phone = jsonObj.phone;
    this.partPVSeq = jsonObj.partPVSeq;
    this.type = jsonObj.type;
    this.instructionToCustomerServices = jsonObj.instructionToCustomerServices;
    this.customerNumber = jsonObj.customerNumber;
  }

  id: number;
  socialSecurityNumber: string = null;
  employeeNumber: string = null;
  firstName: string = null;
  lastName: string = null;
  address1: string = null;
  address2: string = null;
  zipCode: string = null;
  city: string = null;
  email: string = null;
  phone: string = null;
  partPVSeq?: number = null;
  type: string = null;
  instructionToCustomerServices: string = null;
  customerNumber?: number;
}
