import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { Router } from '@angular/router';
import { UserModel } from '@core/models/user.model';
import { Permission } from '@core/models/permissions.enum';
import { Subscription } from 'rxjs';
import { RouteMetadata } from '@shared/route-metadata';
import { DialogService } from '@core/services/dialog.service';

@Component({
  selector: 'app-super-user-menu',
  templateUrl: './super-user-menu.component.html',
  styleUrls: ['./super-user-menu.component.scss']
})
export class SuperUserMenuComponent implements OnInit, OnDestroy {

  user: UserModel;
  hover: boolean;

  private _componentSubscriptions = new Array<Subscription>();
  routeMetadata = RouteMetadata;

  constructor(private _authenticationService: AuthenticationService,
    private _dialogService: DialogService,
    private _router: Router) {
  }

  ngOnInit(): void {
    this._componentSubscriptions.push(this._authenticationService.currentUser$.subscribe(u => {
      this.user = u;
    }));
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  keepOrder = (a, b) => {
    return a;
  }

  get isImpersonating() {
    return this._authenticationService.currentUserValue.isImpersonating;
  }

  get isAuthorizedToImpersonate(): boolean {
    return this._authenticationService.hasPermission(Permission.ImpersonateLogin);
  }

  get isAuthorizedToAdminister(): boolean {
    return this._authenticationService.hasAnyPermission([
      Permission.AdministreraVanligaFrågor,
      Permission.AdministreraInformationOchBlanketter,
      Permission.AdministreraNyheter,
      Permission.AdministreraSamarbetspartners,
      Permission.AdministreraAssistansbolag,
      Permission.AdministreraStatusExternKörjournalsrapportering,
      Permission.AdministreraExternaKörjournalsrapporter,
      Permission.AdministreraAllaAnvändare
    ]);
  }

  get isAuthorizedToAdministerFaq(): boolean {
    return this._authenticationService.hasPermission(Permission.AdministreraVanligaFrågor);
  }

  get isAuthorizedToAdministerForms(): boolean {
    return this._authenticationService.hasPermission(Permission.AdministreraInformationOchBlanketter);
  }

  get isAuthorizedToAdministerNews(): boolean {
    return this._authenticationService.hasPermission(Permission.AdministreraNyheter);
  }

  get isAuthorizedToAdministerAffiliates(): boolean {
    return this._authenticationService.hasPermission(Permission.AdministreraSamarbetspartners);
  }

  get isAuthorizedToAdministerAssistance(): boolean {
    return this._authenticationService.hasPermission(Permission.AdministreraAssistansbolag);
  }

  get isAuthorizedToAdministerFleetExternal(): boolean {
    return this._authenticationService.hasAnyPermission([
      Permission.AdministreraStatusExternKörjournalsrapportering,
      Permission.AdministreraExternaKörjournalsrapporter
    ]);
  }

  logout() {
    var isImpersonating = this._authenticationService.currentUserValue.isImpersonating;
    if (isImpersonating) {
      this._authenticationService.logout().subscribe({
        next: () => {

          this._authenticationService.logout().subscribe({
            next: () => {
              this._router.navigate(["/login"]);
            },
            error: (error) => {
              this._dialogService.showError(error, "Logga ut");
            },
            complete: () => {

            }
          });

        },
        error: (error) => {
          this._dialogService.showError(error, "Logga ut");
        },
        complete: () => {

        }
      });
    }
    else {
      this._authenticationService.logout().subscribe({
        next: () => {
          this._router.navigate(["/login"]);
        },
        error: (error) => {
          this._dialogService.showError(error, "Logga ut");
        },
        complete: () => {

        }
      });
    }
  }
}
