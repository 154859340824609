import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor,  FormControl,  FormGroup,  NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrameAgreement } from '@domain/models/frame-agreement.model';

@Component({
    selector: 'app-frame-agreement-selector',
    templateUrl: './frame-agreement-selector.component.html',
    styleUrls: ['./frame-agreement-selector.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          multi:true,
          useExisting: FrameAgreementSelectorComponent
        }
      ]
  })
export class FrameAgreementSelectorComponent implements OnInit, ControlValueAccessor {

    frameAgreement: FrameAgreement;
    
    touched = false;  
    disabled = false;

    formGroup: FormGroup;
    includeDisabledFormControl: FormControl<boolean>;
    frameAgreementFormControl: FormControl<FrameAgreement>;
  
    ngOnInit() {        
        this.initForm();   
      }

    get readonlyMode(){
        return this.frameAgreement !== null;
    }

    get editMode() : boolean {
        return !this.readonlyMode;
    }

    get includeDisabled() : boolean{
        return this.includeDisabledFormControl.value;
    }

    private initForm() {
        this.frameAgreementFormControl = new FormControl<FrameAgreement>(null);
        this.includeDisabledFormControl = new FormControl<boolean>(false); 
    
        this.formGroup = new FormGroup(
          {
            frameAgreement: this.frameAgreementFormControl,
            includeDisabled: this.includeDisabledFormControl
        });
      }
    
    
    writeValue(obj: any): void {
        if(obj instanceof (FrameAgreement)) {
            this.frameAgreement = obj as FrameAgreement;
        }
        else{
            this.frameAgreement = null;
        }        
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange = (frameAgreement) => { };

    onTouched = () => { };

    resetFrameAgreement(){
        this.markAsTouched();
        this.frameAgreement = null;
        this.onChange(null);    
    }

    onSelected(frameAgreement: FrameAgreement) {   
        this.markAsTouched();
        this.frameAgreement = frameAgreement;
        this.onChange(frameAgreement);    
    }   
    
    markAsTouched() {
        if (!this.touched) {
          this.onTouched();
          this.touched = true;
        }
      }
}