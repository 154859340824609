import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray,  FormControl, Validators, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { HeroModel } from '@domain/models/hero.model';
import { HeroService } from '@domain/services/hero.service';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subscription} from 'rxjs';
import { CustomValidators } from '@shared/validators/custom-validators';
import { UserService } from '@domain/services/user.service';
import { FleetSecurityResponse } from '@domain/models/fleet-security-response.model';
import { FleetWebUserDetails } from '@domain/models/fleetweb-user-details.model';
import { CreateFleetWebUserRequest } from '@domain/models/create-fleetweb-user-request.model';
import { UpdateFleetWebUserRequest } from '@domain/models/update-fleetweb-user-request.model';
import { FrameAgreementService } from '@domain/services/frame-agreement.service';
import { FrameAgreementDetails } from '@domain/models/frame-agreement-details.model';
import { UserOrganizationInfo } from '@domain/models/user-organization-info.model';
import { FrameAgreement } from '@domain/models/frame-agreement.model';
import { EditUserOrganizationCostcentersComponent } from '../edit-user-organization-costcenters/edit-user-organization-costcenters.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectedCustomer } from '@domain/models/selected-customer.model';
import { FleetWebUserQuery } from '@domain/models/fleetweb-user-query.model';

@Component({
  selector: 'app-new-or-edit-customer-user',
  templateUrl: './new-or-edit-customer-user.component.html',
  styleUrls: ['./new-or-edit-customer-user.component.scss']
})
export class NewOrEditCustomerUserComponent implements OnInit, OnDestroy {

  heroModel: HeroModel;

  userId: string = null;
  customerId: number = null;

  user: FleetWebUserDetails;
    
  formGroup: FormGroup;
  userNameFormControl: FormControl<string>;
  frameAgreementFormControl: FormControl<FrameAgreement>;
  rolesFormArray: FormArray<FormControl<boolean>>;
  customersFormControl: FormControl<Array<SelectedCustomer>>;
    
  @Input() 
  public limited = true;

  private _loadingHelper = new LoadingHelper();
  private _componentSubscriptions = new Array<Subscription>();

  constructor(
    private _heroService: HeroService,
    private _userService : UserService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    private _router: Router) {

    this.userId = this._route.snapshot.paramMap.get('userId');
    this.customerId = this._route.snapshot.paramMap.get('customerId') ? parseInt(this._route.snapshot.paramMap.get('customerId')) : null;

    this.initHero();
  }

  ngOnInit() {
    this._componentSubscriptions.push(this._heroService.clickEvent.subscribe(event => {
      this.onDeleteUser(event);
    }));
        
    this._componentSubscriptions.push(this._heroService.thirdClickEvent.subscribe(event => {
      this.onResetPassword(event);
    }));

    this.initForm();   
    this.getUser(); 
  }
  
  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);  
  }

  get isLoading() {
    return this._loadingHelper.isLoading;
  }

  private initHero() {
    this.heroModel = new HeroModel();
    this.heroModel.title = "Användare";
    this.heroModel.subtitle = this.isNewUser ? "Skapa ny" : "Redigera";
    this._heroService.set(this.heroModel);
    if(!this.isNewUser) {
      this._heroService.setButton("Ta bort");
      this._heroService.setThirdButton("Återställ lösenord");
    }
  }

  get emailInvalid() : boolean{
    return this.emailRequiredInvalid || this.emailPatternInvalid;
  }

  get emailRequiredInvalid(): boolean {
    return this.userNameFormControl.errors && this.userNameFormControl.errors.required && this.userNameFormControl.dirty;
  }
  
  get emailPatternInvalid(): boolean {
    return this.userNameFormControl.errors && this.userNameFormControl.errors.email && this.userNameFormControl.dirty;
  }

  get emailDifferentFromContactAddress(): boolean{
    var userName = this.userNameFormControl.value;
    return this.user && userName != null && userName != "" && this.user.fleetContactEmailAddress != this.userNameFormControl.value;
  }

  get frameAgreementDiffersFromBusinessSystem(): boolean{
    var selectedFrameAgreement = this.frameAgreementFormControl.value;
    return this.user &&
      selectedFrameAgreement != null && 
      this.user.businessSystemQuotaAccountId != null &&
      this.user.businessSystemQuotaAccountId != selectedFrameAgreement.quotaAccountId;
  }

  get rolesInvalid() : boolean {
    return this.rolesFormArray.errors && this.rolesFormArray.dirty;
  }

  get frameAgreementInvalid() : boolean {
    return this.frameAgreementFormControl.errors && this.frameAgreementFormControl.dirty;
  }
  
  get customersInvalid() : boolean {
    return this.customersFormControl.errors && this.customersFormControl.dirty;
  }

  get rolesDescription() {
    return CustomerRolesDescription.customerRolesDescription;
  }

  get isNewUser() : boolean{
    return this.userId === null;
  }

  get isLockedOut(): boolean{
    return this.user && this.user.isLockedOut;
  }

  get submitDisabled(): boolean{
    return !this.formGroup.dirty;
  }
 
  get hasElevatedRole() {
    var currentRoles = this.getCurrentRoles();
    return currentRoles.filter (cr => CustomerRolesDescription.elevatedRolesDescription.filter(er => er.description == cr).length > 0).length > 0;    
  }

  get frameAgreementId(){
    return this.frameAgreementFormControl.value?.id;
  }
 
  private initForm() {

    this.userNameFormControl = new FormControl<string>("", { validators: [Validators.required, Validators.email], updateOn: 'blur' });
    this.rolesFormArray = new FormArray<FormControl<boolean>>([], [CustomValidators.atLeastOneSelectedCheckbox]);
    this.frameAgreementFormControl = new FormControl<FrameAgreement>(null, { validators: [Validators.required]});
    this.customersFormControl = new FormControl<Array<SelectedCustomer>>(new Array<SelectedCustomer>());
    
    this.rolesDescription.forEach(() => {      
      this.rolesFormArray.push(new FormControl<boolean>(false));
    });

    this._componentSubscriptions.push(this.frameAgreementFormControl.valueChanges.subscribe(value=> {
      this.customersFormControl.setValue(new Array<SelectedCustomer>());
    }));
    
    this._componentSubscriptions.push(this.rolesFormArray.valueChanges.subscribe(value => {
      this.customersFormControl.clearValidators();
      if(this.hasElevatedRole)
      {
        this.customersFormControl.addValidators(CustomValidators.minLengthArray(1));
      }
    }));

    this.formGroup = new FormGroup(
      {
        userName: this.userNameFormControl,
        roles: this.rolesFormArray,
        frameAgreement: this.frameAgreementFormControl,
        customers: this.customersFormControl
    });
  }

  private patchForm(user : FleetWebUserDetails) {
    
    if(user.userId) {
      this.userNameFormControl.setValue(user.userName);
    }
    else{
      this.userNameFormControl.setValue(user.fleetContactEmailAddress);
    }

    if(user.frameAgreementId != null){
      var frameAgreement = new FrameAgreement();
      frameAgreement.id = user.frameAgreementId;
      frameAgreement.name = user.frameAgreementName;
      frameAgreement.enabled = user.frameAgreementEnabled;
      frameAgreement.quotaAccountName = user.frameAgreementQuotaAccountName;
      frameAgreement.quotaAccountId = user.frameAgreementQuotaAccountId;
      this.frameAgreementFormControl.setValue(frameAgreement);
     
      var selectedCustomers = this.user.userOrganizationInfo.map(userOrganizationInfo => {
        var selectedCustomer = new SelectedCustomer();
        selectedCustomer.customerId = userOrganizationInfo.customerId;
        selectedCustomer.selectedCostcenterIds = userOrganizationInfo.costCenterIds.map(ccid => ccid);
        return selectedCustomer;
      });

      this.customersFormControl.setValue(selectedCustomers);
    } else {
      this.frameAgreementFormControl.setValue(null);
      this.customersFormControl.setValue(new Array<SelectedCustomer>());
    }

    this.rolesDescription.forEach((role, index) => {
      var findIndex = user.roles.findIndex(r => r == role.description); 
      this.rolesFormArray.controls[index].setValue(findIndex !== -1);
    });
  }


  onUnlock(event: any){
    event.preventDefault();
    event.stopPropagation();

    this._loadingHelper.startLoading();
      this._userService.unlock(this.userId).subscribe({
        next: () => {   
          this.user.isLockedOut = false;   
          this._dialogService.showInfoMessage("Användare upplåst", "Användaren kan nu logga in igen.");    
        },
        error: (error) => {
          this._loadingHelper.stopLoading();
          this._dialogService.showError(error, "Lås upp användare", false);
        },
        complete: () => {
          this._loadingHelper.stopLoading();
        }
      });    
  }

  onSubmit() {        
    if (!this.formGroup.valid) {
      this.userNameFormControl.markAsDirty();
      this.rolesFormArray.markAsDirty();
      this.frameAgreementFormControl.markAsDirty();
      this.customersFormControl.markAsDirty();
      return;
    }        
    
    if (this.user.userId) {
      this.updateUser();
    }
    else {
      this.createUser();
    }
  }

  private onDeleteUser(event: any){
    const modalRef = this._dialogService.showConfirm(
      "Ta bort användare",
      "Är du säker på att du vill ta bort användaren?",
      "Ta bort",
      "Avbryt");

    modalRef.result.then(result => {
      if (result === true)
        this.deleteUser();
    });
  }

  private onResetPassword(event: any){
    if(this.formGroup.dirty){
      this._dialogService.showInfoMessage("Återställ lösenord","Du måste spara dina ändringar innan du kan återställa lösenordet.");
      return;
    }

    var message = "Är du säker på att du vill återställa lösenordet?";
    var hasElevatedRole = this.getCurrentRoles().filter(r => 
      CustomerRolesDescription.elevatedRolesDescription.filter(er => 
        er.description === r).length > 0).length > 0 ;
    
    if(hasElevatedRole && !this.user.hasMobileNumber) {
      message = "Användaren saknar mobilnummer i affärssystemet. Återställningskod kommer att skickas via e-post. " + message;
    }

    const modalRef = this._dialogService.showConfirm(
      "Återställ lösenord",
      message,
      "Återställ",
      "Avbryt");

    modalRef.result.then(result => {
      if (result === true)
        this.resetPassword();
    });
  }

  private getCurrentRoles(){
    var roles = new Array<string>();
    this.rolesFormArray.controls.forEach((control, index) => {
      if (control.value) {
        var role = this.rolesDescription[index].description;
        roles.push(role);
      }
    });
    return roles;
  }

  private getUser() {
    this._loadingHelper.startLoading();
    
    var query = new FleetWebUserQuery();
    query.userId = this.userId;
    query.customerId = this.customerId;

    this._userService.get(query).subscribe({
      next: data => {
        this.user = data;
        this.patchForm(this.user);
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Hämta användare", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
 
  private resetPassword(){
    this._loadingHelper.startLoading();
    this._userService.resetPassword(this.userId).subscribe({
      next: (result : FleetSecurityResponse) => {
        if(result.success) {
          this._dialogService.showInfoMessage("Återställ lösenord", result.message);
        }
        else {
          this._dialogService.showErrorMessage("Återställ lösenord", result.message);
        }
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Återställ lösenord", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private createUser(){
    this._loadingHelper.startLoading();
    
    var request = new CreateFleetWebUserRequest();
    request.userName = this.userNameFormControl.value;
    request.roles = this.getCurrentRoles();
    request.customerId = this.user.customerId;
    request.frameAgreementId = this.frameAgreementFormControl.value.id;
    if(this.hasElevatedRole){
      request.userOrganizationInfo = this.customersFormControl.value.map(selectedCustomer => {
        var userOrganizationInfo = new UserOrganizationInfo;
        userOrganizationInfo.customerId = selectedCustomer.customerId;
        userOrganizationInfo.costCenterIds = selectedCustomer.selectedCostcenterIds;
        return userOrganizationInfo;
      });
    }
    else{
      request.userOrganizationInfo = new Array<UserOrganizationInfo>();
    }

    this._userService.create(request).subscribe({
      next: (result : FleetSecurityResponse) => {
       if(result.success) {
          this._dialogService.showInfoMessage("Skapa användare", result.message);  
          this._router.navigate([`../../edit/${result.userId}`], {relativeTo: this._route});        
        }
        else {
          this._dialogService.showErrorMessage("Skapa användare", result.message);
        }
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Skapa användare", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });    
  }

  private updateUser(){
    this._loadingHelper.startLoading();
    
    var request = new UpdateFleetWebUserRequest();
    request.userId = this.userId;
    request.userName = this.userNameFormControl.value;
    request.roles = this.getCurrentRoles();
    request.frameAgreementId = this.frameAgreementFormControl.value.id;
    request.customerId = this.user.customerId;
    if(this.hasElevatedRole){
      request.userOrganizationInfo = this.customersFormControl.value.map(selectedCustomer => {
        var userOrganizationInfo = new UserOrganizationInfo;
        userOrganizationInfo.customerId = selectedCustomer.customerId;
        userOrganizationInfo.costCenterIds = selectedCustomer.selectedCostcenterIds;
        return userOrganizationInfo;
      });
    }
    else{
      request.userOrganizationInfo = new Array<UserOrganizationInfo>();
    }
    
    this._userService.update(request).subscribe({
      next: () => {
        this.formGroup.markAsPristine();
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Spara användare", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });      
  }

  private deleteUser(){
    this._loadingHelper.startLoading();
    this._userService.deleteUser(this.userId).subscribe({
      next: () => {
         var modalRef = this._dialogService.showInfoMessage("Ta bort användare", "Användaren har tagits bort.");
         modalRef.result.then(() => {
          this._router.navigate(["../../"], {relativeTo: this._route});
        });
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._dialogService.showError(error, "Ta bort användare", false);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
}

export enum CustomerRole {
  Driver = 0,
  FleetManager = 1,
  AssistantFleetManager = 2,
  Payroll = 3,
  Accounting = 4 
}

export class CustomerRolesDescription {

  description: string;
  value: CustomerRole;

  private constructor(description: string, value: CustomerRole){
    this.description = description;
    this.value = value;
  }
    
  static customerRolesDescription: Array<CustomerRolesDescription> = [
    new CustomerRolesDescription('Driver', CustomerRole.Driver),
    new CustomerRolesDescription('FleetManager', CustomerRole.FleetManager),
    new CustomerRolesDescription('AssistantFleetManager', CustomerRole.AssistantFleetManager),
    new CustomerRolesDescription('Payroll', CustomerRole.Payroll),
    new CustomerRolesDescription('Accounting', CustomerRole.Accounting)
  ];

  static elevatedRolesDescription: Array<CustomerRolesDescription> = [
    new CustomerRolesDescription('FleetManager', CustomerRole.FleetManager),
    new CustomerRolesDescription('AssistantFleetManager', CustomerRole.AssistantFleetManager),
    new CustomerRolesDescription('Payroll', CustomerRole.Payroll),
    new CustomerRolesDescription('Accounting', CustomerRole.Accounting)
  ];
}
