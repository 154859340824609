export enum Severity {
    Error,
    Warning,
    Information
}

export class UserValidationProblem{
    constructor(jsonObject: any) {
        this.message = jsonObject.message;
        this.severity = jsonObject.severity;        
      }
    
      message: string;
      severity: Severity;
}