import { FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export class CustomValidators {
  static atLeastOneSelectedCheckbox(formArray: FormArray<FormControl<boolean>>): ValidationErrors | null {
    const selectedCount = formArray.controls
      .map(control => control.value)
      .reduce((previous, checked) => checked ? previous + 1 : previous, 0);

    return selectedCount > 0 ? null : { atLeastOneSelectedCheckbox: true };
  }

  public static minLengthArray(min: number) : ValidatorFn {
    return (control: FormControl) : ValidationErrors | null => {
        if (control.value.length >= min)
            return null;

        return { 
          'minLengthArray': {valid: false }
        };
    }
}

  //static notDirtyOrValidValidator(): ValidatorFn {
  //  const validator: ValidatorFn = (control: FormControl<string>) => {
  //    console.log("notDirtyOrValidValidator:control", control);

  //    return !control.dirty || control.value.length > 0 ? null : { dirtyOrNotValid: true };
  //  };

  //  console.log("notDirtyOrValidValidator:validator", validator);
  //  return validator;
  //}
}
