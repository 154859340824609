import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(array: any, searchKeyword: string, specificFields: string[]) {
        if (searchKeyword !== undefined && searchKeyword.length >= 1) {
            searchKeyword = searchKeyword.toLowerCase();
            if (typeof array[0] === 'string') {
                array = array.filter(function (el: any) {
                    return el.toLowerCase().indexOf(searchKeyword) > -1;
                });
            }
            else if (specificFields !== undefined) {
                let filteredArray = [];

                // Add all hits for every field into a new array
                specificFields.forEach(x => {
                    let filteredByField = array.filter(function (el: any) {
                        return (el[x]+'').toLowerCase().indexOf(searchKeyword) > -1;
                    });

                    if (filteredByField.length > 0) {
                        filteredByField.forEach(item => {
                            filteredArray.push(item);
                        });
                    }
                })

                // Remove duplicates
                array = filteredArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });
            }
        }

        return array;
    }
}