import { IValidation } from './validation.interface';

export class DealerValidation implements IValidation {

    SellerName : Array<string>;
    SellerPhone : Array<string>;
    SellerEmail : Array<string>;
    DealerPartPVSeq : Array<string>;

    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.SellerName = jsonObj.SellerName;
            this.SellerPhone = jsonObj.SellerPhone;
            this.SellerEmail = jsonObj.SellerEmail;
            this.DealerPartPVSeq = jsonObj.DealerPartPVSeq;
        }
    }

    get isValid(){
        return this.errors.length === 0; 
    }

    get errors(){
        var errors = new Array<string>();   
                          
        if(this.DealerPartPVSeq)
        {
            this.DealerPartPVSeq.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.SellerName)
        {
            this.SellerName.forEach(mess => {
                errors.push(mess);
            });
        }
                
        if(this.SellerPhone)
        {
            this.SellerPhone.forEach(mess => {
                errors.push(mess);
            });
        }
                
        if(this.SellerEmail)
        {
            this.SellerEmail.forEach(mess => {
                errors.push(mess);
            });
        }      
        
        return errors;
    }
}

