<ng-container *ngIf="hasValidRoute">
  <li>
    <ng-container *ngIf="routerLinkActive; else: renderNonSuperuserMenu">
      <a routerLink="{{route.value.data.routerLink}}" routerLinkActive="super-user-icon-active" class="super-user-icon">
        <i class="c-icon--small fa c-icon {{route.value.data.icon}}"></i>
        <span class="super-user-icon-text" [hidden]="!hover">{{route.value.data.title}}</span>
      </a>
    </ng-container>

    <ng-template #renderNonSuperuserMenu>
      <a class="c-mega-menu__link c-mega-menu__link--level-one" routerLink="{{route.value.data.routerLink}}" (click)="closeMenu()" tabindex="0">
        <ng-container *ngIf="route.value.data.icon && route.value.data.icon !== ''; else: renderWithoutIcon">
          <span class="o-media o-media--small o-media--middle">
            <span class="o-media__img">
              <i class="c-mega-menu__icon fa c-icon {{route.value.data.icon}} c-icon--small"></i>
            </span>
            <span class="o-media__body">
              <p>{{route.value.data.title}}</p>
            </span>
          </span>
        </ng-container>

        <ng-template #renderWithoutIcon>
          <span>{{route.value.data.title}}</span>
          <ng-container *ngIf="route.value.data.badgeCounter && route.value.data.badgeCounter !=='' && route.value.data.badgeCounter !== undefined && route.value.data.badgeCounter !== null">
            <span *ngIf="badgeCount > 0" class="badge {{route.value.data.badgeColor}} badge-round">{{badgeCount}}</span>
          </ng-container>
        </ng-template>

      </a>
    </ng-template>
  </li>
</ng-container>