import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ExternalReportProcessHistoryStatus } from '@domain/models/external-report-process-history.model';

@Pipe({
  name: 'externalreportstatus'
})
export class ExternalReportStatusPipe implements PipeTransform {

  constructor(private dom: DomSanitizer) { }
  transform(status: ExternalReportProcessHistoryStatus): any {
    switch (status) {
      case null:
        return this.dom.bypassSecurityTrustHtml(`<i class="fa c-icon c-icon--[clock] c-icon--small"></i>`);
      case ExternalReportProcessHistoryStatus.Success:
        return this.dom.bypassSecurityTrustHtml(`<i class="fa c-icon c-icon--[checkmark-outline] c-icon--small" style="color: #0f5132;"></i>`);
      case ExternalReportProcessHistoryStatus.Deleted:
        return this.dom.bypassSecurityTrustHtml(`<i class="fa c-icon c-icon--[bin] c-icon--small" style="color: #055160;"></i>`);
      case ExternalReportProcessHistoryStatus.Denied:
        return this.dom.bypassSecurityTrustHtml(`<i class="fa c-icon c-icon--[attention] c-icon--small" style="color: #842029;"></i>`);
      case ExternalReportProcessHistoryStatus.Pending:
        return this.dom.bypassSecurityTrustHtml(`<i class="fa c-icon c-icon--[calendar] c-icon--small" style="color: #664d03;"</i>`);
      default:
        return this.dom.bypassSecurityTrustHtml(``);
    }
  }
}
