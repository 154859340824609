import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ApplicationError } from '@core/models/application-error.model';
import { AuthenticationService } from '@core/services/authentication.service';
import { ForceLogoutRequestModel } from '@domain/models/force-logout-request.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-force-logut',
  templateUrl: './force-logut.component.html',
  styleUrls: ['./force-logut.component.scss']
})
export class ForceLogoutComponent implements OnInit, OnDestroy {
  form: FormGroup;
  setOfflineControl: FormControl<boolean>;
  errorMessage: string;
  
  private _componentSubscriptions = new Array<Subscription>();

  constructor(
    public modal: NgbActiveModal,
    private _authenticationService: AuthenticationService,
    private _formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  initForm() {
    this.setOfflineControl = this._formBuilder.control(false);

    this.form = this._formBuilder.group({
      offline: this.setOfflineControl
    });

    this._componentSubscriptions.push(this.setOfflineControl.valueChanges.subscribe((value) => {
      this.errorMessage = "";
    }));
  }

  ForceLogout() {    
    var request = new ForceLogoutRequestModel();
    request.setModuleOffline = this.setOfflineControl.value;

    this._authenticationService.forceLogout(request).subscribe({
      next: () => {
          this.modal.close(true);          
      },
      error: (error) => {
        this.errorMessage = ApplicationError.getMessage(error);
      },
      complete: () => {

      }
    });
  }
}
