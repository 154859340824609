import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PaginationComponent, PaginationSettings } from '../pagination/pagination.component';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { DialogService } from '@core/services/dialog.service';
import { UserService } from '@domain/services/user.service';
import { QueryResult } from '@domain/models/query-result.model';
import { FrameAgreement } from '@domain/models/frame-agreement.model';
import { SearchFleetWebUserQuery } from '@domain/models/filters/search-fleetweb-user-query.model';
import { FleetWebUserInfo } from '@domain/models/fleetweb-user-info.model';
import { HeroModel } from '@domain/models/hero.model';
import { HeroService } from '@domain/services/hero.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FrameAgreementSearchComponent } from '../frame-agreement-search/frame-agreement-search.component';
import { AuthenticationService } from '@core/services/authentication.service';
import { Permission } from '@core/models/permissions.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForceLogoutComponent } from './force-logout/force-logut.component';

@Component({
  selector: 'app-customer-user-search',
  templateUrl: './customer-user-search.component.html',
  styleUrls: ['./customer-user-search.component.scss']
})
export class CustomerUserSearchComponent implements OnInit, OnDestroy {
  @ViewChild(PaginationComponent) pagination: PaginationComponent;
  @ViewChild(FrameAgreementSearchComponent) frameAgreementSearchComponent: FrameAgreementSearchComponent;

  @Input() limited: boolean;
  @Input() editUrl: string;
  @Input() newUrl: string;

  heroModel: HeroModel;
  form: UntypedFormGroup;
  
  includeDisabledFormControl: FormControl<boolean>;
  firstNameControl: FormControl<string>;
  lastNameControl: FormControl<string>;
  socialSecurityNumberControl: FormControl<string>;
  emailControl: FormControl<string>;
  existingFormControl: FormControl<boolean>;
    
  private _selectedFrameAgreement: FrameAgreement = null;

  private _searchFleetWebUsersQuery = new SearchFleetWebUserQuery();
  private _searchResult: QueryResult<FleetWebUserInfo> = null;

  private _componentSubscriptions = new Array<Subscription>();
  private _searchLoadingHelper = new LoadingHelper();

  paginationSettings = new PaginationSettings(0, 1, 10);

  constructor(  
    private _heroService: HeroService,  
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _localStorageService: LocalStorageService,
    private _modalService: NgbModal,
    private _dialogService: DialogService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute) { 
    
      this.initHero();
  }

  ngOnInit() {   
    this.initFilterModel();
    this.initForm();    
    this.performSearch();    
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
        s.unsubscribe();
      });
      this._componentSubscriptions.splice(0);  
  }

  private initHero() {
    this.heroModel = new HeroModel();
    this.heroModel.title = "Användare";
    this.heroModel.subtitle = "Översikt";
    
    var canForceLogout = this._authenticationService.hasPermission(Permission.LoggaUtAnvändare);
    if(canForceLogout)
    {
      this.heroModel.buttonText = "Logga ut alla användare";
    }

    this._heroService.set(this.heroModel);    

    if(canForceLogout){
      this._componentSubscriptions.push(this._heroService.clickEvent.subscribe(() => {
        const modalRef = this._modalService.open(
          ForceLogoutComponent,
          { backdrop: "static", size: "sm", animation: false }
        ); 
        
        modalRef.result.then(result => {        
          if(result)
            this._authenticationService.handleUnAuthorizedUser();
        });
      }));
    }
  }

  private initFilterModel() {    
    var savedQuery = this.limited ? this._localStorageService.searchCompanyFleetWebUserQuery : this._localStorageService.searchFleetWebUserQuery;    
    this._searchFleetWebUsersQuery = savedQuery == null ? new SearchFleetWebUserQuery() : savedQuery;
    this.paginationSettings.page = this._searchFleetWebUsersQuery.page;
    this.paginationSettings.pageSize = this._searchFleetWebUsersQuery.pageSize;    

    if(this._searchFleetWebUsersQuery.frameAgreementId)
    {
      var selectedFrameAgreement = new FrameAgreement();
      selectedFrameAgreement.id = this._searchFleetWebUsersQuery.frameAgreementId;
      selectedFrameAgreement.name = this._searchFleetWebUsersQuery.frameAgreementName;
      selectedFrameAgreement.quotaAccountName = this._searchFleetWebUsersQuery.frameAgreementQuotaAccountName;
      this._selectedFrameAgreement = selectedFrameAgreement;
    }    
  }

  private initForm() {
    this.includeDisabledFormControl = new FormControl<boolean>(this._searchFleetWebUsersQuery.includeDisabledFrameAgreements); 
    this.firstNameControl = new FormControl<string>(this._searchFleetWebUsersQuery.firstName);
    this.lastNameControl = new FormControl<string>(this._searchFleetWebUsersQuery.lastName);
    this.socialSecurityNumberControl = new FormControl<string>(this._searchFleetWebUsersQuery.socialSecurityNumber);
    this.emailControl = new FormControl<string>(this._searchFleetWebUsersQuery.email);
    this.existingFormControl = new FormControl<boolean>(this._searchFleetWebUsersQuery.existingUsersOnly);
    
    this.form = this._formBuilder.group({
      includeDisabled: this.includeDisabledFormControl,
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      socialSecurityNumber : this.socialSecurityNumberControl,
      email: this.emailControl,
      existing: this.existingFormControl
    });    
  }

  public get isLoading(): boolean{
    return this._searchLoadingHelper.isLoading;
  }

  public get users() : Array<FleetWebUserInfo>{
    return this._searchResult?.result;
  }

  public get existing(): boolean{
    return this.existingFormControl.value;
  }

  public get showDisclaimer(): boolean{
    return this.limited && !this.existing;
  }

  public get showSuperUserDisclaimer(): boolean{
    return !this.limited && this.existingFormControl.value == false && this._selectedFrameAgreement != null;
  }

  public get frameAgreement(): FrameAgreement{
    return this._selectedFrameAgreement;
  }

  get includeDisabled(): boolean{
    return this.includeDisabledFormControl.value;
  }

  public getCreateOrEditDisabled(user: FleetWebUserInfo){
    return user.userId === null && (user.userName === '' || user.userName == null || user.hasConflictingFrameAgreements);
  }

  public onCreateOrEdit(user: FleetWebUserInfo){
    if(user.userId != null) {
       this._router.navigate([`edit/${user.userId}`], { relativeTo: this._route });
    }
    else{
      this._router.navigate([`new/${user.customerId}`], { relativeTo: this._route });
    }
  }

  public getTitle(user: FleetWebUserInfo){
    if(user.userId !== null){
      return "Redigera användare";
    }
    else if(user.userName === '' || user.userName == null) {
      return "Fleet kontaktadress e-post saknas i affärssystemet.";
    }
    else if(user.hasConflictingFrameAgreements){
      return "Personen ligger på flera ramavtal i affärssystemet."
    }
    else{
      return "Skapa användare";
    }
  }
  
  public onReset(){
    this.form.reset();
    this.frameAgreementSearchComponent.clear();
    this.existingFormControl.setValue(true);
    this.includeDisabledFormControl.setValue(false);
  }
  
  public onSubmit(){
    this._searchFleetWebUsersQuery.page = 1;

    this._searchFleetWebUsersQuery.firstName = this.firstNameControl.value?.trim();
    this._searchFleetWebUsersQuery.email = this.emailControl.value?.trim();
    this._searchFleetWebUsersQuery.lastName = this.lastNameControl.value?.trim();
    this._searchFleetWebUsersQuery.existingUsersOnly = this.existingFormControl.value;
    this._searchFleetWebUsersQuery.frameAgreementId = this._selectedFrameAgreement?.id;
    this._searchFleetWebUsersQuery.socialSecurityNumber = this.socialSecurityNumberControl.value?.trim();
    this._searchFleetWebUsersQuery.includeDisabledFrameAgreements = this.includeDisabledFormControl.value;
    
    // Only used in client:
    this._searchFleetWebUsersQuery.frameAgreementName = this._selectedFrameAgreement?.name;
    this._searchFleetWebUsersQuery.frameAgreementQuotaAccountName = this._selectedFrameAgreement?.quotaAccountName;
        
    this.performSearch();
  }
 
  onFrameAgreementSelected(frameAgreement: FrameAgreement) {    
    this._selectedFrameAgreement = frameAgreement;    
  }
 
  onPageChanged(page: number) {
    this._searchFleetWebUsersQuery.page = page;
    this.performSearch();
  }

  onPageSizeChanged(pageSize: number) {
     this._searchFleetWebUsersQuery.pageSize = pageSize;
     this._searchFleetWebUsersQuery.page = 1;
     this.performSearch();
  }

  performSearch() {    
    this._searchLoadingHelper.startLoading();
    
    if(this.limited){
      this._localStorageService.searchCompanyFleetWebUserQuery = this._searchFleetWebUsersQuery;
    }
    else{
      this._localStorageService.searchFleetWebUserQuery = this._searchFleetWebUsersQuery;
    }

    this._userService.search(this._searchFleetWebUsersQuery).subscribe({
      next: (result) => {        
        this._searchResult = result;     
        this.paginationSettings = new PaginationSettings(result.total, this._searchFleetWebUsersQuery.page, this._searchFleetWebUsersQuery.pageSize);        
      },
      error: (error) => {
        this._searchLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Sök kundernas användare");
      },
      complete: () => {
        this._searchLoadingHelper.stopLoading();
      }
    });
  }
}
