<form [formGroup]="formGroup">
  <div class="mb-2 c-input focus-within">
    <input formControlName="search"
           type="text"
           class="c-input__input focus-within"
           [class.is-invalid]="searchFailed"
           [ngbTypeahead]="search"
           [resultTemplate]="resultTemplate"
           [inputFormatter]="inputFormatter"
           #instance="ngbTypeahead"
           [placeholder]="placeholderText" />
    <button class="c-input__addon c-input__addon--no-background" (click)="clear()">
      <i class="c-icon c-icon--[control-cross-filled]"></i>
    </button>
    <span class="c-input__addon c-input__addon-start c-input__addon--no-background c-input__addon-start--no-background">
      <i class="search-icon"></i>
    </span>
  </div>

  <ng-template #resultTemplate let-r="result" let-t="term">
    <ngb-highlight [result]="inputFormatter(r)" [term]="t" [class.line-through]="strikeThroughText(r)">
    </ngb-highlight>
    <div class="mt-4" *ngIf="isLastItemInList(r)">      
      <i>
        {{ resultText }} <span *ngIf="showRefineSearch">Förfina gärna din sökning.</span>
      </i>
    </div>
  </ng-template>

  <small class="m-0">{{searchInstruction}}</small>
  <strong *ngIf="searching">&nbsp; Söker...</strong>
  <small *ngIf="!searching && searchEmpty" class="text-red">Inga sökträffar.</small>
  <small *ngIf="!searching && searchFailed" class="text-red">Sökningen misslyckades.</small>
</form>
