import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { UserModel } from '@core/models/user.model';
import { BaseApiService } from '@core/services/base-api.service';
import { LoginModel } from '@domain/models/login.model';
import { SessionStorageService } from '@core/services/session-storage.service';
import { ResetPasswordModel } from '@domain/models/reset-password.model';
import { ChangePasswordModel } from '@domain/models/change-password.model';
import { Permission } from '@core/models/permissions.enum';
import { LocalStorageService } from './local-storage.service';
import { NotificationService } from '@domain/services/notification.service';
import { ForceLogoutRequestModel } from '@domain/models/force-logout-request.model';
import { Router } from '@angular/router';
import { DialogService } from './dialog.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public static LogoutUrl = "Authentication/Logout";

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser$: Observable<UserModel>;
  
  constructor(private _apiService: BaseApiService,
    private _router: Router,
    private _dialogService: DialogService,  
    private _sessionStorageService: SessionStorageService,
    private _localStorageService: LocalStorageService, 
    private _notificationService: NotificationService,
    private _loggingService: LoggingService) {

    this.currentUserSubject = new BehaviorSubject<UserModel>(_sessionStorageService.currentUser);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  login(loginModel: LoginModel): Observable<any> {
    this._localStorageService.clearAll();

    return this._apiService.post("Authentication/Login", loginModel).pipe(
      map(data => {
        if (data.requireNewPassword || data.isPasswordExpired) {
          return data;
        }

        let user: UserModel = new UserModel(data);
        this.updateCurrentUser(user);
        return data;
      }));
  }

  impersonateLogin(username: string): Observable<any> {
    return this._apiService.post("Authentication/ImpersonateLogin", username).pipe(
      map(data => {
        let user: UserModel = new UserModel(data);
        this.updateCurrentUser(user);
        return data;
      })
    );
  }

  requestPasswordReset(userName: string): Observable<any> {
    return this._apiService.post("Authentication/RequestPasswordReset", userName);
  }

  changePassword(changePasswordModel: ChangePasswordModel): Observable<any> {
    return this._apiService.post("Authentication/ChangePassword", changePasswordModel);
  }

  resetPassword(resetPasswordModel: ResetPasswordModel): Observable<any> {
    return this._apiService.post("Authentication/ResetPassword", resetPasswordModel);
  }

  getPasswordValidationInfo(): Observable<any> {
    return this._apiService.get("Authentication/PasswordValidation");
  }

  validateCredentials(loginModel: LoginModel): Observable<any> {
    return this._apiService.post(`Authentication/ValidateCredentials`, loginModel);
  }

  logout(): Observable<any> {
    if (this.currentUserValue && this.currentUserValue.isImpersonating) {
      return this._apiService.post("Authentication/ImpersonateLogout", null).pipe(
        map(data => {
          this._localStorageService.clearAll();
          this._sessionStorageService.clearAll();
          let user: UserModel = new UserModel(data);
          this.updateCurrentUser(user);

          return data;
        })
      );
    }
    else {
      return this._apiService.get(AuthenticationService.LogoutUrl).pipe(
        map(_ => {
          this.clearCurrentUser();

          return new Observable(r => { return r.next(); });
        })
      );
    }
  }

  forceLogout(request: ForceLogoutRequestModel): Observable<any> {    
    return this._apiService.post("Authentication/ForceLogout", request).pipe(
      map(data => {          
        return data;
      })
    );    
  }

  handleUnAuthorizedUser(){
    // we force re-login when receiving status 401 Unauthorized

    this._loggingService.logInformation('Unauthorized user, clearing browser user info and navigate to login.');

    // if the same view makes multiple requests, we only want to clear the browser user info, 
    // display the error dialog and redirect to login page for the first request that gets
    // the 401 response. 
    
    if(this.currentUserValue != null)
      {
        this.clearCurrentUser();
        var modalRef = this._dialogService.showErrorMessage("Utloggad", "Du har blivit utloggad från FleetWeb.");
        modalRef.result.then(_ => {
            this._router.navigate(['/login']);               
        });
      }
  }

  clearCurrentUser(){
    this.updateCurrentUser(null);
    this._localStorageService.clearAll();
    this._sessionStorageService.clearAll();
  }
  
  hasPermissionStringInput(permission: string): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasPermissionStringInput(permission);
  }

  hasPermission(permission: Permission): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasPermission(permission);
  }

  hasAnyPermission(permissions: Array<Permission>): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasAnyPermission(permissions);
  }

  hasAllPermissions(permissions: Array<Permission>): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasAllPermissions(permissions);
  }

  get hasPermissions(): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    return this.currentUserValue.hasPermissions;
  }

  get hasReportPermissions(): boolean {
    if (!this.currentUserValue) {
      return false;
    }
    return this.currentUserValue.hasReportPermissions;
  }

  private updateCurrentUser(user: UserModel) {
    this._sessionStorageService.currentUser = user;
    this.currentUserSubject.next(user);

    if (user && user.hasPermission(Permission.LäsaPåminnelser)) {
      this._notificationService.updateNotifications();
    }
    else {
      this._notificationService.resetNotifications();
    }
  }
}

