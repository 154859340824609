export enum ServiceLevel {
    Avrop = 1,
    Konteringsunderlag = 2,
    RapporterStandard = 3,
    Förmånsrapportering = 4,
    Genomfakturering = 5,
    Personalbil = 6,
    EndastAdminKontrakt = 7,
    Fastlöneavdrag = 8,
    Bränsleförmånsrapportering = 9,
    Mätarställningsinrapportering = 10,
    Bilpolicy = 11,
    Förarinloggning = 12,
    Trängselskattrapportering = 13
}