import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WizardStepContentComponent } from '@shared/components/wizard/wizard-step-content/wizard-step-content.component';
import { AvailableMonthReport } from '@domain/models/available-month-report.model';
import { LoggingService } from '@core/services/logging.service';
import { DialogService } from '@core/services/dialog.service';

@Component({
    template: ""
})
export class ReportStepContentComponent<T> extends WizardStepContentComponent {

    @Input() reportModel: T;

    @Output() isLateEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() rentalToggleEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() loadMonthEvent: EventEmitter<AvailableMonthReport> = new EventEmitter();

    constructor(
        loggingService: LoggingService,
        dialogService: DialogService) {
        super(loggingService, dialogService);
    }

    getNextMonth(date: Date): Date {
        let newDate = new Date(date);
        newDate.setMonth(date.getMonth() + 1);
        return newDate;
    }

    getPreviousMonth(date: Date): Date {
        let newDate = new Date(date);
        newDate.setMonth(date.getMonth() - 1);
        return newDate;
    }
}
