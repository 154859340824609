export class FleetWebUserQuery
{
    constructor(jsonObject: any = null) {    
        if (jsonObject == null) {
          return;
        }
    
        this.userId = jsonObject.userId;
        this.customerId = jsonObject.customerId;
      }
    
      
    userId : string;
    customerId: number;
        
    toQueryString() {        
        if (this.userId) {
            return "?userId=" + this.userId;
        }

        if (this.customerId) {
            return "?customerId=" + this.customerId;
        }
    }      
}