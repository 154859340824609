export class FuelCardsValidation{

    FuelCards: Array<string>;

    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.FuelCards = jsonObj.FuelCards;
        }
    }
    
    get isValid(){
        return this.errors.length === 0; 
    }

    get errors(){
        var errors = new Array<string>();     

        if(this.FuelCards)
        {
            this.FuelCards.forEach(mess => {
                errors.push(mess);
            });
        }

        return errors;
    }
}
