import { Component, EventEmitter } from '@angular/core';
import { HeroService } from '../../../domain/services/hero.service';
import { ReportStepContentComponent } from './report-step-content/report-step-content.component';
import { MileageReportSelectMonthComponent } from '../../../features/cars/mileage-report/steps/mileage-report-select-month/mileage-report-select-month.component';
import { CarService } from '../../../domain/services/car.service';
import { CarInfoModel } from '../../../domain/models/car-info.model';
import { AvailableMonthReport } from '@domain/models/available-month-report.model';
import { WizardComponent } from '@shared/components/wizard/wizard.component';
import { LoggingService } from '@core/services/logging.service';
import { LoadingHelper } from '@shared/helpers/loading.helper';

@Component({
  template: ''
})
export class ReportComponent<T> extends WizardComponent {

  reportModel: T;
  license: string;
  accountNumber: number;
  car: CarInfoModel;
  isLate: boolean;

  protected _carLoadingHelper = new LoadingHelper();

  rentalToggleEvent: EventEmitter<boolean> = new EventEmitter();
  loadMonthEvent: EventEmitter<AvailableMonthReport> = new EventEmitter();

  constructor(
    _heroService: HeroService,
    public _carService: CarService,
    protected _loggingService: LoggingService) {
    super(_heroService);
  }

  isMileageReportChooseMonthComponent() {
    return this.currentStep.component == MileageReportSelectMonthComponent;
  }

  //TODO: Här borde vi väl egentligen se till att köra unsubscribe på alla event subscriptions?
  protected override loadComponent() {
    if (this.steps && this.currentStep && this.dynamicContainer) {
      
      const viewContainerRef = this.dynamicContainer.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(this.currentStep.component);

      (<ReportStepContentComponent<T>>componentRef.instance).reportModel = this.reportModel;
      
      (<ReportStepContentComponent<T>>componentRef.instance).resetWizardEvent.subscribe(event => {
        if (event) {
          this.reset();
        }
      });

      (<ReportStepContentComponent<T>>componentRef.instance).submitEvent.subscribe(event => {
        if (event) {
          this.nextStep();
        }
      });

      (<ReportStepContentComponent<T>>componentRef.instance).previousStepEvent.subscribe(event => {
        if (event) {
          this.previousStep();
        }
      });

      (<ReportStepContentComponent<T>>componentRef.instance).errorEvent.subscribe(event => {
        if (event) {
          this.toSecondStep();
        }
      });

      (<ReportStepContentComponent<T>>componentRef.instance).isLateEvent.subscribe(event => {
        this.isLate = event;
      });

      (<ReportStepContentComponent<T>>componentRef.instance).loadingEvent.subscribe(event => {
        if (event) {
          this._stepLoadingHelper.startLoading();
        }
        else {
          this._stepLoadingHelper.stopLoading();
        }
      });

      (<ReportStepContentComponent<T>>componentRef.instance).rentalToggleEvent.subscribe(event => {
        if (event) {
          this.rentalToggleEvent.emit(true);
        }
      });
      
      (<ReportStepContentComponent<T>>componentRef.instance).loadMonthEvent.subscribe(month => {
        if (month) {
          this.loadMonthEvent.emit(month);
        }
      });

      componentRef.changeDetectorRef.detectChanges();
    }
  }

  toFirstStep() {
    this.currentStep = this.steps[0];;
    this.setHeroSubtitle();
    this.loadComponent();
  }

  toSecondStep() {
    this.currentStep = this.steps[1];
    this.setHeroSubtitle();
    this.loadComponent();
  }

  isLoading(): boolean {
    return this._carLoadingHelper.isLoading || this._stepLoadingHelper.isLoading;
  }

  getNextMonth(date: Date): Date {
    let newDate = new Date(date);
    newDate.setMonth(date.getMonth() + 1);
    return newDate;
  }

  getMonthAfterNext(year: number, month: number): Date {
    let newDate = new Date(year, month-1, 1); //Js indexes months from 0.
    newDate.setMonth(newDate.getMonth() + 2); //want to add 2 months
    return newDate;
  } 
}
