import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Permission } from '@core/models/permissions.enum';
import { AuthenticationService } from '../services/authentication.service';
import { PermissionGroups } from '@core/models/permission-groups';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authenticationService.currentUserValue;
        if (currentUser) {            
            // check if user has any of needed permissions
            if (route.data.permissions && !currentUser.permissions.some(perm => route.data.permissions.includes(perm))) {                
                // Unauthorized
                this._router.navigate(['/not-authorized'], { skipLocationChange: true });
                return false;
            }

            return true;
        }

        // TODO: Put returnUrl in local storage to hide it from url
        this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authenticationService.currentUserValue;
        if (currentUser) {            
            // check if user has any of needed permissions
            if (route.data.permissions && !currentUser.permissions.some(perm => route.data.permissions.includes(perm))) {                
                // Unauthorized
                this._router.navigate(['/not-authorized'], { skipLocationChange: true });
                return false;
            }

            return true;
        }

        // TODO: Put returnUrl in local storage to hide it from url
        this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
