<section class="o-page-wrap u-mt mb-5">
  <div class="report-filter mt-5 mb-5 pt-5 pb-5 bg-grey">
    <div class="o-page-wrap o-page-wrap--xsmall">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-100">
        <div class="row">
          <div class="col">
            <fieldset class="o-fieldset u-mb">
              <div class="o-fieldset__row mt-4 mb-2">
                <div class="switch mb-3 mt-3">
                  <input type="checkbox" class="switch" id="switch-existing" formControlName="existing">
                  <label for="switch-existing">Visa endast befintliga användare</label>
                </div>
              </div>
              <div *ngIf="!limited" class="o-fieldset__row mb-2">
                <label for="frameAgreement">Ramavtal</label>
                <div class="d-flex justify-content-start">
                  <div class="c-input mb-3 w-100">
                    <app-frame-agreement-search class="w-100"
                                                [includeDisabled]="includeDisabled"  
                                                [intitialFrameAgreement]="frameAgreement"
                                                (selected)="onFrameAgreementSelected($event)">
                    </app-frame-agreement-search>
                  </div>
                  <div class="switch ms-3 mt-1">
                    <input type="checkbox" class="switch" id="switch-inactive" formControlName="includeDisabled">
                    <label for="switch-inactive" class="text-nowrap">Visa inaktiva</label>
                  </div>
                </div>
              </div>
              <div *ngIf="showSuperUserDisclaimer" class="alert alert-warning">
                <p>När du söker efter personer i affärssystemet på ett visst ramavtal, kommer du endast kunna få sökträffar för personer som är förare eller Fleet Manager på ett kontrakt. </p>
                <p>För att lägga upp nya användare utan engagemang, sök utan angivet ramavtal.</p>                                
              </div>
              <div *ngIf="showDisclaimer" class="alert alert-info">
                <p>Du kan endast skapa <b>nya</b> användare för personer som är förare eller Fleet Manager på ett kontrakt. </p>                
                <p class="mb-0">För hjälp att lägga upp nya användare utan engagemang, vänligen kontakta Fleet Support.</p>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="firstName">Förnamn</label>
                <div class="c-input mb-3">
                  <input id="firstName" formControlName="firstName" class="c-input__input">
                </div>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="lastname">Efternamn</label>
                <div class="c-input mb-3">
                  <input id="lastname" formControlName="lastName" class="c-input__input">
                </div>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="socialSecurityNumber">Personnummer</label>
                <div class="c-input mb-3">
                  <input id="socialSecurityNumber" formControlName="socialSecurityNumber" class="c-input__input">
                </div>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="email">E-post</label>
                <div class="c-input mb-3">
                  <input id="email" formControlName="email" class="c-input__input">
                </div>
              </div>             
            </fieldset>
          </div>
        </div>
        <div class="d-flex mt-auto justify-content-between">
          <button type="button" class="c-btn c-btn--secondary c-btn--full me-2" (click)="onReset()">
            <span class="c-btn__text">Rensa</span>
          </button>
          <button type="submit" class="c-btn c-btn--primary c-btn--full ms-2" [disabled]="isLoading">
            <span class="c-btn__text">Sök</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
<section *ngIf="users && users.length" class="o-page-wrap u-mt mb-5">
  <app-pagination [settings]="paginationSettings" (pageChanged)="onPageChanged($event)" (pageSizeChanged)="onPageSizeChanged($event)"></app-pagination>
</section>
<section *ngIf="users && users.length" class="o-page-wrap u-mt mb-5 min-width-1200 p-0">
  <div class="report-table">
    <table cellspacing="0">
      <tr class="text-start text-primary">
        <th></th>
        <th class="">Förnamn</th>
        <th class="">Efternamn</th>
        <th class="">Personnummer</th>
        <th class="">E-post</th>
        <th *ngIf="!limited" class="">Ramavtal</th>
        <th class="">Senast inloggad</th>
        <th class=""></th>
      </tr>
      <tr *ngFor="let user of users">
        <td>          
          <i *ngIf="user.userId !== null && user.isLockedOut" class="fa c-icon c-icon--[lock] c-icon--small"></i>
          <i *ngIf="user.userId !== null  && !user.isLockedOut" class="fa c-icon c-icon--[user] c-icon--small"></i>          
        </td>
        <td>{{user.firstName}}</td>
        <td>{{user.lastName}}</td>
        <td>{{user.socialSecurityNumber}}</td>
        <td>{{user.userName}}</td>
        <td *ngIf="!limited"><span [class.line-through]="!user.frameAgreementEnabled"> {{user.frameAgreementName}}</span></td>
        <td><span *ngIf="user.lastLoginDate !== null"> {{user.lastLoginDate | date: 'yyyy-MM-dd HH:mm:ss'}}</span></td>
        <td >    
          <div class="justify-content-center d-flex w-100">
          <button *ngIf="!getCreateOrEditDisabled(user)" type="button" [title]="getTitle(user)" class="c-btn c-btn--link" (click)="onCreateOrEdit(user)">
            <span class="c-btn__text"> {{user.userId === null ? 'Skapa' :'Redigera' }}</span>
          </button>
          <button *ngIf="getCreateOrEditDisabled(user)" class="ms-2" type="button" title="Kan inte skapa användare" class="badge badge-info" [ngbPopover]="getTitle(user)" popoverTitle="Kan inte skapa användare">
            i
          </button>
        </div>      
        </td>
      </tr>
    </table>
  </div>
</section>
<section *ngIf="users && users.length === 0 && !isLoading" class="o-page-wrap u-mt mb-5">
  <div class="u-text-center">
    Din sökning gav inte något resultat.
  </div>
</section>
<app-spinner *ngIf="isLoading"></app-spinner>
