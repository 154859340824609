import { NotificationType } from "./notification-type.enum";
import { Notification } from "./notification.model";

export class NotificationGroup {
    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
          return;
        }
    
        this.license = jsonObj.license;
        this.accountNumber = jsonObj.accountNumber;
        this.type = jsonObj.type;

        this.notifications = new Array<Notification>();
        if (jsonObj.notifications) {      
            this.notifications = jsonObj.notifications.map(n => {
                return new Notification(n);
            });            
          }
      }
          
      license : string;
      accountNumber: number;
      type : NotificationType;
      notifications: Array<Notification>;
}
    