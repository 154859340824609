
export class DateHelper {

  static get today() : Date{
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return now;
  }

  static isTodayOrFutureDate(date: Date): boolean {
    if (!date) {
      return false;
    }

    date.setHours(0, 0, 0, 0);   
    return date >= this.today;
  }

  static isSameOrLaterDate(fromDate: Date, toDate: Date): boolean {
    if (!fromDate || !toDate) {
      return false;
    };

    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);

    return toDate >= fromDate;
  }

  //NOTE: toLocaleDateString is not reliable!
  static toSwedishDateFormat(date: Date) : string {
    if(date == null){
      return null;
    }

    if(!(date instanceof Date)){
      return null;
    }
    
    var result = `${date.getFullYear()}-${DateHelper.zeroPadSingleDigit(date.getMonth()+1)}-${DateHelper.zeroPadSingleDigit(date.getDate())}`;        
    return result;
  }

  private static zeroPadSingleDigit(n: number): string {
    return n < 10 ? "0"+n : n.toString();
  }

  static get nowInSwedishDateFormat(): string{
    return DateHelper.toSwedishDateFormat(new Date());
  }
}
