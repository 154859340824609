import { OrganizationTargetGroup } from "@core/models/organization-target-group.enum";

export class FormRoleModel {
  constructor(jsonObject: any = null) {
    
    if (jsonObject == null) {
      return;
    }

    this.targetGroup = jsonObject.targetGroup;
  }

  targetGroup: OrganizationTargetGroup;
}
