
export class FleetWebUserInfo {

    constructor(jsonObject: any = null) {
      if (jsonObject == null) {
        return;
      }
  
      this.userId = jsonObject.userId;
      this.userName = jsonObject.userName;
      this.roles = jsonObject.roles;
      this.lastLoginDate = jsonObject.lastLoginDate != null ? new Date(jsonObject.lastLoginDate) : null;      
      this.createdAtUtc = jsonObject.createdAtUtc != null ? new Date(jsonObject.createdAtUtc) : null;      
      this.isLockedOut = jsonObject.isLockedOut;          
      this.rolesString = jsonObject.rolesString;

      this.customerId = jsonObject.customerId;
      this.firstName = jsonObject.firstName;
      this.lastName = jsonObject.lastName;
      this.socialSecurityNumber = jsonObject.socialSecurityNumber;
      this.frameAgreementId = jsonObject.frameAgreementId;
      this.frameAgreementName = jsonObject.frameAgreementName;
      this.frameAgreementEnabled = jsonObject.frameAgreementEnabled;
      this.frameAgreementQuotaAccountName = jsonObject.frameAgreementQuotaAccountName;
      this.frameAgreementQuotaAccountId = jsonObject.frameAgreementQuotaAccountId;
      this.businessSystemQuotaAccountId = jsonObject.businessSystemQuotaAccountId;
      this.hasConflictingFrameAgreements = jsonObject.hasConflictingFrameAgreements;
      this.fleetCustomerNumber = jsonObject.fleetCustomerNumber;
      this.businessSystemRoles = jsonObject.businessSystemRoles;

    }
  
    userId : string;
    userName : string;
    lastLoginDate : Date;
    createdAtUtc: Date;
    roles: Array<string>;
    isLockedOut: boolean;
    rolesString: string;

    customerId: number;
    fleetCustomerNumber: number;
    firstName: string;
    lastName: string;
    socialSecurityNumber: string;
    frameAgreementId : number;
    frameAgreementName : string;
    frameAgreementEnabled : boolean;
    frameAgreementQuotaAccountName: string;
    frameAgreementQuotaAccountId: number;
    businessSystemQuotaAccountId: number;
    hasConflictingFrameAgreements: boolean;    
    businessSystemRoles: string;
}
