import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { Contact } from '../models/contact.model';
import { DriverCategory } from '../models/driver-category.model';
import { SearchDriverQuery } from '../models/filters/search-driver-query.model';
import { DriverPart } from '@domain/models/driver-part.model';
import { QueryResult } from '@domain/models/query-result.model';

@Injectable({
  providedIn: "root"
})
export class DriverService {

  constructor(private _apiService: BaseApiService) { }

  searchDrivers(searchDriverQuery: SearchDriverQuery): Observable<QueryResult<DriverPart>> {    
    return this._apiService.get("Driver" + searchDriverQuery.toQueryString()).pipe(
      map(data => {
        let result = new QueryResult<DriverPart>(data, DriverPart);        
        return result;
      })
    );
  }

  getLoggedInDriver(): Observable<Contact> {
    return this._apiService.get("Driver/LoggedInDriver");
  }

  getDriverCategories(): Observable<Array<DriverCategory>> {
    return this._apiService.get("Driver/DriverCategories").pipe(
      map(data => {
          let result = data.map(d => { return new DriverCategory(d); });
          return result;
        })
      );
  }

  getExistingDriver(partId: number): Observable<Contact> {
    return this._apiService.get(`Driver/${partId}`);
  }
}
