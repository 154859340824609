export class FavoriteSeller {

    constructor(jsonObj: any = null) {
  
      if (jsonObj == null) {
        return;
      }
  
      this.id = jsonObj.id;
      this.dealerName = jsonObj.dealerName;
      this.dealerPartId = jsonObj.dealerPartId;
      this.sellerName = jsonObj.sellerName;
      this.sellerEmail = jsonObj.sellerEmail;
      this.sellerPhone = jsonObj.sellerPhone;
    }
  
    id: number;
    dealerPartId: number;
    dealerName: string;
    sellerName: string = null;
    sellerPhone: string = null;
    sellerEmail: string = null;  

    
  }
