export class QueryResult<T>  {

    constructor(jsonObject: any, type: any) {
        if(jsonObject == null) {
            this.result = new Array<T>;
            this.total = 0;            
        }
        else {
            this.result = jsonObject.result.map(r => { return new type(r); });
            this.total = jsonObject.total;
        }
    }

    public result: T[];
    public total: number;
}
