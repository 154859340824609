import { Addon } from "@domain/models/addon.model";

export class SuborderAddon {
  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.id = jsonObj.id;
    this.addonId = jsonObj.addonId;
    this.suborderId = jsonObj.suborderId;

    if (jsonObj.addon) {
      this.addon = new Addon(jsonObj.addon);
    }
  }

  id: number;
  addonId: number;
  suborderId: number;
  addon: Addon;
}
