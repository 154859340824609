<div class="o-page-wrap c-mega-menu__outer-wrap u-ph-none@s">
  <div class="js-mega-menu-wrap c-mega-menu__inner-wrap js-is-loaded">
    <nav class="js-mega-menu c-mega-menu c-mega-menu--first-level-is-open js-is-loaded" [class.is-open]="active" appClickOutside (ClickOutside)="closeMenu()">
      <ul class="c-mega-menu__list c-mega-menu__list--level-one u-mb">
        <li>
          <div class="d-flex">
            <a class="c-btn mb-4 w-100" tabindex="0" (click)="logout()">
              <span class="c-btn__text">Logga ut</span>
            </a>
          </div>
        </li>
      </ul>
      <ul class="c-mega-menu__list c-mega-menu__list--level-one u-mb">
        <ng-container *ngFor="let parentRoute of routeMetadata.Profile.children | keyvalue:keepOrder; let parentIndex = index">
          <ng-container *ngIf="parentRoute && parentRoute.value.data && parentRoute.value.data.showInMenu">            
            <app-menu-item (hideMenuInParentComponent)="closeMenu()" [route]="parentRoute"></app-menu-item>
          </ng-container>
        </ng-container>
      </ul>
    </nav>
  </div>
</div>