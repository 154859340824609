export class ContentType {

    static pdf = new ContentType('pdf', 'application/pdf', 'document-pdf');
    static doc = new ContentType('doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'document-checkmark');
    static docx = new ContentType('docx', 'application/msword', 'document-checkmark');
    // static xls = new ContentType('xls', 'application/vnd.ms-excel', 'document-xls');
    // static xlsx = new ContentType('xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'document-xls');
    
    static KnownTypes = [
        this.pdf,
        this.doc,
        this.docx
        // ,
        // this.xls,
        // this.xlsx
    ];    
     
    constructor(fileExtension: string, mimeType: string, icon: string) {
        this.fileExtension = fileExtension;
        this.mimeType = mimeType;
        this.icon = icon;
    }

    mimeType: string;
    fileExtension: string;
    icon: string;
}