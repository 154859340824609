export class AvailableMonthReport {
  constructor(jsonObject: AvailableMonthReport = null) {

    if (jsonObject == null) {
      return;
    }

    this.month = new Date(jsonObject.month);
    this.isValid = jsonObject.isValid;
    this.info = jsonObject.info;
    this.extraInfo = jsonObject.extraInfo;
    this.currentMileage = jsonObject.currentMileage;
  }

  month: Date;
  isValid: boolean;
  info?: string;
  extraInfo?: string;
  currentMileage?: number;

  selected?: boolean;
}
