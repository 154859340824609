export class YearMonthViewModel {
  constructor(period?: string) {
    if (period && period.length === 6) {
      this.year = Number(period.substring(0, 4));
      this.month = Number(period.substring(4, 6));
    }
  }

  year: number;
  month: number;

  get caption(): string {
    return `${this.year}-${String(this.month).padStart(2, "0")}`;
  }

  get yearAndMonth(): Date {
    return new Date(this.year, this.month);
  }

  get value(): string {
    return `${this.year}${String(this.month).padStart(2, "0")}`;
  }
}
