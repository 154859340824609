<div class="c-hero-teaser" [class.isFrontPageNews]="hasFrontPageNews === true">
  <img src="assets/images/bg-img-desktop-hd.jpg" class="c-hero-teaser__image">
</div>
<div class="c-hero-teaser__content">
  <div class="o-page-wrap">
    <div class="c-hero-teaser__box u-pb-none">
      <div class="o-layout o-layout--middle u-mh-none u-mb-small">
        <h3 class="text-start mt-2 mb-0">FleetWeb</h3>
        <div class="o-layout__item u-2/4 u-ph-none mt-0">
          <img class="c-media-grid__image mt-3 mb-3" src="assets/images/fleet-support.svg">
        </div>
      </div>

      <ng-template [ngIf]="showFrontPageNews">
        <div>
          <ng-container *ngFor="let item of frontPageNews">
            <app-news-card class="c-card mb-2" [singleNews]="item" [class.isImportant]="item.important"></app-news-card>
          </ng-container>
        </div>
      </ng-template>

      <form id="login" [formGroup]="loginForm" *ngIf="displayLogin" (ngSubmit)="login()">
        <fieldset class="o-fieldset u-mb">
          <div class="o-fieldset__row">
            <div>
              <label for="userNameInput">
                <span class="vertical-align-middle">Användarnamn</span>
              </label>
              <input id="userNameInput" formControlName="username" type="text" class="c-input__input"
                     placeholder="Användarnamn" [ngClass]="{ 'is-error': loginSubmitted && userNameControl.errors }"
                     autocomplete="username" />
            </div>
          </div>
          <div class="o-fieldset__row">
            <div>
              <label for="password">
                <span class="vertical-align-middle">Lösenord</span>
              </label>
              <input formControlName="password" type="password" class="c-input__input"
                     placeholder="Lösenord" [ngClass]="{ 'is-error': loginSubmitted && passwordControl.errors }"
                     autocomplete="current-password" />
            </div>
            <small *ngIf="errorMessage" class="is-error">{{errorMessage}}</small>
          </div>
        </fieldset>
        <div class="login-links pb-3 d-flex justify-content-end mt-auto mobile-btn-bar">
          <a (click)="toggleDisplay()" class="c-link me-2" [disabled]="!canLogin">
            <i class="c-link__icon c-icon c-icon--[control-arrow-right] c-icon--xsmall c-icon--square c-icon--fill"></i>
            <span class="c-link__body">
              <span class="c-link__effect">Glömt inloggningsuppgifter?</span>
            </span>
          </a>
          <button type="submit" class="c-btn fob-button" [disabled]="!canLogin">
            <span class="c-btn__text">Logga in</span>
          </button>
        </div>        
        <app-spinner *ngIf="loading"></app-spinner>
      </form>
      <ng-template [ngIf]="displayReset">
        <form id="reset" [formGroup]="resetForm" *ngIf="!resetPerformed" (ngSubmit)="reset()">
          <h3>Återställ lösenord</h3>
          <fieldset class="o-fieldset u-mb">
            <div class="o-fieldset__row">
              <div>
                <label for="resetPasswordInput">
                  <span class="vertical-align-middle">Användarnamn (normalt samma som e-postadress)</span>
                </label>
                <input id="resetPasswordInput" formControlName="username" type="text" class="c-input__input"
                      placeholder="Användarnamn" [ngClass]="{ 'is-error': resetSubmitted && resetPasswordUserNameControl.errors }"
                      autocomplete="username" />
              </div>
              <small *ngIf="errorMessage" class="is-error">{{errorMessage}}</small>
            </div>
          </fieldset>
          <div class="login-links pb-3 d-flex justify-content-end mt-auto mobile-btn-bar">
            <a class="c-link me-2" (click)="toggleDisplay()">
              <span class="c-link__body">
                <span class="c-link__effect">Avbryt</span>
              </span>
            </a>
            <button type="submit" class="c-btn fob-button" [disabled]="!canLogin">
              <span class="c-btn__text">Återställ lösenord</span>
            </button>
          </div>
        </form>
        <div *ngIf="resetPerformed">
          Ett e-postmeddelande är skickat till dig. Vänligen kontakta Fleet Support vid frågor.
          <div class="login-links pb-3 d-flex justify-content-end mt-auto mobile-btn-bar">
            <a class="c-link me-2" (click)="toggleDisplay()">
              <span class="c-link__body">
                <span class="c-link__effect">Tillbaka</span>
              </span>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
