<div class="c-modal">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__wrapper">  <!-- [ngClass]="{'wrapper-error': error}"> -->
        <div class="c-modal__header">
            <button type="button" class="c-modal__close-btn close-modal" aria-label="Close"
                (click)="modal.close(false)"></button>
        </div>
        <div class="c-modal__content">
          <header>
            <ng-template [ngIf]="!error" [ngIfElse]="Error">
              <h3 class="text-start">{{title}}</h3>
            </ng-template>
            <ng-template #Error>
              <h3 class="text-start d-flex align-items-bottom">
                <i class="fa c-icon c-icon--[control-exclamation-mark] me-2"></i>
                <span>{{title}}</span>
              </h3>
            </ng-template>
          </header>

          <p class="text-start" style="white-space: normal">{{message}}</p>
        </div>

        <div class="c-modal__footer">
            <button type="button" class="c-btn close-btn-modal" aria-label="Close"
                (click)="modal.close(false)">Stäng</button>
        </div>
    </div>
</div>
