import { FleetAggregation } from "./fleet-aggregation.model";

export class FleetDataModel {

  constructor(jsonObject: any = null) {

    if (jsonObject == null) {
      return;
    }

    this.numberOfActiveCars = jsonObject.numberOfActiveCars;
    this.numberOfOrderedCars = jsonObject.numberOfOrderedCars;
    this.numberOfExtendedContracts = jsonObject.numberOfExtendedContracts;
    this.numberOfContractsRunningOut = jsonObject.numberOfContractsRunningOut;

    this.numberOfActiveCarsByMake = new Array<FleetAggregation>();
    if (jsonObject.numberOfActiveCarsByMake) {
      jsonObject.numberOfActiveCarsByMake.forEach((element) => {
        this.numberOfActiveCarsByMake.push(new FleetAggregation(element));
      });
    }
    this.numberOfActiveCarsByYearDetailed = new Array<FleetAggregation>();
    if (jsonObject.numberOfActiveCarsByYearDetailed) {
      jsonObject.numberOfActiveCarsByYearDetailed.forEach((element) => {
        this.numberOfActiveCarsByYearDetailed.push(new FleetAggregation(element));
      });
    }

    this.numberOfActiveCarsByYearAggregated = new Array<FleetAggregation>();
    if (jsonObject.numberOfActiveCarsByYearAggregated) {
      jsonObject.numberOfActiveCarsByYearAggregated.forEach((element) => {
        this.numberOfActiveCarsByYearAggregated.push(new FleetAggregation(element));
      });
    }

    this.numberOfActiveCarsByFuel = new Array<FleetAggregation>();
    if (jsonObject.numberOfActiveCarsByFuel) {
      jsonObject.numberOfActiveCarsByFuel.forEach((element) => {
        this.numberOfActiveCarsByFuel.push(new FleetAggregation(element));
      });
    }
  }

  numberOfActiveCars: number;
  numberOfOrderedCars: number;
  numberOfExtendedContracts: number;
  numberOfContractsRunningOut: number;

  numberOfActiveCarsByMake: Array<FleetAggregation>;
  numberOfActiveCarsByYearDetailed: Array<FleetAggregation>;
  numberOfActiveCarsByYearAggregated: Array<FleetAggregation>;
  numberOfActiveCarsByFuel: Array<FleetAggregation>;
}
