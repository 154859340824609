<form *ngIf="formGroup" [formGroup]="formGroup">
  <div *ngIf="showSelectAll" class="mb-3">
      <button type="button" class="c-btn c-btn--secondary" (click)="onSelectAllCustomers()" [disabled]="selectDisabled">
        <span class="c-btn__text">Markera samtliga</span>
      </button>
      <button type="button" class="c-btn c-btn--secondary ms-2" (click)="onDeselectAllCustomers()" [disabled]="deselectDisabled">
        <span class="c-btn__text">Avmarkera samtliga</span>
      </button>    
  </div>  
  <div *ngIf="showFilter" class="mb-4">
    <label>Filtrera kunder</label>
    <div class="c-input mb-1">
      <input formControlName="filter" class="c-input__input" type="search" maxlength="255" placeholder="Kundens namn"/>
      <button type="button" class="c-btn c-btn--secondary ms-2" (click)="onClearFilter()" [disabled]="!isFiltering">Rensa</button>
    </div>  
    <small>
      Visar {{filteredOrganizations.length}} av totalt {{frameAgreementDetails.organizations.length}} kunder. Valda kunder visas alltid.
    </small>     
  </div>  
  <div formArrayName="customers" *ngFor="let check of customersFormArray.controls; let i = index" class="switch mb-2 mt-2">
    <input type="checkbox" class="switch" [id]="filteredOrganizations[i].customerId" [formControl]="check" />
    <label [for]="filteredOrganizations[i].customerId">{{ filteredOrganizations[i].name }} ({{ filteredOrganizations[i].organizationNumber }}) </label>
    <a *ngIf="check.value" title="Kostnadsställen" class="link c-btn c-btn--link c-btn--small float-end" (click)="onEditCostCenters(i)" >
      <span [class.is-error]="hasInvalidCostCenters(i)" class="c-btn__text">{{ getCostCenterLimitationCountText(i) }}</span>
    </a>               
  </div>
</form>
