export class DriverValidation {
    
    FirstName: Array<string>;
    LastName: Array<string>;
    Address1: Array<string>;
    Address2: Array<string>;
    ZipCode: Array<string>;
    City: Array<string>;
    Email: Array<string>;
    Phone: Array<string>;
    SocialSecurityNumber: Array<string>;
    EmployeeNumber: Array<string>;
    Type: Array<string>;
    PartPVSeq: Array<string>;
    DriverCategoryName: Array<string>;
    DriverCategoryId: Array<string>;

    constructor(jsonObj : any = null){

        if(jsonObj != null)
        {
            this.Type = jsonObj['Contact.Type'];
            this.FirstName = jsonObj['Contact.FirstName'];
            this.LastName = jsonObj['Contact.LastName'];
            this.Address1 = jsonObj['Contact.Address1'];
            this.Address2 = jsonObj['Contact.Address2'];
            this.ZipCode = jsonObj['Contact.ZipCode'];
            this.City =  jsonObj['Contact.City'];
            this.Email = jsonObj['Contact.Email'];
            this.Phone = jsonObj['Contact.Phone'];
            this.SocialSecurityNumber = jsonObj['Contact.SocialSecurityNumber'];
            this.EmployeeNumber = jsonObj['Contact.EmployeeNumber'];
            this.PartPVSeq = jsonObj['Contact.PartPVSeq'];
            this.DriverCategoryName = jsonObj['DriverCategoryName'];
            this.DriverCategoryId = jsonObj['DriverCategoryId']; 
        }
    }

    get isValid(){
        return this.errors.length === 0; 
    }

    get errors(){
        var errors = new Array<string>();   
                 
        if(this.Type)
        {
            this.Type.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.PartPVSeq)
        {
            this.PartPVSeq.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.FirstName)
        {
            this.FirstName.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.LastName)
        {
            this.LastName.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.Address1)
        {
            this.Address1.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.Address2)
        {
            this.Address2.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.ZipCode)
        {
            this.ZipCode.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.City)
        {
            this.City.forEach(mess => {
                errors.push(mess);
            });
        }
              
        if(this.Phone)
        {
            this.Phone.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.Email)
        {
            this.Email.forEach(mess => {
                errors.push(mess);
            });
        }
                
        if(this.SocialSecurityNumber)
        {
            this.SocialSecurityNumber.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.EmployeeNumber)
        {
            this.EmployeeNumber.forEach(mess => {
                errors.push(mess);
            });
        }
      
        if(this.DriverCategoryName)
        {
            this.DriverCategoryName.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.DriverCategoryId)
        {
            this.DriverCategoryId.forEach(mess => {
                errors.push(mess);
            });
        }

        return errors;
    }
}
