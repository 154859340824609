import { CarModel } from './car.model';
import { Contact } from './contact.model';
import { PreViewStatus } from './previews-status.model';
import { FuelCard } from './fuel-card.model';
import { edocsFile } from './edocs-file.model';
import { ServiceLevel } from '@core/models/service-level.enum';
import { InvoiceAddress } from './invoice-address.model';
import { Branch } from './branch.model';
import { CustomerPart } from './customer-part.model';
import { AgreementDetail } from './agreement-detail.model';
import { SuborderAddon } from "@domain/models/suborder-addon.model";

export class Suborder {

  constructor(jsonObj: any = null) {
    this.fuelCards = new Array<FuelCard>();
    this.addons = new Array<SuborderAddon>();

    if (jsonObj == null) {
      this.car = new CarModel();
      this.contact = new Contact();
      return;
    }

    if (jsonObj.agreementDetail) {
      this.agreementDetail = new AgreementDetail(jsonObj.agreementDetail);
    }
    if (jsonObj.branch) {
      this.branch = new Branch(jsonObj.branch);
    }
    if (jsonObj.invoiceAddress) {
      this.invoiceAddress = new InvoiceAddress(jsonObj.invoiceAddress);
    }
    if (jsonObj.customer) {
      this.customer = new CustomerPart(jsonObj.customer);
    }
    if (jsonObj.car) {
      this.car = new CarModel(jsonObj.car);
    }
    if (jsonObj.contact) {
      this.contact = new Contact(jsonObj.contact);
    }
    if (jsonObj.fuelCards) {
      jsonObj.fuelCards.forEach((element) => {
        this.fuelCards.push(new FuelCard(element));
      });
    }
    if (jsonObj.addons) {
      jsonObj.addons.forEach((element) => {
        this.addons.push(new SuborderAddon(element));
      });
    }

    this.id = jsonObj.id;
    this.preViewId = jsonObj.preViewId;
    this.oldRegistrationNumber = jsonObj.oldRegistrationNumber;
    this.agreementId = jsonObj.agreementId;
    this.message = jsonObj.message;
    this.customerPartPVSeq = jsonObj.customerPartPVSeq;
    this.basketId = jsonObj.basketId;
    this.branchNumber = jsonObj.branchNumber;
    this.insuranceCompany = jsonObj.insuranceCompany;
    this.sellerName = jsonObj.sellerName;
    this.sellerPhone = jsonObj.sellerPhone;
    this.sellerEmail = jsonObj.sellerEmail;
    this.driverCategoryId = jsonObj.driverCategoryId;
    this.hasDriverCategories = jsonObj.hasDriverCategories;
    this.driverCategoryName = jsonObj.driverCategoryName;
    this.costCenterText = jsonObj.costCenterText;
    this.code1 = jsonObj.code1;
    this.code2 = jsonObj.code2;
    this.salaryDeductionGross = jsonObj.salaryDeductionGross;
    this.salaryDeductionNet = jsonObj.salaryDeductionNet;
    this.vatDeduction = jsonObj.vatDeduction;
    this.residualValue = jsonObj.residualValue;
    this.residualValuePercentage = jsonObj.residualValuePercentage;
    this.createdBy = jsonObj.createdBy;
    this.createdDate = jsonObj.createdDate !== null ? new Date(jsonObj.createdDate) : null;
    this.updatedBy = jsonObj.updatedBy;
    this.updatedDate = jsonObj.updatedDate !== null ? new Date(jsonObj.updatedDate) : null;
    this.suborderSent = jsonObj.suborderSent !== null ? new Date(jsonObj.suborderSent) : null;
    this.numberOfTerms = jsonObj.numberOfTerms;
    this.frameAgreementId = jsonObj.frameAgreementId;
    this.periodicityInMonths = jsonObj.periodicityInMonths;
    this.orderNumber = jsonObj.orderNumber;
    this.managerRef = jsonObj.managerRef;
    this.offerSent = jsonObj.offerSent !== null ? new Date(jsonObj.offerSent) : null;
    this.offerNumber = jsonObj.offerNumber;
    this.iPAddress = jsonObj.iPAddress;
    this.insuranceCompanyName = jsonObj.insuranceCompanyName;
    this.dealerPartPVSeq = jsonObj.dealerPartPVSeq;
    this.dealerName = jsonObj.dealerName;
    //TODO:
    // this.preViewStatus: PreViewStatus;
    // this.edocsFiles: Array<edocsFile>;
  }

  agreementDetail: AgreementDetail = null;

  id: number = -1; // NOT NULL, Löpnummer och nyckel
  preViewId: number = null; //Case Id, avtalsid kommer från PreView efter att avropet skickats in

  oldRegistrationNumber: string = null;//registreringsnummer för bil som ska ersättas av detta avrop.
  agreementId: number = null; //Typ av bil, verksamhet, förmån och personalbil mm
  message: string = null; //Fritextmeddelande
  customerPartPVSeq: number = null; //NOT NULL, Leasetagare, contact??
  basketId: number = null; //Fakturaadress
  branchNumber: string = null; //Filialnummer/69-nummer''FIlialnummer/69-nummer
  insuranceCompany: string = null; //Försäkringsbolag 
  // public int? DealerPartPVSeq: number; //Återförsäljare 
  sellerName: string = null; //Säljare 
  sellerPhone: string = null; //Säljarens mobilnummer
  sellerEmail: string = null; //Säljarens e-postadress
  driverCategoryId: number = null; //Referens till vald förarkategori. 
  hasDriverCategories: boolean = false;
  driverCategoryName: string = null;
  costCenterText: string = null; //Namn inte id för kostnaddsställe
  code1: string = null;//Ytterligare dimension 1 för kontering.
  code2: string = null; //Ytterligare dimension 2 för kontering.
  salaryDeductionGross: number = null; //Förmånsvärde brutto
  salaryDeductionNet: number = null; //Förmånsvärde netto
  vatDeduction: number = null; // Momsavdrag i procent' 'Första förhöjda inbetalning
  residualValue: number = null; //Restvärde
  residualValuePercentage: number = null;
  createdBy: string = null; //Använadarid för den kund som skapat avropet 
  createdDate: Date = null; //Datum och tid då avropet först skapades
  updatedBy: string = null; //Användarid på den som sist uppdaterade avropet
  updatedDate: Date = null; //Datum och tid då avropet sist uppdaterades
  suborderSent: Date = null; //Flagga för att hålla koll på om avropet är skickat till Preview eller inte 
  numberOfTerms: number = null; //Antal månader som kontraktet löper på 
  frameAgreementId: number = null; // Ramavtals id 
  periodicityInMonths: number = null; //Behövs denna? Vi har numberofterms
  orderNumber: string = null; //Beställningsnummer 
  managerRef: number = null; //Återförsäljaren
  offerSent: Date = null; //Flagga, inte null så är det datum och tid för när offerten skickats till Hummingbird 
  offerNumber: string = null; //Offertnummer 
  iPAddress: string = null; //Loggad ip-adress, sparas vid signering av avrop 
  car: CarModel;
  contact: Contact;
  fuelCards: Array<FuelCard> = null;
  edocsFiles: Array<edocsFile> = null;
  insuranceCompanyName: string = null;
  branch: Branch = null;
  invoiceAddress: InvoiceAddress = null;
  customer: CustomerPart = null;
  dealerPartPVSeq?: number = null;
  dealerName: string = null;
  addons: Array<SuborderAddon> = null;

  get isNew(): boolean {
    return this.preViewId == null && this.id === -1;
  }

  get hasSalaryDeduction(): boolean {
    if (!this.agreementDetail) {
      return false;
    }

    var salaryDeduction = this.agreementDetail.serviceLevels.find(s => s.sequence == ServiceLevel.Fastlöneavdrag);
    return salaryDeduction != null;
  }

  get agreementHasFuelCards(): boolean {
    if (!this.agreementDetail) {
      return false;
    }

    return this.agreementDetail.agreementFuelCards && this.agreementDetail.agreementFuelCards.length > 0;
  } 
}
