import { Component, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeAheadSearchComponent } from "../type-ahead-search/type-ahead-search.component";
import { QueryResult } from "@domain/models/query-result.model";
import { FrameAgreementService } from "@domain/services/frame-agreement.service";
import { FrameAgreement } from "@domain/models/frame-agreement.model";
import { SearchFrameAgreementQuery } from "@domain/models/filters/search-frame-agreement-query.model";

@Component({
  standalone: true,
  selector: "app-frame-agreement-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"],
  imports: [CommonModule, NgbTypeaheadModule, ReactiveFormsModule]
})
export class FrameAgreementSearchComponent  extends TypeAheadSearchComponent<FrameAgreement> {
  private readonly _minSearchTermLength = 2;
  private readonly _maxNumberOfResults = 10;
  
  @Input() includeDisabled = false;
  @Input() intitialFrameAgreement: FrameAgreement;
  
  constructor(private frameAgreementService: FrameAgreementService,
    parentFormGroup: FormGroupDirective) {
    super(parentFormGroup);    
  }

  public override get placeholderText(): string {
    return "Sök ramavtal";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: FrameAgreement): string {
    if (!match || match == null) {
      return "";
    }
    
    return  `${match.name ? match.name.trimEnd() : ""} ${match.id ? `(${match.quotaAccountName})`: ""}`;
  }

  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade ramavtal.`; 
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på ramavtalets namn eller ramnummer/kvotkonto med minst ${minSearchTermLength} tecken.`;
  }

  protected override searchAction(term: string): Observable<QueryResult<FrameAgreement>> {
    const searchQuery = new SearchFrameAgreementQuery();
    searchQuery.searchText = term.toString();
    searchQuery.pageSize = this._maxNumberOfResults;
    searchQuery.includeDisabled = this.includeDisabled;

    return this.frameAgreementService.searchFrameAgreements(searchQuery);
  }
  
  protected override isInstanceOfType(value: FrameAgreement): boolean {
    return value instanceof FrameAgreement;
  }

  protected override strikeThroughText(match: FrameAgreement): boolean {
    return !match.enabled;
  }

  protected override get initialSelection(): FrameAgreement {
    return this.intitialFrameAgreement;
  }
}
