export class CarMatch {
  constructor(jsonObject: any) {
    this.objectId = jsonObject.objectId;
    this.make = jsonObject.make;
    this.model = jsonObject.model;
    this.license = jsonObject.license;
    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.customerId = jsonObject.customerId;
    this.customerNumber = jsonObject.customerNumber;
    this.accountCustomerId = jsonObject.accountCustomerId;
    this.accountCustomerNumber = jsonObject.accountCustomerNumber;
    this.accountCustomerName = jsonObject.accountCustomerName;
    this.customerAgreementId = jsonObject.customerAgreementId;
    this.customerAgreementCode = jsonObject.customerAgreementCode;
    this.accountCostCenterId = jsonObject.accountCostCenterId;
    this.accountCostCenterText = jsonObject.accountCostCenterText;
  }

  objectId: number;
  make: string;
  model: string;
  license: string;
  firstName: string;
  lastName: string;
  customerId: number;
  customerNumber: number;
  accountCustomerId: number;
  accountCustomerNumber: number;
  accountCustomerName: string;
  customerAgreementId: number;
  customerAgreementCode: number | null;
  accountCostCenterId: number | null;
  accountCostCenterText: string;
}
