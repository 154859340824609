import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ScrollService {
  private scrollToTopSource = new Subject<void>();
  
  scrollToTop$ = this.scrollToTopSource.asObservable();

  triggerScrollToTop() {
    this.scrollToTopSource.next();
  }
}