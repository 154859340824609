import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class DomainModule {
    static forRoot(): ModuleWithProviders<DomainModule> {
        return {
            ngModule: DomainModule
        };
    }
}