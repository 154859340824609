import { Component } from '@angular/core';
import { HeroModel } from '../../../domain/models/hero.model';
import { HeroService } from '../../../domain/services/hero.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

    heroModel: HeroModel;
    constructor(private _heroService: HeroService) {

        this.initHero();
    }

    initHero() {
        this.heroModel = new HeroModel();
        this.heroModel.title = "Oops";
        this._heroService.set(this.heroModel);
    }
}