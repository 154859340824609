import { PermissionGroups } from '@core/models/permission-groups';
import { Permission } from '@core/models/permissions.enum';

export const RouteMetadata = {
    Company: {
        path: '',        
        data: {
            title: "Företag",
            permissions: PermissionGroups.CompanyPermissions
        },
        children: {
            default: {
                path: '',
                redirectTo: "cars",
                pathMatch: "full",
                data: {
                    title: "REMOVE",
                }
            },
            users: {
                path: 'users',                
                data: {
                    routerLink: "/company/users",
                    showInMenu: true,
                    title: "Användare",
                    permissions: [Permission.AdministreraFöretagsanvändare]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            permissions: [Permission.AdministreraFöretagsanvändare]
                        }
                    },
                    new_id: {
                        path: 'new/:customerId',
                        data: {
                            title: "Ny användare",
                            permissions: [Permission.AdministreraFöretagsanvändare]
                        }
                    },
                    edit_id: {
                        path: 'edit/:userId',
                        data: {
                            title: "Redigera användare",
                            permissions: [Permission.AdministreraFöretagsanvändare]
                        }
                    }
                }
            },
            cars: {
                path: 'cars',                
                data: {
                    routerLink: "/company/cars",
                    showInMenu: true,
                    title: "Vagnparksöversikt",
                    permissions: [Permission.Vagnparksöversikt]
                }
            },
            invoices: {
                path: 'invoices',                
                data: {
                    routerLink: "/company/invoices",
                    showInMenu: true,
                    title: "Fakturor",
                    permissions: [Permission.LäsaFakturor]
                },
                children: {
                    default: {
                        path: '',                        
                        data: {
                            title: "Fleetfakturor",
                            permissions: [Permission.LäsaFakturor]
                        }
                    },
                    supplier: {
                        path: 'supplier',                        
                        data: {
                            title: "Leverantörsfakturor",
                            permissions: [Permission.LäsaFakturor]
                        }
                    }
                }
            },
            support_cases: {
                path: '',
                data: {
                    title: "Ärenden",
                    routerLink: "/support-cases",
                    showInMenu: true,
                    permissions: PermissionGroups.ReadSupportCasePermissions
                },
                children: {
                    default: {
                        path: '',
                        redirectTo: "support-case-list",
                        pathMatch: 'full'
                    },
                    support_case_list: {
                        path: "support-case-list",
                        data: {
                            title: "Översikt",
                            permissions: PermissionGroups.ReadSupportCasePermissions
                        }
                    },
                    support_case_show: {
                        //path: "support-case-show/:supportCaseId",
                        path: "support-case-show",
                        data: {
                            title: "Ärende",
                            permissions: PermissionGroups.ReadSupportCasePermissions
                        }
                    },
                    support_case_new: {
                        path: "support-case-new",
                        data: {
                            title: "Nytt ärende",
                            permissions: PermissionGroups.CreateSupportCasePermissions
                        }
                    },
                    change_driver_account_information_partId: {
                        path: "change-driver-account-information/:partId",
                        data: {
                            title: "Ändra föraruppgifter",
                            permissions: [Permission.SkapaÄndraFöraruppgifterÄrendeAllaUppgifter, Permission.SkapaÄndraFöraruppgifterÄrendeEndastAnställningsnummer]
                        }
                    },
                    change_driver_objectId: {
                        path: "change-driver/:objectId",
                        data: {
                            title: "Ändra förare",
                            permissions: [Permission.SkapaÄndraFörareÄrende]
                        }
                    },
                    change_cost_center_objectId: {
                        path: "change-cost-center/:objectId",
                        data: {
                            title: "Ändra kostnadsställe",
                            permissions: [Permission.SkapaÄndraKostnadsställeÄrende]
                        }
                    },
                    change_car_category_objectId: {
                        path: "change-car-category/:objectId",
                        data: {
                            title: "Ändra bilkategori",
                            permissions: [Permission.SkapaÄndraBilkategoriÄrende]
                        }
                    },
                    order_fuel_card_objectId: {
                        path: "order-fuel-card/:objectId",
                        data: {
                            title: "Beställ drivmedelskort",
                            permissions: [Permission.SkapaBeställDrivmedelskortÄrende]
                        }
                    }
                }
            },
            reports: {
                path: '',
                data: {
                    renderAsParent: true,
                    showInMenu: true,
                    title: "Rapporter",
                    permissions: PermissionGroups.ReportPermissions
                },
                children: {
                    default: {
                        path: '',
                        redirectTo: "/reports/benefit-value-summary"
                    },
                    benefit_value_summary: {
                        path: 'benefit-value-summary',
                        data: {
                            routerLink: "/reports/benefit-value-summary",
                            showInMenu: true,
                            title: "Förmånsvärdessammanställning",
                            permissions: [Permission.RapportFörmånsvärdesammanställning]
                        }
                    },
                    cost_overview_driver: {
                        path: 'cost-overview-driver',
                        data: {
                            routerLink: "/reports/cost-overview-driver",
                            showInMenu: true,
                            title: "Kostnadsöversikt - förare",
                            permissions: [Permission.RapportKostnadsöversiktFörare, Permission.RapportKostnadsöversiktFöretag]
                        }
                    },
                    gross_reduction: {
                        path: 'gross-reduction',
                        data: {
                            routerLink: "/reports/gross-reduction",
                            showInMenu: true,
                            title: "Löneavdrag personalbil",
                            permissions: [Permission.RapportBruttolöneavdrag]
                        }
                    },
                    salary_basis: {
                        path: 'salary-basis',
                        data: {
                            routerLink: "/reports/salary-basis",
                            showInMenu: true,
                            title: "Löneunderlag",
                            permissions: [Permission.RapportLöneunderlag]
                        }
                    },
                    environmental: {
                        path: 'environmental',
                        data: {
                            routerLink: "/reports/environmental",
                            showInMenu: true,
                            title: "Miljörapport",
                            permissions: [Permission.RapportMiljörapport]
                        }
                    },
                    reported_mileage: {
                        path: 'reported-mileage',
                        data: {
                            routerLink: "/reports/reported-mileage",
                            showInMenu: true,
                            title: "Rapporterad körsträcka",
                            permissions: [Permission.RapportRapporteradKörsträcka]
                        }
                    },
                    split_calculation: {
                        path: 'split-calculation',
                        data: {
                            routerLink: "/reports/split-calculation",
                            showInMenu: true,
                            title: "Splitberäkning - förare",
                            permissions: [Permission.RapportSplittberäkningFörare]
                        }
                    },
                    total_costs: {
                        path: 'total-costs',
                        data: {
                            routerLink: "/reports/total-costs",
                            showInMenu: true,
                            title: "Total kostnad",
                            permissions: [Permission.RapportTotalaKostnader]
                        }
                    },
                    transactions: {
                        path: 'transactions',
                        data: {
                            routerLink: "/reports/transactions",
                            showInMenu: true,
                            title: "Transaktionslista",
                            permissions: [Permission.RapportTransaktionslista]
                        }
                    },
                    carpark_overview: {
                        path: 'carpark-overview',
                        data: {
                            routerLink: "/reports/carpark-overview",
                            showInMenu: true,
                            title: "Vagnparksöversikt inkl kostnader",
                            permissions: [Permission.RapportVagnsparksöversikt]
                        }
                    }
                }
            },
            suborder: {
                data: {
                    renderAsParent: true,
                    showInMenu: true,
                    title: "Avrop",
                    permissions: [Permission.AdministreraAvrop]
                },
                children: {
                    suborder: {
                        path: 'suborder',                        
                        data: {
                            routerLink: "/company/suborder",
                            showInMenu: true,
                            title: "Ny bil",
                            permissions: [Permission.AdministreraAvrop]
                        }
                    },
                    suborder_replace_existing: {
                        path: 'suborder-replace-existing',
                        data: {
                            routerLink: "/company/suborder-replace-existing",
                            showInMenu: true,
                            title: "Ersätt befintlig bil",
                            permissions: [Permission.AdministreraAvrop]
                        }
                    },
                    suborder_existing: {
                        path: 'suborder/:existing',
                        data: {
                            title: "Avrop",
                            permissions: [Permission.AdministreraAvrop]
                        }
                    },
                    suborder_caseId_suborderId: {
                        path: 'suborder/:caseId:suborderId',
                        data: {
                            title: "Avrop",
                            permissions: [Permission.AdministreraAvrop]
                        }
                    },
                    suborder_list: {
                        path: 'suborder-list',
                        data: {
                            routerLink: "/company/suborder-list",
                            showInMenu: true,
                            title: "Avropslista",
                            permissions: [Permission.AdministreraAvrop]
                        }
                    }
                }
            },
            report_all: {
                path: 'report/all',
                
                data: {
                    routerLink: "/report/all",
                    showInMenu: true,
                    title: "Rapportera för verksamhetsbil",
                    permissions: [Permission.MätarställningRapporteringFöretag]
                }
            },
            cars_expiring: {
                path: 'cars/expiring',
                
                data: {
                    routerLink: "/company/cars/expiring",
                    showInMenu: true,
                    title: "Fordon som löper ut",
                    permissions: [Permission.LäsaFöretagsbilarSomLöperUt]
                }
            },
            sales: {
                path: 'sales',
                
                data: {
                    routerLink: "/sales",
                    showInMenu: true,
                    title: "Bilförsäljning",
                    permissions: [Permission.LäsaInnehållRiktatTillFleetManagers, Permission.LäsaInnehållRiktatTillAssistantFleetManagers]
                }
            }
        }
    },
    SuperUser: {
        path: '',
        data: {
            permissions: PermissionGroups.SuperUserPermissions
        },
        children: {
            default: {
                path: '',
                redirectTo: "/impersonate"
            },
            Impersonate: {
                path: 'impersonate',
                data: {
                    routerLink: "/super-user/impersonate",
                    title: "Logga in som",
                    permissions: [Permission.ImpersonateLogin]
                }
            },
            internal_users:{
                path: 'internal-users',
                data: {
                    routerLink: "/super-user/internal-users",
                    showInMenu: true,
                    icon: "c-icon--[two-users]",
                    title: "Interna användare",
                    permissions: [Permission.AdministreraInternaAnvändare]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            permissions: [Permission.AdministreraInternaAnvändare]
                        }
                    },
                    new: {
                        path: 'new',
                        data: {
                            title: "Ny intern användare",
                            permissions: [Permission.AdministreraInternaAnvändare]
                        }
                    },
                    edit_id: {
                        path: 'edit/:userId',
                        data: {
                            title: "Redigera intern användare",
                            permissions: [Permission.AdministreraInternaAnvändare]
                        }
                    }
                }
            },
            user_admin: {
                path: 'user-admin',
                data: {
                    routerLink: "/super-user/user-admin",
                    showInMenu: true,
                    icon: "c-icon--[two-users]",
                    title: "Användare",
                    permissions: [Permission.AdministreraAllaAnvändare]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            permissions: [Permission.AdministreraAllaAnvändare]
                        }
                    },
                    new_id: {
                        path: 'new/:customerId',
                        data: {
                            title: "Ny användare",
                            permissions: [Permission.AdministreraAllaAnvändare]
                        }
                    },
                    edit_id: {
                        path: 'edit/:userId',
                        data: {
                            title: "Redigera användare",
                            permissions: [Permission.AdministreraAllaAnvändare]
                        }
                    }
                }
            },
            faq: {
                path: 'faq',
                
                data: {
                    routerLink: "/super-user/faq",
                    showInMenu: true,
                    icon: "c-icon--[faq]",
                    title: "Vanliga frågor",
                    permissions: [Permission.AdministreraVanligaFrågor]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            title: "Översikt",
                            permissions: [Permission.AdministreraVanligaFrågor]
                        }
                    },
                    new: {
                        path: 'new',
                        data: {
                            title: "Ny fråga",
                            permissions: [Permission.AdministreraVanligaFrågor]
                        }
                    },
                    edit_id: {
                        path: 'edit/:id',
                        data: {
                            title: "Redigera",
                            permissions: [Permission.AdministreraVanligaFrågor]
                        }
                    }
                }
            },
            forms: {
                path: 'forms',
                data: {
                    routerLink: "/super-user/forms",
                    showInMenu: true,
                    icon: "c-icon--[document-lines-checkmark]",
                    title: "Information & blanketter",
                    permissions: [Permission.AdministreraInformationOchBlanketter]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            title: "Översikt",
                            permissions: [Permission.AdministreraInformationOchBlanketter]
                        }
                    },
                    new: {
                        path: 'new',
                        data: {
                            title: "Ladda upp",
                            permissions: [Permission.AdministreraInformationOchBlanketter]
                        }
                    },
                    edit_id: {
                        path: 'edit/:id',
                        data: {
                            title: "Redigera",
                            permissions: [Permission.AdministreraInformationOchBlanketter]
                        }
                    }
                }
            },
            news: {
                path: 'news',
                data: {
                    routerLink: "/super-user/news",
                    showInMenu: true,
                    icon: "c-icon--[newsletter]",
                    title: "Nyheter",
                    permissions: [Permission.AdministreraNyheter]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            title: "Översikt",
                            permissions: [Permission.AdministreraNyheter]
                        }
                    },
                    new: {
                        path: 'new',
                        data: {
                            title: "Ny nyhet",
                            permissions: [Permission.AdministreraNyheter]
                        }
                    },
                    edit_id: {
                        path: 'edit/:id',
                        data: {
                            title: "Redigera",
                            permissions: [Permission.AdministreraNyheter]
                        }
                    }
                }
            },
            affiliates: {
                path: 'affiliates',
                data: {
                    routerLink: "/super-user/affiliates",
                    showInMenu: true,
                    icon: "c-icon--[dealer]",
                    title: "Samarbetspartners",
                    permissions: [Permission.AdministreraSamarbetspartners]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            title: "Översikt",
                            permissions: [Permission.AdministreraSamarbetspartners]
                        }
                    },
                    new: {
                        path: 'new',
                        data: {
                            title: "Lägg till",
                            permissions: [Permission.AdministreraSamarbetspartners]
                        }
                    },
                    edit_id: {
                        path: 'edit/:id',
                        data: {
                            title: "Redigera",
                            permissions: [Permission.AdministreraSamarbetspartners]
                        }
                    }
                }
            },
            assistance: {
                path: 'assistance',
                data: {
                    routerLink: "/super-user/assistance",
                    showInMenu: true,
                    icon: "c-icon--[lightbulb]",
                    title: "Assistansbolag",
                    permissions: [Permission.AdministreraAssistansbolag]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            title: "Översikt",
                            permissions: [Permission.AdministreraAssistansbolag]
                        }
                    },
                    new: {
                        path: 'new',
                        data: {
                            title: "Lägg till",
                            permissions: [Permission.AdministreraAssistansbolag]
                        }
                    },
                    edit_id: {
                        path: 'edit/:id',
                        data: {
                            title: "Redigera",
                            permissions: [Permission.AdministreraAssistansbolag]
                        }
                    }
                }
            },
            external_api_admin: {
                path: 'external-api-admin',
                data: {
                    routerLink: "/super-user/external-api-admin",
                    showInMenu: true,
                    icon: "c-icon--[chip]",
                    title: "Fleet External API",
                    permissions: [Permission.AdministreraExternaKörjournalsrapporter, Permission.AdministreraStatusExternKörjournalsrapportering]
                },
                children: {
                    default: {
                        path: '',
                        data: {
                            title: "API:ets öppettider",
                            permissions: [Permission.AdministreraStatusExternKörjournalsrapportering]
                        }
                    },
                    toll: {
                        path: 'toll',
                        data: {
                            title: "Trängselskatt",
                            permissions: [Permission.AdministreraExternaKörjournalsrapporter]
                        }
                    },
                    toll_detail_reportId_reportDriverId: {
                        path: 'toll-detail/:reportId:reportDriverId',
                        data: {
                            title: "Trängselskatterapport",
                            permissions: [Permission.AdministreraExternaKörjournalsrapporter]
                        }
                    },
                    toll_detail: {
                        path: 'toll-detail',
                        data: {
                            title: "Trängselskatterapport",
                            permissions: [Permission.AdministreraExternaKörjournalsrapporter]
                        }
                    },
                    mileage: {
                        path: 'mileage',
                        data: {
                            title: "Kilometerrapport",
                            permissions: [Permission.AdministreraExternaKörjournalsrapporter]
                        }
                    },
                    mileage_detail_reportId_reportDriverId: {
                        path: 'mileage-detail/:reportId:reportDriverId',
                        data: {
                            title: "Kilometerrapport",
                            permissions: [Permission.AdministreraExternaKörjournalsrapporter]
                        }
                    },
                    mileage_detail: {
                        path: 'mileage-detail',
                        data: {
                            title: "Kilometerrapport",
                            permissions: [Permission.AdministreraExternaKörjournalsrapporter]
                        }
                    }
                }
            }
        }
    },
    Home: {
        children: {            
            default: {
                path: '',                
                data: {
                    showInMenu: false
                }
            },
            forms: {
                path: 'forms',                
                data: {
                    routerLink: "/forms",
                    showInMenu: true,
                    title: "Information & blanketter",
                    permissions: [Permission.LäsaInformationOchBlanketter]
                }
            },
            affiliates: {
                path: 'affiliates',
                data: {
                    routerLink: "/affiliates",
                    showInMenu: true,
                    title: "Samarbetspartners"
                }
            },
            faq: {
                path: 'faq',
                data: {
                    routerLink: "/faq",
                    showInMenu: true,
                    title: "Vanliga frågor"
                }
            },
            news: {
                path: 'news',
                data: {
                    title: "Nyheter",
                    permissions: [Permission.LäsaNyheter]
                }
            },
            notifications: {
                path: 'notifications',
                data: {
                    routerLink: "",
                    title: "Påminnelser",
                    permissions: [Permission.LäsaPåminnelser]
                }
            },            
            assistance: {
                path: 'assistance',
                data: {
                    routerLink: "/assistance",
                    showInMenu: true,
                    icon: "c-icon--[control-lightbulb]",
                    title: "Assistans"
                }
            },
            support: {
                path: 'support',
                data: {
                    routerLink: "/support",
                    showInMenu: true,
                    icon: "c-icon--[control-support]",
                    title: "Support"
                }
            },
            contact_information: {
                path: 'contact-information',
                data: {
                    routerLink: "/contact-information",
                    showInMenu: true,
                    icon: "c-icon--[control-comments]",
                    title: "Kontakt"
                }
            },
            driver: {
                path: 'driver',
            },
            fleet_manager: {
                path: 'fleet-manager',
                data: {
                    permissions: [Permission.FleetDashboard]
                }
            }
        }
    },
    Profile: {
        path: '',
        children: {            
            cars: {
                path: '',
                data: {
                    title: "Bilar",
                    showInMenu: true,
                    routerLink: "/cars",
                    badgeCounter: "carCount",
                    badgeColor: "badge-info-inverse",
                    permissions: [Permission.LäsaEgnaFordon]
                },
                children: {
                    default: {
                        path: '',
                        redirectTo: "overview",
                        pathMatch: 'full'
                    },
                    overview: {
                        path: 'overview',                
                        data: {
                            title: "Översikt",
                            permissions: [Permission.LäsaEgnaFordon]
                        }
                    },
                    information_license: {
                        path: 'information/:license/:accountnumber',                
                        data: {
                            title: "Information",
                            permissions: [Permission.LäsaEgnaFordon]
                        }
                    },
                    history_license: {
                        path: 'history/:license',                
                        data: {
                            title: "Rapporteringshistorik",
                            permissions: [Permission.KilometerRapportering, Permission.TrängselskattRapportering]
                        }
                    },
                    mileage_license: {
                        path: 'mileage/:license/:accountnumber',                
                        data: {
                            title: "Kilometerrapport",
                            permissions: [Permission.KilometerRapportering]
                        }
                    },
                    mileage_nfb_license: {
                        path: 'mileage/nfb/:license/:accountnumber',                
                        data: {
                            title: "Mätarställning",
                            permissions: [Permission.MätarställningRapportering]
                        }
                    },
                    toll_license: {
                        path: 'toll/:license/:accountnumber',                
                        data: {
                            title: "Trängselskatt",
                            permissions: [Permission.TrängselskattRapportering]
                        }
                    }
                }
            },
            notifications: {
                path: 'notifications',                
                data: {
                    routerLink: "/notifications",
                    showInMenu: true,
                    title: "Påminnelser",
                    permissions: [Permission.LäsaPåminnelser],
                    badgeCounter: "notificationCount",
                    badgeColor: "badge-danger"
                }
            },
            news: {
                path: 'news',                
                data: {
                    routerLink: "/news",
                    showInMenu: true,
                    title: "Nyheter",
                    permissions: [Permission.LäsaNyheter]
                }
            },
            history: {
                path: 'history',
                data: {
                    routerLink: "/cars/history",
                    showInMenu: true,
                    title: "Rapporteringshistorik",
                    permissions: [Permission.Rapporteringshistorik]
                }
            },
            account_settings: {
                path: 'account-settings',
                data: {
                    routerLink: "/account-settings",
                    showInMenu: true,
                    icon: "c-icon--[control-user]",
                    title: "Min profil",
                }
            }
        }
    }
};
