export class SearchFrameAgreementQuery {
    
    constructor() {
        this.pageSize = 15;
        this.page = 1;        
    }

    pageSize: number;
    page: number;
    searchText: string;
    includeDisabled: boolean;

    toQueryString() {
        var queryString = 
        "?pagesize=" + this.pageSize + 
        "&page=" + this.page + 
        "&includeDisabled=" + this.includeDisabled;
        
        if(this.searchText) {
            queryString += "&searchText=" +  this.searchText;
        }

        return queryString;
    }
}
