export class MileageReportResponse {
    
    constructor(jsonObject: any = null) {
        if(jsonObject == null) {
            return;
        }

        this.isLate= jsonObject.isLate;        
    }

    isLate: boolean;
}