<div  class="c-card notification o-layout__item mb-3">
    <div class="d-flex justify-content-between align-items-start mb-1">
        <div class="d-flex justify-content-between align-items-start">
            <h4 class="me-1 mb-0" *ngIf="!showSummary && notificationGroup.type === NotificationType.Mileage">Rapportera kilometer</h4>
            <h4 class="me-1 mb-0" *ngIf="!showSummary && notificationGroup.type === NotificationType.Toll">Rapportera trängselskatt</h4>            
            <h3 class="me-1 mb-0" *ngIf="showSummary && notificationGroup.type === NotificationType.Mileage">Rapportera kilometer</h3>
            <h3 class="me-1 mb-0" *ngIf="showSummary && notificationGroup.type === NotificationType.Toll">Rapportera trängselskatt</h3>            
        </div>
        <span class="badge badge-info">{{ notificationGroup.license }}</span>    
    </div>      
    <ul *ngIf="showSummary" class="m-4">
        <li class="mb-2" *ngFor="let item of notificationGroup.notifications">
            <span>{{ item.yearAndMonth | date: 'yyyy-MM'}}</span>
            <span>&nbsp;</span>
            <ng-template [ngIf]="item.daysLeft > 0">
                <span class="text-grey">rapportera inom <b>{{item.daysLeft}}</b> dagar</span>
            </ng-template>
            <ng-template [ngIf]="item.daysLeft === 0">
                <span class="text-grey">rapportera idag</span>
            </ng-template>
            <ng-template [ngIf]="item.daysLeft < 0">
                <span class="text-grey">är <b class="text-red">försenad</b></span> 
            </ng-template>
        </li>        
    </ul>
    <a class="c-btn c-btn--full c-btn--link c-btn--small" (click)="navigate()">
        <i class="c-btn__icon  c-icon  c-icon--[control-arrow-right]"></i>
        <span class="c-btn__text">Rapportera nu</span>
    </a>
</div>
