import { ApplicationError } from "./application-error.model";

export class ClientError extends ApplicationError {
    
    public static StandardContactText = " Vänligen kontakta Fleet Support.";

    constructor(jsonError: any) {
        const message = jsonError?.detail ?? jsonError?.title ?? ApplicationError.standardErrorMessage;
        super(message);      
    }

    public override fullMessage(showContactFleet = false){
        return `${this._message}${showContactFleet ? ClientError.StandardContactText : ""}`;            
    }
}
