import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class LoggingService {

  logDebug(message?: any, ...optionalParams: any[]) {
    if (!environment.enableLogging) {
      return;
    }

    if (optionalParams.length > 0) {
      console.debug(message, optionalParams);
    }
    else {
      console.debug(message);
    }
  }

  logInformation(message?: any, ...optionalParams: any[]) {
    if (!environment.enableLogging) {
      return;
    }

    if (optionalParams.length > 0) {
      console.info(message, optionalParams);
    }
    else {
      console.info(message);
    }
  }

  logWarning(message?: any, ...optionalParams: any[]) {
    if (!environment.enableLogging) {
      return;
    }

    if (optionalParams.length > 0) {
      console.warn(message, optionalParams);
    }
    else {
      console.warn(message);
    }
  }

  logError(message?: any, ...optionalParams: any[]): void {
    if (!environment.enableLogging) {
      return;
    }
    
    if (optionalParams.length > 0) {
      console.error(message, optionalParams);
    }
    else {
      console.error(message);
    }    
  }
}
