import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { Dealer } from '../models/dealer.model';
import { SearchDealerQuery } from '../models/filters/search-dealer-query.model';
import { QueryResult } from '../models/query-result.model';
import { FavoriteSeller } from '@domain/models/favorite-seller.model';

@Injectable({
    providedIn: "root"
})
export class DealerService {

    constructor(private _apiService: BaseApiService) { }

    getDealers(searchDealerQuery: SearchDealerQuery): Observable<QueryResult<Dealer>> {
        return this._apiService.get("Dealer" + searchDealerQuery.toQueryString()).pipe(
            map(data => {                    
                let searchResult = new QueryResult<Dealer>(data, Dealer);
                return searchResult;
            })
        );
    }

    getDealer(id: number): Observable<Dealer> {
        return this._apiService.get(`Dealer/${id}`).pipe(
            map(data => {
                let dealer = new Dealer(data);
                return dealer;
            })
        );
    }

    getFavoriteSellers(): Observable<Array<FavoriteSeller>> {
        return this._apiService.get("Dealer/GetFavoriteSellers").pipe(
            map(data => {                 
                var searchResult = data.map(b => { return new FavoriteSeller(b); });   
                return searchResult;
            })
        );
    }

    deleteFavoriteSeller(id: number): Observable<void> {
        return this._apiService.delete(`Dealer/${id}`);
    }

    addFavoriteSeller(seller: FavoriteSeller) {
        return this._apiService.post(`Dealer/AddFavoriteSeller`, seller).pipe(
          map(data => {
            return data;
          })
        );
    }
}
