export class SearchSuborderQuery {
    
    constructor(jsonObject: any = null) {
        this.pageSize = 20;
        this.page = 1;
        this.searchText = null;
        this.notSigned = true;
        this.signed = true;
        this.carOrdered = true;
        this.contractStarted = false;
        this.cancelled = false;     
        
        if(jsonObject == null) {
            return;
        }
                    
        this.cancelled = jsonObject.cancelled;
        this.carOrdered = jsonObject.carOrdered;
        this.contractStarted = jsonObject.contractStarted;
        this.notSigned = jsonObject.notSigned;
        this.signed = jsonObject.signed;
        this.searchText = jsonObject.searchText;
        this.page = jsonObject.page;
        this.pageSize = jsonObject.pageSize;     
    }

    pageSize: number;
    page: number;
    searchText: string;
    notSigned: boolean;
    signed: boolean;
    carOrdered: boolean;
    contractStarted: boolean;
    cancelled: boolean;

    public get isFiltering() {
        return !this.notSigned ||
         !this.signed || 
         !this.carOrdered ||
        this.contractStarted ||
        this.cancelled;
    }

    public reset(){
        this.pageSize = 20;
        this.page = 1;
        this.searchText = null;
        this.notSigned = true;
        this.signed = true;
        this.carOrdered = true;
        this.contractStarted = false;
        this.cancelled = false;        
    }

    toQueryString() {
        var queryString =  "?notSigned=" + this.notSigned + 
        "&signed=" + this.signed + 
        "&carOrdered=" + this.carOrdered + 
        "&cancelled=" +  this.cancelled + 
        "&contractStarted=" + this.contractStarted + 
        "&pagesize=" + this.pageSize + 
        "&page=" + this.page;
        
        if(this.searchText)
        {
            queryString += "&searchText=" +  this.searchText;
        }

        return queryString;
    }
}
