<ng-template [ngIf]="driver">
  <h4 class="u-mb-xsmall">Personuppgifter</h4>
  <hr class="mt-3 mb-3">
  <ul class="user-details">
    <li><b>Förnamn:</b> {{ driver.firstName }}</li>
    <li><b>Efternamn:</b> {{ driver.lastName }}</li>
    <li>
      <b>Startdatum: </b><span *ngIf="driver.startDate; else noInfo">{{ driver.startDate | date:'yyyy-MM-dd' }}</span>
    </li>
    <li>
      <b>Slutdatum: </b><span *ngIf="driver.endDate; else noInfo">{{ driver.endDate | date:'yyyy-MM-dd' }}</span>
    </li>
    <li *ngIf="driver.contactAddress"><b>Adress:</b> {{ driver.contactAddress.address1 }}</li>
    <li *ngIf="driver.contactAddress"><b>Postnummer:</b> {{ driver.contactAddress.zipCode }}</li>
    <li *ngIf="driver.contactAddress"><b>Ort:</b> {{ driver.contactAddress.city }}</li>
    <li *ngIf="driver.contactAddress"><b>Telefon:</b> {{ driver.contactAddress.phone }}</li>
    <li *ngIf="driver.contactAddress"><b>Mobilnummer:</b> {{ driver.contactAddress.mobilePhone }}</li>
    <li *ngIf="driver.contactAddress"><b>E-post:</b> {{ driver.contactAddress.email }}</li>
    <li><b>Anställningsnummer:</b> {{ driver.employeeNumber }}</li>
  </ul>
</ng-template>


<ng-template #noInfo>
  <span> - </span>
</ng-template>

