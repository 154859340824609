import { Component, Output, EventEmitter, Input } from "@angular/core";
import { ServiceLevel } from '@core/models/service-level.enum';
import { AuthenticationService } from "@core/services/authentication.service";
import { RouteMetadata } from '@shared/route-metadata';


@Component({
  selector: "app-hamburger-menu",
  templateUrl: "./hamburger-menu.component.html",
  styleUrls: ["./hamburger-menu.component.scss"]
})
export class HamburgerMenuComponent  {

  @Output() hideMenu = new EventEmitter<any>();
  @Input() active: boolean;

  public ServiceLevel = ServiceLevel;
  routeMetadata = RouteMetadata;
  routeMetadataArray: any[] = [];
  secondLevelOpen: boolean[];
  counter: number = 0;

  constructor(private _authenticationService: AuthenticationService) {
    this.secondLevelOpen = [];
  }

  keepOrder = (a, b) => {
    return a;
  }
 
  closeMenu() {
    this.secondLevelOpen.fill(false);
    this.hideMenu.emit();
  }

  toggleSecondLevelMenu(i: number) {
    this.secondLevelOpen.forEach((item, index) => {
      if ((index) != i) {
        this.secondLevelOpen[index] = false;
      }
    });
    this.secondLevelOpen[i] = !this.secondLevelOpen[i];
  }

  secondLevelIsOpen(): boolean {
    return this.secondLevelOpen.includes(true);
  }

  hasPermission(permissions: string[]): boolean{
    for(const permission of permissions) {
       if(this._authenticationService.hasPermissionStringInput(permission)) {
       return true;
       }
   }
   return false;
 }   


}
