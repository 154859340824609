import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import DOMPurify from "dompurify";

DOMPurify.addHook("afterSanitizeAttributes", node => {
  if ("target" in node) {
    //node.setAttribute("target", "_blank");
    //rel = "noopener" prevents the new page from being able to access the window.opener property and ensures it runs in a separate process.
    node.setAttribute("rel", "noopener");
  }
});

@Pipe({
  name: "safeHtml"
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {

  }

  transform(value: any): any {
    const sanitizedContent = DOMPurify.sanitize(value, { ADD_ATTR: ["target"] });
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
