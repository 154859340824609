import { AgreementFuelCard } from './agreement-fuel-card.model';
import { Agreement } from './agreement.model';
import { MarketProduct } from './market-product.model';
import { ServiceLevel } from './service-level.model';

export class AgreementDetail extends Agreement {

  constructor(jsonObj: any = null) {
    super(jsonObj);

    if (jsonObj == null) {
      return;
    }

    this.isOperationalLease = jsonObj.isOperationalLease;
    this.contractDurationMin = jsonObj.contractDurationMin;
    this.contractDurationMax = jsonObj.contractDurationMax;

    this.serviceLevels = new Array<ServiceLevel>();
    jsonObj.serviceLevels.forEach((element) => {
      this.serviceLevels.push(new ServiceLevel(element));
    });

    this.agreementFuelCards = new Array<AgreementFuelCard>();
    jsonObj.agreementFuelCards.forEach((element) => {
      this.agreementFuelCards.push(new AgreementFuelCard(element));
    });

    if (jsonObj.marketProduct) {
      this.marketProduct = new MarketProduct(jsonObj.marketProduct);
    }
  }

  serviceLevels: ServiceLevel[];
  agreementFuelCards: AgreementFuelCard[];

  isOperationalLease?: boolean;
  contractDurationMin?: number;
  contractDurationMax?: number;
  marketProduct?: MarketProduct = null;


  get numberOfTerms(): Array<number> {
    if (!this.contractDurationMin && !this.contractDurationMax) {
      return null;
    }
    let numberOfTermsSpan = new Array<number>();
    for (let i = this.contractDurationMin; i <= this.contractDurationMax; i++) {
      if ((i % 6 == 0 && i < 25) || (i % 12 == 0 && i > 24)) {
        numberOfTermsSpan.push(i);
      }
    }
    return numberOfTermsSpan;
  }
}

