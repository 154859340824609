import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/services/base-api.service';
import { Observable, map } from 'rxjs';
import { SalesLinkResponse } from '@domain/models/saleslinkresponse.model';
import { FleetCCLinkResponse } from '@domain/models/fleetcclinkresponse.model';

@Injectable({
  providedIn: 'root'
})

export class ApplicationService {

  constructor(private _apiService: BaseApiService) { }

  getStatus(): Observable<boolean> {
    return this._apiService.get("Application/IsOnline");
  }

  getSalesLink(): Observable<SalesLinkResponse> {
    return this._apiService.get("Application/SalesLink").pipe(
      map(data => {
        let result = new SalesLinkResponse(data);
        return result;
      })
    );
  } 

  getFleetCCLink(): Observable<FleetCCLinkResponse> {
    return this._apiService.get("Application/FleetCCLink").pipe(
      map(data => {
        let result = new FleetCCLinkResponse(data);
        return result;
      })
    );
  } 
}
