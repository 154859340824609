<form [formGroup]="formGroup">
  <div class="o-fieldset__row mb-3">
    <label>Visa för företag/organisationer</label>
    <div class="switch mt-2">
      <input type="checkbox" class="switch" id="send-to-all" [checked]="allCustomers" (change)="toggleAllCustomers()">
      <label for="send-to-all">{{ allCustomers ? "Alla företag/organisationer" : "Utvalda företag/organisationer" }}</label>
    </div>
    @if(!allCustomers){
    <div>
      <div class="c-input focus-within mt-2">
        <app-customer-search class="w-100"
                             [clearSearchOnSelectedMatch]="true"
                             [(excludePartIds)]="excludePartIds"
                             (loadingChanged)="onLoadingChanged($event)"
                             (selected)="onCustomerSelected($event)">
        </app-customer-search>
      </div>
      <div class="mt-2">
        <div *ngFor="let selectedCustomer of selectedCustomers">
          <div>
            {{selectedCustomer.name}}&nbsp;{{selectedCustomer.organizationNumber}}
            <i class="c-icon--small c-icon c-icon--[bin] float-end show-cursor" title="Visa inte för företaget/organisationen" (click)="unselectCustomer(selectedCustomer.customerId)"></i>
          </div>
        </div>
        <div *ngIf="validationCustomerMessage">
          <small class="is-error w-100">{{validationCustomerMessage}}</small>
        </div>
      </div>
    </div>
    }
  </div>
</form>
