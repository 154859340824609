<div class="d-flex justify-content-between align-items-start">
    <div class="d-flex justify-content-between align-items-start">
        <h3 class="u-mb-xsmall">{{ car.marketProduct.name }}</h3>
        <span *ngIf="!car.isActiveDriver" class="badge badge-warning ms-2">Avslutad</span>
    </div>
    <span class="badge badge-info">{{ car.license }}</span>    
</div>
<p class="u-mb-small">
    {{ car.make }} {{ car.model }}
</p>
<hr class="mb-3">
<p>
    <a [routerLink]="['/cars/information', car.license, car.accountNumber]" class="c-btn c-btn--full c-btn--link c-btn--small">
        <i class="c-btn__icon  c-icon  c-icon--[control-arrow-right]"></i>
        <span class="c-btn__text">Bilinformation</span>
    </a>
    <a *ngIf="canSeeReportHistory" [routerLink]="['/cars/history', car.license]" class="c-btn c-btn--full c-btn--link c-btn--small">
        <i class="c-btn__icon  c-icon  c-icon--[control-arrow-right]"></i>
        <span class="c-btn__text">Rapporteringshistorik</span>
    </a>
</p>
<small *ngIf="canReport">
    Rapportera
</small>
<a *ngIf="canReportMileageNoFuelBenefit" [routerLink]="['/cars/mileage/nfb', car.license, car.accountNumber]" class="c-btn c-btn--full mb-2">
    <span class="c-btn__text">Mätarställning</span>
</a>
<a *ngIf="canReportMileage" [routerLink]="['/cars/mileage', car.license, car.accountNumber]" class="c-btn c-btn--full mb-2">
    <span class="c-btn__text">Kilometer</span>
</a>
<a *ngIf="canReportToll" [routerLink]="['/cars/toll', car.license, car.accountNumber]" class="c-btn c-btn--full c-btn--secondary mb-2">
    <span class="c-btn__text">Trängselskatt</span>
</a>


