import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private _apiService: BaseApiService) { }

    download(id: number): Observable<any> {
        return this._apiService.download(`File/Download/${id}`, null);
    }    

    openFile(data: Blob, fileName: string, contentType: string) {
        var newBlob = new Blob([data], { type: contentType });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const file = URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = file;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
