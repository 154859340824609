import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { CarInfoModel } from '../models/car-info.model';
import { CarDetailModel } from '../models/car-detail.model';
import { CompanyCarInfoModel } from '../models/company-car-info.model';
import { CarMatch } from "@domain/models/car-match.model";
import { CarAndDriverQuery } from '@domain/models/filters/car-and-driver-query-model';
import { QueryResult } from '../models/query-result.model';
import { FleetDataModel } from '@domain/models/fleet-data.model';
import { CompanyCarDetail } from '@domain/models/company-car-detail.model';
import { DriverDetailModel } from '../models/driver-detail.model';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  constructor(private _apiService: BaseApiService) { }

  getCarInfo(license: string, accountNumber: number = null): Observable<CarInfoModel> {
    return this._apiService.get(`Car/${license}/?accountnumber=${accountNumber}`).pipe(map(
      data => {
        let result = new CarInfoModel(data);
        return result;
      })
    );
  }

  getCarDetail(query: CarAndDriverQuery): Observable<CarDetailModel>;
  getCarDetail(license: string, accountNumber: number): Observable<CarDetailModel>;
  getCarDetail(licenseOrQuery: string | CarAndDriverQuery, accountNumber?: number): Observable<CarDetailModel> {
    return ((licenseOrQuery instanceof (CarAndDriverQuery))
      ? this._apiService.post("Car/detail", licenseOrQuery)
      : this._apiService.get(`Car/${licenseOrQuery}/Detail/?accountnumber=${accountNumber}`)).pipe(
        map(data => {
          let result = new CarDetailModel(data);
          return result;
        })
      );
  }

  getDriverDetail(query: CarAndDriverQuery): Observable<DriverDetailModel> {
    return this._apiService.post("Car/driver", query).pipe(map(
      data => {
        let result = new DriverDetailModel(data);
        return result;
      })
    );
  }

  getCars(filter: CarAndDriverQuery = null): Observable<CarInfoModel[]> {
    return this._apiService.post("Car", filter).pipe(map(
      data => {
        if (!data) {
          return data;
        }

        let cars = data.map(car => { return new CarInfoModel(car); });
        return cars;
      })
    );
  }

  getNumberOfCars(filter: CarAndDriverQuery = null): Observable<number> {
    return this._apiService.post("Car/Count", filter);
  }

  getCarInformation(objectId: number): Observable<CompanyCarDetail> {
    return this._apiService.get(`Car/Fleet/${objectId}`).pipe(map(
      data => {
        let result = new CompanyCarDetail(data);
        return result;
      })
    );
  }

  getCompanyCars(filter: CarAndDriverQuery = null): Observable<QueryResult<CompanyCarInfoModel>> {
    return this._apiService.post("Car/Fleet", filter).pipe(map(
      data => {
        let carResult = new QueryResult<CompanyCarInfoModel>(data, CompanyCarInfoModel);
        return carResult;
      })
    );
  }

  getCompanyCarsReport(filter: CarAndDriverQuery): Observable<Blob> {
    return this._apiService.download("Car/Fleet/Report", filter);
  }

  searchByFleetManager(query: CarAndDriverQuery): Observable<QueryResult<CarMatch>> {
    return this._apiService.post("Car/Fleet/Search", query).pipe(
      map(data => {
        let result = new QueryResult<CarMatch>(data, CarMatch);
        return result;
      })
    );
  }

  getFleetData(): Observable<FleetDataModel> {
    return this._apiService.get(`Car/FleetData`).pipe(
      map(data => {
        let result = new FleetDataModel(data);
        return result;
      })
    );
  }
}
