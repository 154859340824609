export class ExtraEquipment
{
    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
            return;
        }
        this.id = jsonObj.id;
        this.name = jsonObj.name;
        this.carId = jsonObj.carId;
        this.price = jsonObj.price;
        this.isFringeBenefit = jsonObj.isFringeBenefit;
    }
    
    id: number;
    name: string;
    carId: number;
    price: number;
    isFringeBenefit: boolean;
}

