export class Addon {
  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.id = jsonObj.id;
    this.name = jsonObj.name;
    this.description = jsonObj.description;
    
  }

  id: number;
  name: string;
  description: string;
}
