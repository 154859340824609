import { MarketProduct } from "./market-product.model";
import { FuelCard } from "@domain/models/fuel-card.model";

export class CarInfoModel {

  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }
    this.objectId = jsonObject.objectId;
    this.license = jsonObject.license;
    this.make = jsonObject.make;
    this.model = jsonObject.model;
    this.modelCode = jsonObject.modelCode;
    this.customerId = jsonObject.customerId;
    this.canReportMileage = jsonObject.canReportMileage;
    this.canReportToll = jsonObject.canReportToll;

    if (jsonObject.marketProduct) {
      this.marketProduct = new MarketProduct(jsonObject.marketProduct);
    }

    if (jsonObject.startDate) {
      this.startDate = new Date(jsonObject.startDate);
    }

    if (jsonObject.endDate) {
      this.endDate = new Date(jsonObject.endDate);
    }

    if (jsonObject.accountStartDate) {
      this.accountStartDate = new Date(jsonObject.accountStartDate);
    }

    if (jsonObject.accountEndDate) {
      this.accountEndDate = new Date(jsonObject.accountEndDate);
    }

    if (jsonObject.accountOriginalEndDate) {
      this.accountOrginalEndDate = new Date(jsonObject.accountOriginalEndDate);
    }

    this.isActiveDriver = jsonObject.isActiveDriver;
    this.isOperationalLease = jsonObject.isOperationalLease;

    if (jsonObject.serviceLevels) {
      this.serviceLevels = new Array<number>();
      jsonObject.serviceLevels.forEach((element) => {
        this.serviceLevels.push(element.sequence);
      });
    }

    if (jsonObject.fuelCards) {
      this.fuelCards = new Array<FuelCard>();
      jsonObject.fuelCards.forEach(fuelCard => {
        this.fuelCards.push(new FuelCard(fuelCard));
      });
    }

    this.agreementId = jsonObject.agreementId;
    this.agreementName = jsonObject.agreementName;
    this.accountStatus = jsonObject.accountStatus;

    this.driverId = jsonObject.driverId;
    this.driverName = jsonObject.driverName;

    if (jsonObject.objectCustomerId)
      this.objectCustomerId = jsonObject.objectCustomerId;

    if (jsonObject.customerNumber) {
      this.customerNumber = jsonObject.customerNumber;
    }

    this.customerName = jsonObject.customerName;
    this.productionYear = jsonObject.productionYear;
    this.accountNumber = jsonObject.accountNumber;
    this.employeeNumber = jsonObject.employeeNumber;

    if (jsonObject.customerId) {
      this.customerId = jsonObject.customerId;
    }
    if (jsonObject.driverPartId) {
      this.driverPartId = jsonObject.driverPartId;
    }
    
    this.organizationPartId = jsonObject.organizationPartId;
    
    if (jsonObject.driverCustomerNumber) {
      this.driverCustomerNumber = jsonObject.driverCustomerNumber;
    }
  }

  objectId: number;

  license: string;
  marketProduct: MarketProduct;
  make: string;
  model: string;
  modelCode: string;
  startDate?: Date;
  endDate?: Date;
  isActiveDriver: boolean;
  isOperationalLease?: boolean;
  serviceLevels: number[];
  accountStatus: number;
  agreementId: number;
  agreementName: string;
  objectCustomerId?: number
  driverId: number;
  driverName: string;
  customerNumber?: number;
  customerName: string;
  productionYear?: number;
  accountNumber: number;
  employeeNumber: string;
  accountStartDate?: Date;
  accountEndDate?: Date;
  accountOrginalEndDate?: Date;
  customerId?: number;
  organizationPartId?: number;
  driverPartId?: number;
  fuelCards: FuelCard[];
  canReportMileage: boolean;
  canReportToll: boolean;
  driverCustomerNumber?: number;

  get accountStatusText(): string {
    if (this.accountStatus === 1) {
      return "Aktivt";
    } else if (this.accountStatus === 2) {
      return "Avslutat";
    }

    return "";
  }

  //First word in Model
  get modelShort(): string {
    if (this.model !== null && this.model !== undefined) {
      return this.model.split(" ")[0];
    }
    return "";
  }
}
