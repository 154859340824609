export class Address {
  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.name = jsonObj.name;
    this.address1 = jsonObj.address1;
    this.address2 = jsonObj.address2;
    this.address3 = jsonObj.address3;
    this.zipCode = jsonObj.zipCode;
    this.city = jsonObj.city;
    this.email = jsonObj.email;
    this.phone = jsonObj.phone;
    this.mobilePhone = jsonObj.mobilePhone;
    this.type = jsonObj.type;
  }

  name: string;
  address1: string;
  address2: string;
  address3: string;
  zipCode: string;
  city: string;
  email: string;
  phone: string;
  mobilePhone: string;
  type?: number;
}
