export class AccountingValidation{
    CostCenterText: Array<string>;
    Code1: Array<string>;
    Code2: Array<string>;

    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.CostCenterText = jsonObj.CostCenterText;
            this.Code1 = jsonObj.Code1;
            this.Code2 = jsonObj.Code2;
        }
    }    

    get isValid(){
        return this.errors.length === 0; 
    }

    get errors(){
        var errors = new Array<string>();     
        
        if(this.CostCenterText)
        {
            this.CostCenterText.forEach(mess => {
                errors.push(mess);
            });
        }
          
        if(this.Code1)
        {
            this.Code1.forEach(mess => {
                errors.push(mess);
            });
        }
          
        if(this.Code2)
        {
            this.Code2.forEach(mess => {
                errors.push(mess);
            });
        }

        return errors;
    }
}
