import { NotificationType } from "./notification-type.enum";

export class Notification{
    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
          return;
        }
    
        this.license = jsonObj.license;
        this.accountNumber = jsonObj.accountNumber;
        this.type = jsonObj.type;
        this.month = jsonObj.month;
        this.year = jsonObj.year;
        this.yearAndMonth = new Date(jsonObj.yearAndMonth);
        this.daysLeft = jsonObj.daysLeft;        
      }
          
      license : string;
      accountNumber: number;
      type : NotificationType;
  
      month: number;
      year: number;          
      yearAndMonth: Date;   
      daysLeft: number;
}