<ng-template [ngIf]="availableMonths && availableMonths.length > 0" [ngIfElse]="noAvailableMonths">
    <fieldset class="o-fieldset u-mb">
        <div class="o-fieldset__row">

            <div class="switch">
                <input type="checkbox" class="switch" id="switch-hyrbil" [(ngModel)]="reportModel.isRental" (ngModelChange)="toggleRental()" />
                <label for="switch-hyrbil">Rapportera även för hyrbil</label>
            </div>

        </div>
        <div class="o-fieldset__row">
            <p>Välj månad att rapportera</p>
            <ng-container *ngFor="let item of availableMonths">

                <ng-template [ngIf]="item.isValid" [ngIfElse]="invalid">
                    <div class="p-3 mb-2 list-btn" [class.no-hover]="!item.isValid"
                        [class.active-list-btn]="item.selected" (click)="toggleSelected(item)">
                        <div class="d-flex justify-content-between no-flex-mobile">
                            <div>
                                <b class="me-2 show-cursor">{{ item.selected ? "Vald" : "Välj" }}</b>
                                <span>{{ item.month | date: 'yyyy-MM' }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #invalid>
                    <div class="p-3 mb-2 list-btn no-hover">
                        <div class="d-flex justify-content-between no-flex-mobile">
                            <div>
                                <span>{{ item.month | date: 'yyyy-MM' }}</span>
                            </div>
                            <div>
                                <span class="text-grey">{{ item.info }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </ng-container>
        </div>
    </fieldset>

    <div class="d-flex mt-4 mobile-btn-bar justify-content-end">
        <button type="button" class="c-btn report-nav-btn" (click)="submit()" [disabled]="!getSelectedMonth()">
            <span class="c-btn__text">Nästa</span>
        </button>
    </div>
</ng-template>

<ng-template #noAvailableMonths>
    <p>{{ errorMessage }}</p>
</ng-template>
