import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ExternalMileageReportType } from '@domain/models/external-mileage-report-type.enum';

@Pipe({
  name: 'externalmileagereporttype'
})
export class ExternalMileageReportTypePipe implements PipeTransform {

  constructor(private dom: DomSanitizer) { }
  transform(status: ExternalMileageReportType): any {
    switch (status) {
      case ExternalMileageReportType.MileageReport:
        return this.dom.bypassSecurityTrustHtml(`<i title="Kilometerrapport" class="fa c-icon c-icon--[car] c-icon--small"></i>`);
      case ExternalMileageReportType.MileageReportNoFuelBenefit:
        return this.dom.bypassSecurityTrustHtml(`<i title="Mätarställning" class="fa c-icon c-icon--[truck-right-bus-left] c-icon--small"></i>`);
      case ExternalMileageReportType.Unknown:        
      default:
        return this.dom.bypassSecurityTrustHtml(``);
    }
  }
}
