<div id="super-user-menu" [class.super-user-menu-wide]="hover" (mouseover)="hover=true" (mouseout)="hover=false">
  <div class="d-flex flex-column justify-content-between h-100">
    <ul>
      <ng-container *ngIf="!isImpersonating">
        <li *ngIf="isAuthorizedToImpersonate">
          <a routerLink="{{routeMetadata.SuperUser.children.Impersonate.data.routerLink}}" routerLinkActive="super-user-icon-active" class="super-user-icon"
            [class.super-user-icon-wide]="hover">
            <i class="c-icon--small fa c-icon c-icon--[user]"></i>
            <span class="super-user-icon-text" [hidden]="!hover">{{routeMetadata.SuperUser.children.Impersonate.data.title}}</span>
          </a>
        </li>

        <li *ngIf="isAuthorizedToAdminister">
          <div class="super-user-text-admin mt-4">
            <span class="super-user-icon-text text-grey" [hidden]="!hover">
              Administrera
            </span>
          </div>
        </li>

        <ng-container *ngFor="let parentRoute of routeMetadata.SuperUser.children | keyvalue:keepOrder; let parentIndex = index">
          <ng-container *ngIf="parentRoute.value.data && parentRoute.value.data.showInMenu">
            <ng-container *ngIf="parentRoute && parentRoute.value.data && parentRoute.value.data.showInMenu">
              <app-menu-item [hover]="hover" [routerLinkActive]="'super-user-icon-active'" [route]="parentRoute"></app-menu-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <li>
        <div class="super-user-text-admin mt-4">
          <span class="super-user-icon-text text-grey" [hidden]="!hover">
            Superuser
          </span>
        </div>
      </li>
      
      <li>
        <a (click)="logout()" class="super-user-icon" [class.super-user-icon-wide]="hover" style="border-radius: 25px;border: 1px solid rgb(0,70,102);">
          <i class="c-icon--small fa c-icon c-icon--[user]"></i>
          <span class="super-user-icon-text" [hidden]="!hover">Logga ut</span>
        </a>
      </li>
    </ul>
  </div>
</div>
