import { FleetWebUserInfo } from "./fleetweb-user-info.model";
import { UserOrganizationInfo } from "./user-organization-info.model";

export class FleetWebUserDetails extends FleetWebUserInfo {
  constructor(jsonObject: any = null) {
    super(jsonObject);   
    if(jsonObject == null){
      return;
    }

    this.userOrganizationInfo = jsonObject.userOrganizationInfo ? jsonObject.userOrganizationInfo.map(userOrganization => new UserOrganizationInfo(userOrganization)) : new Array<UserOrganizationInfo>();
    this.fleetContactEmailAddress = jsonObject.fleetContactEmailAddress;
    this.fleetContactMobileNumber = jsonObject.fleetContactMobileNumber;
    this.employeeId = jsonObject.employeeId;
  }

  userOrganizationInfo: Array<UserOrganizationInfo> = new Array<UserOrganizationInfo>();
  fleetContactEmailAddress: string;
  fleetContactMobileNumber: string;
  employeeId: string;

  get hasMobileNumber() : boolean{
    return this.fleetContactMobileNumber != null && this.fleetContactMobileNumber !== "";
  }
}

