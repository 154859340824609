  <ng-template [ngIf]="car">
    <div class="d-flex justify-content-between align-items-start">
      <div>
        <div class="d-flex justify-content-between align-items-start">
          <h3 class="u-mb-xsmall">{{ car.marketProduct.name }}</h3>
          <span *ngIf="showDriver && car.accountStatus !== 1" class="badge badge-warning ms-2">Avslutad</span>
          <span *ngIf="!showDriver && !car.isActiveDriver" class="badge badge-warning ms-2">Avslutad</span>
        </div>
        <h5>{{car.customerName}}</h5>
      </div>
      <div>
        <span class="badge badge-info">{{ car.license }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-start">
      <p class="u-mb-small">
        {{ car.make }} {{ car.model }}
      </p>

    </div>
    <hr class="mb-3">
    <ul class="biluppgifter">
      <li *ngIf="showDriver">
        <b>Förare: </b><span *ngIf="car.driverName; else noInfo">{{ car.driverName }}</span>
      </li>
      <li>
        <b>Tillverkningsår: </b><span *ngIf="car.productionYear; else noInfo">{{ car.productionYear }}</span>
      </li>
      <li>
        <b>Modellkod: </b> <span *ngIf="car.modelCode; else noInfo">{{ car.modelCode }}</span>
      </li>
      <li>
        <b>Avtalstid fr o m: </b><span *ngIf="car.accountStartDate; else noInfo">{{ car.accountStartDate | date:'yyyy-MM-dd' }}</span>
      </li>
      <li>
        <b>Avtalstid t o m: </b><span *ngIf="car.accountEndDate; else noInfo">{{ car.accountEndDate | date:'yyyy-MM-dd' }}</span>
      </li>
      <li>
        <b>Kontraktets status: </b><span>{{ car.accountStatusText }}</span>
      </li>
      <li>
        <b>Startdatum förare: </b><span *ngIf="car.startDate; else noInfo">{{ car.startDate | date:'yyyy-MM-dd' }}</span>
      </li>
      <li>
        <b>Slutdatum förare: </b><span *ngIf="car.endDate; else noInfo">{{ car.endDate | date:'yyyy-MM-dd' }}</span>
      </li>
      <li *ngIf="car.marketProduct.id !== 3">
        <b>Förmånsvärde / månad: </b> <span *ngIf="car.grossBenefitValue; else noInfo"> {{ car.grossBenefitValue | currency:'SEK':'symbol':'1.0-2' }}</span>
      </li>
      <li *ngIf="car.marketProduct.id !== 3">
        <b>Reducerat förmånsvärde: </b><span *ngIf="car.grossBenefitValue; else noInfo">{{ car.reducedBenefitValue ? 'Ja' : 'Nej' }}</span>
      </li>
      <li>
        <b>Avtalad årlig körsträcka: </b> <span *ngIf="car.yearlyAgreedMileage; else noInfo"> {{ car.yearlyAgreedMileage | kilometer }} </span>
      </li>
      <li>
        <b>Kostnadsställe: </b><span *ngIf="car.costCenterText; else noInfo">{{ car.costCenterText }}</span>
      </li>
      <li>
        <b>Kod 1: </b><span *ngIf="car.code1; else noInfo">{{ car.code1 }}</span>
      </li>
      <li>
        <b>Kod 2: </b><span *ngIf="car.code2; else noInfo">{{ car.code2 }}</span>
      </li>
      <li *ngIf="car.marketProduct.id === 2">
        <b>Drivmedelsförmån: </b><span>{{ hasMileageReporting() ? 'Ja' : 'Nej'  }}</span>
      </li>
      <li *ngIf="hasAllowSplit()">
        <b>Splitfaktura: </b><span>Ja</span>
      </li>
    </ul>

    <hr class="mt-3 mb-3">
    <div class="d-flex justify-content-between">
      <h3 class="u-mb-xsmall">Drivmedelskort</h3>
      <i aria-hidden="true" class="c-icon c-icon--[gas-card] ms-1"></i>
    </div>
    <hr class="mt-3 mb-3">
    <i *ngIf="!car.fuelCards || car.fuelCards.length === 0">
      Inga drivmedelskort är kopplade till detta fordon
    </i>

    <div *ngIf="car.fuelCards && car.fuelCards.length > 0">
      <h4 class="mb-3"><strong>Aktiva kort</strong></h4>

      <ul class="user-details">
        <li *ngFor="let fuelCard of car.fuelCards"> <b> {{ fuelCard.name }} </b> - {{ fuelCard.cardNumber }}</li>
      </ul>
      <hr class="mt-3 mb-3">
      <h4 class="mb-3"><strong>Spärra kort</strong></h4>
      <p>
        <strong>Ring Fleet Support</strong><br />
        Telefontid helgfria vardagar mellan klockan 08.30 - 16.30<br />
        <strong><a href="tel:+46812081220" style="font-size:1rem;" class="c-btn c-btn--link c-btn--small"><span class="c-btn__text">08 - 120 812 20</span></a></strong>
      </p>
      <p>
        Övrig tid, kontakta ditt drivmedelsbolag<br />
        <strong *ngIf="showAffiliatesLink"><a href="affiliates" style="font-size:1rem;" class="c-btn c-btn--link c-btn--small"><span class="c-btn__text">Hitta kontaktuppgifter här</span></a></strong>
      </p>
    </div>

    <hr *ngIf="car.marketProduct.id !== 3" class="mt-3 mb-3">
    <div *ngIf="car.marketProduct.id !== 3" class="c-extra-content mb-4"
         [class.is-closed]="!displayExtraContent">
      <div class="c-extra-content__trigger u-text-center" style="display: block;"
           (click)="toggleExtraContent()">
        <div class="c-extra-content__trigger-text">
          Visa förmånsvärdegrundande utrustning
        </div>
        <div class="c-extra-content__icon"></div>
      </div>
      <section class="c-extra-content__content pt-3 ps-3 pe-3">
        <h4>Förmånsvärdegrundande utrustning</h4>
        <i *ngIf="!car.extras || car.extras.length === 0">
          Kunde inte hitta någon förmånsvärdegrundande utrustning
        </i>
        <ul *ngIf="car.extras && car.extras.length > 0" class="extrautrustning">
          <li *ngFor="let item of car.extras">{{ item }}</li>
        </ul>
      </section>
    </div>
  </ng-template>

<ng-template #noInfo>
  <span> - </span>
</ng-template>
