import { YearMonthViewModel } from "../../../features/reports/year.month.view.model";

export class SearchExternalReportQuery {
    
    constructor(jsonObject: any = null) {
        this.pageSize = 20;
        this.page = 1;
        this.searchText = null;

        if(jsonObject == null){
            return;
        }

        this.pageSize = jsonObject.pageSize;
        this.page = jsonObject.page;    
        this.searchText = jsonObject.searchText;
        this.includeAccepted = jsonObject.includeAccepted;
        this.includeDenied = jsonObject.includeDenied;
        this.includeDeleted = jsonObject.includeDeleted;
        this.includePending = jsonObject.includePending;

        if(jsonObject.period != null)
        {
            const yearAndMonthViewModel = new YearMonthViewModel();
            yearAndMonthViewModel.year = jsonObject.period.year;
            yearAndMonthViewModel.month = jsonObject.period.month;
            this.period = yearAndMonthViewModel;
        }

        this.supplier = jsonObject.supplier;
        this.reportType = jsonObject.reportType;
    }

    pageSize: number;
    page: number;

    searchText: string;
    includeAccepted = false;
    includeDenied = true;
    includeDeleted = false;
    includePending = false;
    period: YearMonthViewModel;
    supplier?: string;
    reportType?: number;

    public get isFiltering(): boolean {
        return this.includeAccepted ||
          this.includeDeleted ||
          this.includePending ||
          this.period != null ||
          this.supplier != null ||
          this.reportType != null;
    }
    
    toQueryString() {
        var queryString = 
        "?pagesize=" + this.pageSize + 
        "&page=" + this.page;
        
        if(this.searchText)
        {
            queryString += "&searchText=" +  this.searchText;
        }

        if(this.includeAccepted)
        {
            queryString += "&includeAccepted=" +  this.includeAccepted;
        }
        
        if(this.includeDenied)
        {
            queryString += "&includeDenied=" +  this.includeDenied;
        }
        
        if(this.includeDeleted)
        {
            queryString += "&includeDeleted=" +  this.includeDeleted;
        }
        
        if(this.includePending)
        {
            queryString += "&includePending=" +  this.includePending;
        }

      if (this.period) {
            queryString += "&year=" + this.period.year + "&month=" + this.period.month;
        }

        if (this.supplier != null) {
            queryString += "&supplier=" + this.supplier;
        }

        if (this.reportType != null) {
            queryString += "&reportType=" + this.reportType;
        }
        return queryString;
    }
}
