import { DateHelper } from "@shared/helpers/date.helper";

export class PeriodFilter {
  fromDateString: string;
  toDateString: string;

  constructor(from: Date, to: Date) {
    this.fromDateString = DateHelper.toSwedishDateFormat(from);
    this.toDateString = DateHelper.toSwedishDateFormat(to);
  }
}
