export class FrameAgreement {

    constructor(jsonObj : any = null) {
        
        if(jsonObj == null)
        {
            return;
        }
        
        this.name = jsonObj.name;        
        this.id = jsonObj.id;        
        this.enabled = jsonObj.enabled;
        this.quotaAccountName = jsonObj.quotaAccountName;
        this.quotaAccountId = jsonObj.quotaAccountId;
    }

    name : string;
    id: number;
    enabled: boolean;
    quotaAccountName: string;
    quotaAccountId: number;
}