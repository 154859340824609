export class SuborderListItem {
    constructor(jsonObj : any) {
        this.caseId = jsonObj.caseId;
        this.suborderId = jsonObj.suborderId;
        this.carDescription = jsonObj.carDescription;
        this.contact = jsonObj.contact;
        this.status = jsonObj.status;
        this.signedBy = jsonObj.signedBy;
        if (jsonObj.signed)
            this.signed = new Date(jsonObj.signed);
    }

    caseId? : number;
    suborderId? : number;
    carDescription : string;
    contact : string;
    status: string;
    signedBy: string;
    signed?: Date;
}
