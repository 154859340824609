import { CarInfoModel } from "./car-info.model";
import { FuelCard } from './fuel-card.model';

export class CarDetailModel extends CarInfoModel {

    constructor(jsonObject: any) {
        super(jsonObject);

        this.yearlyAgreedMileage = jsonObject.yearlyAgreedMileage;
        this.grossBenefitValue = jsonObject.grossBenefitValue;
        this.reducedBenefitValue = jsonObject.reducedBenefitValue;
        this.extras = jsonObject.extras;
        this.costCenterText = jsonObject.costCenterText;
        this.allowSplit = jsonObject.allowSplit;
        this.code1 = jsonObject.code1;
        this.code2 = jsonObject.code2;
    }

    grossBenefitValue: number;
    reducedBenefitValue: boolean;
    extras: string[];
    yearlyAgreedMileage?: number;
    costCenterText: string;
    allowSplit: boolean;
    code1: string;
    code2: string;    
}
