import { EventEmitter } from "@angular/core";

export class LoadingHelper extends EventEmitter {

    loadingChanged: EventEmitter<boolean> = new EventEmitter();

    private loadingCounter: number = 0;

    get isLoading() {
        return this.loadingCounter > 0;
    }
    
    get current(){
        return this.loadingCounter;
    }
    
    startLoading() {        
        this.loadingCounter++;
        if(this.current === 1) {
            this.loadingChanged.emit(this.isLoading);
        }
    }
    
    stopLoading() {
        if(this.current === 0) {
            return;
        }

        this.loadingCounter--;
        
        if(this.current === 0) {
            this.loadingChanged.emit(this.isLoading);
        }
    }     
    
    reset() {
        if(this.current === 0){
            return;
        }
        
        this.loadingCounter = 0;
        this.loadingChanged.emit(this.isLoading);
    }
}
