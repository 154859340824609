import { InsuranceCompanyPart } from './insurance-company-part.model';

export class Branch{
    
    constructor(jsonObj : any = null) {
        if(jsonObj == null)
        {
            return;
        }
                
        this.id= jsonObj.id;
        this.name = jsonObj.name;
        this.number  = jsonObj.number;
        this.insuranceCompany = new InsuranceCompanyPart(jsonObj.insuranceCompany);
    }

    id : number;
    name : string;
    number  : string;
    insuranceCompany  : InsuranceCompanyPart;        
}
