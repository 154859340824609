<div class="c-modal">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__wrapper">
        <div class="c-modal__header">
            <button type="button" class="c-modal__close-btn close-modal" aria-label="Close"
                (click)="modal.close(false)"></button>
        </div>
        <div class="c-modal__content">
            <div *ngIf="errors && errors.length > 0">
                <header class="">
                    <h3 class="text-start">Valideringsfel</h3>
                </header>
                <ul>
                    <li *ngFor="let error of errors">
                        {{error}}
                    </li>
                </ul>
                <a class="c-btn c-btn--full c-btn--link c-btn--small" (click)="modal.close(true)">
                    <i class="c-btn__icon  c-icon  c-icon--[control-arrow-right]"></i>
                    <span class="c-btn__text">Klicka här för att göra om rapporten</span>
                </a>
            </div>

            <div *ngIf="warnings && warnings.length > 0">
                <header class="">
                    <h3 class="text-start">Varning</h3>
                </header>
                <p class="text-start" *ngFor="let warning of warnings">{{warning}}</p>
                <a class="c-btn c-btn--full c-btn--link c-btn--small" (click)="modal.close(true)">
                    <i class="c-btn__icon  c-icon  c-icon--[control-arrow-right]"></i>
                    <span class="c-btn__text">Ja, fortsätt till nästa steg</span>
                </a>
            </div>
        </div>
        <div class="c-modal__footer">
            <button type="button" class="c-btn close-btn-modal" aria-label="Close"
                (click)="modal.close(false)">Avbryt</button>
        </div>
    </div>
</div>