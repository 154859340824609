import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  imports: [CommonModule]
})
export class ConfirmDialogComponent {

  @Input() message: string;
  @Input() buttonText: string;
  @Input() cancelButtonText: string;
  @Input() title: string;

  constructor(public modal: NgbActiveModal) { }
}
