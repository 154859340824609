import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    currentUser: UserModel;
    displayMenu: boolean[];

    private _componentSubscriptions = new Array<Subscription>();

    constructor(private _authenticationService: AuthenticationService) {
        this.displayMenu = [false, false];
    }

    get userName(){
        return this.currentUser.displayName != "" ?
            this.currentUser.displayName :
            "Användare";
    }
   
    ngOnInit(): void {
        this._componentSubscriptions.push(this._authenticationService.currentUser$.subscribe(user => {
            this.currentUser = user;
        }));
    }

    ngOnDestroy(): void {
        this._componentSubscriptions.forEach(s => {
            s.unsubscribe();
        });
        this._componentSubscriptions.splice(0);  
    }

    toggleMenu($event: MouseEvent, i: number) {
        // To make sure that the menu's clickOutsite directive isn't run (which would result in the menu closing again)
        $event.stopPropagation();
        this.displayMenu.forEach((item, index) => { 
            if ((index) != i) {
                this.displayMenu[index] = false;
            } 
        });

        this.displayMenu[i] = !this.displayMenu[i];
    }

    hideMenus() {
        this.displayMenu.fill(false);
    }
}
