import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
    selector: "[appDynamicContainer]",
})
export class DynamicContainerDirective {

    constructor(public viewContainerRef: ViewContainerRef) {
    }
}
