export class Part {
    
    constructor(jsonObj : any = null) {
        if(jsonObj == null)
        {
            return;
        }
        
        this.partId = jsonObj.partId;
    }

    partId : number;
}
