import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-simple-filter',
    templateUrl: './simple-filter.component.html',
    styleUrls: ['./simple-filter.component.scss']
})
export class SimpleFilterComponent{

    @Output() valueChanged = new EventEmitter();

    searchString: string;
  
    filter(){
        this.valueChanged.emit(this.searchString);
    }
}
