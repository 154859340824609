import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DialogService } from '@core/services/dialog.service';
import { FrameAgreementDetails } from '@domain/models/frame-agreement-details.model';
import { Organization } from '@domain/models/organization.model';
import { SelectedCustomer } from '@domain/models/selected-customer.model';
import { FrameAgreementService } from '@domain/services/frame-agreement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '@shared/validators/custom-validators';
import { Subscription } from 'rxjs';
import { EditUserOrganizationCostcentersComponent } from '../edit-user-organization-costcenters/edit-user-organization-costcenters.component';

@Component({
  selector: 'app-customers-selector',
  templateUrl: './customers-selector.component.html',
  styleUrls: ['./customers-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomersSelectorComponent
    }
  ]
})
export class CustomersSelectorComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  touched = false;
  disabled = false;

  @Input() frameAgreementId: number;

  selectedCustomers: Array<SelectedCustomer> = new Array<SelectedCustomer>();
  frameAgreementDetails: FrameAgreementDetails;

  formGroup: FormGroup;
  filterFormControl: FormControl<string>;
  customersFormArray: FormArray<FormControl<boolean>>;

  private _customersFormArraySubscription: Subscription = null;
  private _componentSubscriptions = new Array<Subscription>();

  constructor(private _frameAgreementService: FrameAgreementService,
    private _dialogService: DialogService,
    private _modalService: NgbModal) {

  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromCustomerSelection();
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.frameAgreementId) {
      this.selectedCustomers = new Array<SelectedCustomer>();
      this.onChange(this.selectedCustomers);
      this.getFrameAgreementDetails();
    }
  }

  private initForm() {
    this.filterFormControl = new FormControl<string>("");

    this._componentSubscriptions.push(this.filterFormControl.valueChanges.subscribe(() => {
      this.patchForm();
    }));

    this.customersFormArray = new FormArray<FormControl<boolean>>([], { validators: [CustomValidators.atLeastOneSelectedCheckbox] });
    this.formGroup = new FormGroup({
      customers: this.customersFormArray,
      filter: this.filterFormControl
    });
  }

  writeValue(obj: any): void {    
    if (obj instanceof (Array)) {     
      this.selectedCustomers = obj as Array<SelectedCustomer>;
    }
    else {
      this.selectedCustomers = new Array<SelectedCustomer>();
    }
  }

  // Parent callback
  onChange = (frameAgreement) => { };
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  // Parent callback
  onTouched = () => { };
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  get showFilter(): boolean{
    return this.frameAgreementDetails != null && this.frameAgreementDetails.organizations.length > 10;
  }

  get showSelectAll(): boolean {
    return this.frameAgreementDetails != null && this.filteredOrganizations.length > 1;
  }

  get selectDisabled(): boolean {
    return this.showSelectAll && 
    this.filteredOrganizations.every(o => this.selectedCustomers.find(s => s.customerId === o.customerId) != null);
  }

  get deselectDisabled(): boolean {
    return this.showSelectAll && 
    this.filteredOrganizations.every(o => this.selectedCustomers.every(s => s.customerId !== o.customerId));
  }

  get filteredOrganizations(): Array<Organization> {
    if(this.filterFormControl.value){
      var searchString = this.filterFormControl.value;
      return this.frameAgreementDetails.organizations.filter(organization => {
        var isSelected = this.selectedCustomers.filter(selectedCustomer => selectedCustomer.customerId === organization.customerId).length > 0;
        return isSelected || organization.name.toLowerCase().search(searchString.toLowerCase()) !== -1;
      });
    }
    return this.frameAgreementDetails.organizations;
  }

  get isFiltering(): boolean{
    return this.filterFormControl.value != null && this.filterFormControl.value !== "";
  }

  onClearFilter(){
    this.filterFormControl.setValue(null);
  }
   
  private patchForm() {
    this.unsubscribeFromCustomerSelection();

    this.customersFormArray.clear();
    this.filteredOrganizations.forEach(organization => {
      var isSelected = this.selectedCustomers.filter(selectedCustomer => selectedCustomer.customerId === organization.customerId).length > 0;
      this.customersFormArray.push(new FormControl<boolean>(isSelected));
    });

    this.subscribeToCustomerSelection();
  }

  private subscribeToCustomerSelection() {
    this._customersFormArraySubscription = this.customersFormArray.valueChanges.subscribe(() => {
      this.markAsTouched();
      this.customersFormArray.controls.forEach((control, index) => {
        var organizationCustomerId = this.filteredOrganizations[index].customerId;
        var selectedCustomerIndex = this.selectedCustomers.findIndex(selectedCustomer => selectedCustomer.customerId === organizationCustomerId);

        if (control.value && selectedCustomerIndex === -1) {
          var selectedCustomer = new SelectedCustomer();
          selectedCustomer.customerId = organizationCustomerId;
          this.selectedCustomers.push(selectedCustomer);
        }
        else if (!control.value && selectedCustomerIndex !== -1) {
          this.selectedCustomers.splice(selectedCustomerIndex, 1);
        }
      });

      this.onChange(this.selectedCustomers);
    });
  }

  private unsubscribeFromCustomerSelection() {
    if (this._customersFormArraySubscription) {
      this._customersFormArraySubscription.unsubscribe();
      this._customersFormArraySubscription = null;
    }
  }

  onSelectAllCustomers() {
    this.customersFormArray.controls.forEach(control => {
      if (!control.value) {        
        control.setValue(true);
      }
    });
  }

  onDeselectAllCustomers() {
    this.selectedCustomers.splice(0, this.selectedCustomers.length);
    this.onChange(this.selectedCustomers);
    this.patchForm();
  }

  onEditCostCenters(organizationIndex: number) {
    this.markAsTouched();

    var organization = this.filteredOrganizations[organizationIndex];

    var modalRef = this._modalService.open(
      EditUserOrganizationCostcentersComponent,
      {
        backdrop: "static",
        //size: "sm", 
        animation: false,
        scrollable: true
      }
    );

    var selectedCustomer = this.selectedCustomers.find(c => c.customerId === organization.customerId);

    modalRef.componentInstance.initialCostCenterIds = selectedCustomer.selectedCostcenterIds;
    modalRef.componentInstance.organization = organization;
    modalRef.componentInstance.hasErroneousCostCenters = this.hasInvalidCostCenters(organizationIndex);

    this._componentSubscriptions.push(modalRef.componentInstance.close$.subscribe(result => {
      if (result != null) {
        // Filter out any removed cost centers before saving
        result = result.filter(selectedCostCenter => {          
          return this.invalidCostCenters(organizationIndex).find(invalid => invalid === selectedCostCenter) == null; 
        });

        selectedCustomer.selectedCostcenterIds = result;
        this.onChange(this.selectedCustomers);
        this.customersFormArray.markAsDirty();      
      }
    }));
  }

  getCostCenterLimitationCountText(organizationIndex: number) {
    var organization = this.filteredOrganizations[organizationIndex];
    var selectedCustomer = this.selectedCustomers.find(c => c.customerId === organization.customerId);

    var selectedCostCentersCount = selectedCustomer.selectedCostcenterIds.length;
    var invalidSelectedCostCentersCount = this.invalidCostCenters(organizationIndex).length;

    return selectedCostCentersCount === 0 ?
      `Alla av ${organization.costCenters.length} kostnadsställen` :
      `${selectedCostCentersCount - invalidSelectedCostCentersCount} av ${organization.costCenters.length} kostnadsställen${this.hasInvalidCostCenters(organizationIndex) ? " (!)": ""}`;
  }

  hasInvalidCostCenters(organizationIndex: number) {
    return this.invalidCostCenters(organizationIndex).length > 0;
  }

  invalidCostCenters(organizationIndex)  {
    var organization = this.filteredOrganizations[organizationIndex];
    var selectedCustomer = this.selectedCustomers.find(c => c.customerId === organization.customerId);

    return selectedCustomer.selectedCostcenterIds.filter(selectedCostCenterId => {
      return organization.costCenters.find(organizationCostCenter => {
          return organizationCostCenter.v21Sequence === selectedCostCenterId;
        }) ==
        null;
    });
  }

  getFrameAgreementDetails() {
    this._frameAgreementService.getFrameAgreementDetails(this.frameAgreementId).subscribe({
      next: data => {
        this.frameAgreementDetails = data;
        this.patchForm();
      },
      error: (error) => {
        this._dialogService.showError(error, "Hämta ramavtal", true);
      },
      complete: () => {
      }
    });
  }
}
