import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { QueryResult } from '@domain/models/query-result.model';
import { SearchFleetWebUserQuery } from '@domain/models/filters/search-fleetweb-user-query.model';
import { FleetWebUserInfo } from '@domain/models/fleetweb-user-info.model';
import { FleetSecurityResponse } from '@domain/models/fleet-security-response.model';
import { FleetWebUserDetails } from '@domain/models/fleetweb-user-details.model';
import { CreateFleetWebUserRequest } from '@domain/models/create-fleetweb-user-request.model';
import { UpdateFleetWebUserRequest } from '@domain/models/update-fleetweb-user-request.model';
import { FleetWebUserQuery } from '@domain/models/fleetweb-user-query.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    
  constructor(private _apiService: BaseApiService) { }

  search(searchQuery: SearchFleetWebUserQuery) : Observable<QueryResult<FleetWebUserInfo>> {
    return this._apiService.get(`User/Search${searchQuery.toQueryString()}`).pipe(
      map(data => {
        return new QueryResult<FleetWebUserInfo>(data, FleetWebUserInfo);
      })
    );
  }

  get(query: FleetWebUserQuery) : Observable<FleetWebUserDetails> {
    return this._apiService.get(`User${query.toQueryString()}`).pipe(map(data => {
      return new FleetWebUserDetails(data);
    }));
  }

  create(createFleetWebUserRequest: CreateFleetWebUserRequest) : Observable<FleetSecurityResponse> {
    return this._apiService.post('User', createFleetWebUserRequest).pipe(
      map(data => {
        return new FleetSecurityResponse(data);
      })
    );
  }

  update(updateInternalUserRequest: UpdateFleetWebUserRequest) : Observable<any> {
    return this._apiService.put('User', updateInternalUserRequest);
  }

  unlock(userId: string) : Observable<any> {
    return this._apiService.put(`User/${userId}/Unlock`, null);
  }

  resetPassword(userId: string) : Observable<FleetSecurityResponse>{
    return this._apiService.put(`User/${userId}/ResetPassword`, null).pipe(
      map(data => {
        return new FleetSecurityResponse(data);
      })
    );
  }

  deleteUser(userId: string) : Observable<any> {
    return this._apiService.delete(`User/${userId}`);
  }
}
