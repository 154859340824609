export abstract class ReportingOverviewItem{
    constructor(jsonObject: any = null) {
       
        if(jsonObject == null)
        {
            return;
        }

        this.year = jsonObject.year;
        this.month = jsonObject.month;    
        this.yearAndMonth = new Date(jsonObject.yearAndMonth);

        this.isExternalReport = jsonObject.isExternalReport;    
        this.reported = jsonObject.reported ? new Date(jsonObject.reported) : null;                
        this.info  = jsonObject.info;
    }


    year: number;
    month: number;
    yearAndMonth: Date;

    isExternalReport : boolean;
    reported : Date;
    info : string;
}