import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { HeroService } from "@domain/services/hero.service";
import { WizardStep } from "@shared/components/wizard/wizard-step.model";
import { HeroModel } from "@domain/models/hero.model";
import { DynamicContainerDirective } from "@shared/directives/dynamic-container.directive";
import { WizardStepContentComponent } from "@shared/components/wizard/wizard-step-content/wizard-step-content.component";
import { LoadingHelper } from "@shared/helpers/loading.helper";

@Component({
  template: ''
})
export class WizardComponent implements AfterViewInit, OnDestroy {

  @ViewChild(DynamicContainerDirective)
  dynamicContainer: DynamicContainerDirective;

  steps: WizardStep[];
  currentStep: WizardStep;
  currentComponent: WizardStepContentComponent;

  private _title: string;
  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  heroModel: HeroModel;
  protected _stepLoadingHelper = new LoadingHelper();
  errorMessage: string;

  constructor(    
    public _heroService: HeroService) { }

  ngAfterViewInit() {
    this.initHero();
    this.loadComponent();
  }

  ngOnDestroy() {
    const viewContainerRef = this.dynamicContainer.viewContainerRef;
    viewContainerRef.clear();
  }

  get stepLoading () {
    return this._stepLoadingHelper.isLoading;
  }

  initHero() {
    this.heroModel = new HeroModel();
    this.heroModel.title = this.title;
    this._heroService.set(this.heroModel);
    this.setHeroSubtitle();
    this.setHeroButton();
  }

  setHeroTitle(title: string) {
    this._heroService.setTitle(title);
  }

  setHeroSubtitle() {
    if (this.currentStep) {
      this._heroService.setSubtitle(`Steg ${this.getStepIndex(this.currentStep) + 1}/${this.steps.length}`);
    }
  }

  setHeroButton() {
    this._heroService.setButton("");
  }

  getStepIndex(step: WizardStep) {
    return this.steps.findIndex((value: WizardStep, index: number, obj: WizardStep[]): any => { return value.id === step.id });
  }

  getStepById(stepId: string) {
    return this.steps.find(x => x.id === stepId);
  }

  //TODO: Här borde vi väl egentligen se till att köra unsubscribe på alla event subscriptions?
  protected loadComponent() {

    if (this.steps && this.currentStep && this.dynamicContainer) {

      const viewContainerRef = this.dynamicContainer.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(this.currentStep.component);

      (<WizardStepContentComponent>componentRef.instance).Wizard = this;

      (<WizardStepContentComponent>componentRef.instance).resetWizardEvent.subscribe(event => {
        if (event) {
          this.reset();
        }
      });

      (<WizardStepContentComponent>componentRef.instance).submitEvent.subscribe(event => {
        if (event) {
          this.nextStep();
        }
      });

      (<WizardStepContentComponent>componentRef.instance).previousStepEvent.subscribe(event => {
        if (event) {
          this.previousStep();
        }
      });

      (<WizardStepContentComponent>componentRef.instance).loadingEvent.subscribe(event => {
        if (event) {
          this._stepLoadingHelper.startLoading();
        }
        else {
          this._stepLoadingHelper.stopLoading();
        }
      });
      
      (<WizardStepContentComponent>componentRef.instance).finishEvent.subscribe(event => {
        if (event) {
          this.finish();
        }
      });

      this.currentComponent = componentRef.instance;

      componentRef.changeDetectorRef.detectChanges();
    }
  }

  reset() {
    const nextIndex = 0;
    const nextStepId = this.steps[nextIndex].id;
    this.goToStep(nextStepId);
  }

  nextStep() {
    const nextIndex = this.getStepIndex(this.currentStep) + 1;
    const nextStepId = this.steps[nextIndex].id;
    this.goToStep(nextStepId);
  }

  previousStep() {
    const previousIndex = this.getStepIndex(this.currentStep) - 1;
    const previousStepId = this.steps[previousIndex].id;
    this.goToStep(previousStepId);
  }

  finish() {
    const lastIndex = this.steps.length - 1;
    const lastStepId = this.steps[lastIndex].id;
    this.goToStep(lastStepId);
  }

  goToStep(stepId: string) {
    const step = this.getStepById(stepId);
    this.currentStep = step;
    this.setHeroSubtitle();
    this.setHeroButton();
    this.loadComponent();
  }

  isFirstStep(): boolean {
    return this.getStepIndex(this.currentStep) === 0;
  }

  isLastStep(): boolean {
    return this.getStepIndex(this.currentStep) === this.steps.length - 1;
  }
}
