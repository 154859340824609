export class SearchInternalUserQuery {
    constructor(jsonObject: any = null) {
      this.pageSize = 20;
      this.page = 1;
  
      if (jsonObject == null) {
        return;
      }
  
      this.pageSize = jsonObject.pageSize;
      this.page = jsonObject.page;
  
      this.email = jsonObject.email;      
    }
  
    pageSize: number;
    page: number;
  
    email: string;
    
    toQueryString() {
      var queryString =
        "?pagesize=" + this.pageSize +
        "&page=" + this.page;
  
      if (this.email) {
        queryString += "&email=" + this.email;
      }
    
      return queryString;
    }
  }
  