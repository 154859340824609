import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Permission } from '@core/models/permissions.enum';
import { UserModel } from '@core/models/user.model';
import { AuthenticationService } from "@core/services/authentication.service";
import { DialogService } from '@core/services/dialog.service';
import { CarAndDriverQuery } from '@domain/models/filters/car-and-driver-query-model';
import { CarService } from '@domain/services/car.service';
import { NotificationService } from '@domain/services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit, OnDestroy {
    @Input() route: any;
    @Input() routerLinkActive: string;
    @Input() hover: string;

    @Output() hideMenuInParentComponent = new EventEmitter<any>();

    private _componentSubscriptions = new Array<Subscription>();

    hasValidRoute: boolean = false;
    currentUser: UserModel;
    badgeCount: number;
    
    constructor(
      private _authenticationService: AuthenticationService,
      private _notificationService: NotificationService,
      private _carInfoService: CarService,
      private _dialogService: DialogService) {
    }

    ngOnDestroy(): void {
      this._componentSubscriptions.forEach(s => {
          s.unsubscribe();
      });
      this._componentSubscriptions.splice(0);  
    }

    ngOnInit() {
      this.checkValidRoute();

      if(this.route.value.data?.badgeCounter && this.route.value.data.badgeCounter != '') {
        this.getBadgeCount(this.route.value.data.badgeCounter);
      }
    }

    closeMenu() {
      this.hideMenuInParentComponent.emit();
    }

    getBadgeCount(badgeCounter: string) {
      this._componentSubscriptions.push(this._authenticationService.currentUser$.subscribe(user => {

        this.currentUser = user;

        if(badgeCounter === "carCount" && this.hasReadCarCountPermission){
            this.getNumberOfCars();
        }

        if(badgeCounter === "notificationCount" && this.hasReadNotificationsPermission){
          this.getNotificationCount();
        }
      }));
    }

    private getNotificationCount() {
      this._componentSubscriptions.push(this._notificationService.count$.subscribe(count => {
        this.badgeCount = count;
      }));
    }
  
    private getNumberOfCars() {
      this.badgeCount = 0;
      let query = new CarAndDriverQuery();
  
      this._carInfoService.getNumberOfCars(query).subscribe({
        next: data => {
          this.badgeCount = data;
        },
        error: error => {
          this._dialogService.showError(error, "Hämta antal bilar", true);
        },
        complete: () => {
  
        }
      });
    }
    
    checkValidRoute() {
      if((this.route 
        && this.route.value.data 
        && this.route.value.data.permissions 
        && this.route.value.data.showInMenu 
        && this.route.value.data.routerLink 
        && this.hasPermission(this.route.value.data.permissions))
        || (this.route 
          && this.route.value.data 
          && !this.route.value.data.permissions 
          && this.route.value.data.showInMenu)) {
        this.hasValidRoute = true;
      }
      else {
        this.hasValidRoute = false;
      }
    }

    hasPermission(permissions: string[]): boolean{
       for(const permission of permissions) {
          if(this._authenticationService.hasPermissionStringInput(permission)) {
          return true;
          }
      }
      return false;
    }      
    
    get hasReadCarCountPermission() :boolean {
      return this.hasPermission([Permission.LäsaEgnaFordon]);
    }
  
    get hasReadNotificationsPermission() :boolean {
      return this.hasPermission([Permission.LäsaPåminnelser]);
    }
}
