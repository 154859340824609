import { IValidation } from './validation.interface';

export class MessageValidation implements IValidation{

    Message: Array<string>;
    
    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.Message = jsonObj.Message;
        }
    }

    get isValid(){
        return this.errors.length === 0; 
    }

    get errors(){
        var errors = new Array<string>();  
        
        if(this.Message)
        {
            this.Message.forEach(mess => {
                errors.push(mess);
            });
        }
        
        return errors;
    }
}
