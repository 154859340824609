import { AgreementFuelCard } from "./agreement-fuel-card.model";
import { CarInfoModel } from "./car-info.model";

export class CompanyCarDetail extends CarInfoModel {

    constructor(jsonObject?) {
      super(jsonObject);
  
      if (jsonObject == null) {
        return;
      }
  
      this.code1 = jsonObject.code1;
      this.code2 = jsonObject.code2;  
      this.costCenterId = jsonObject.costCenterId;
      this.costCenterText = jsonObject.costCenterText?.trimEnd();
      this.partId = jsonObject.partId;
  
      if (jsonObject.agreementFuelCardFormats) {
        this.agreementFuelCardFormats = new Array<AgreementFuelCard>();
        jsonObject.agreementFuelCardFormats.forEach(agreementFuelCard => {
          this.agreementFuelCardFormats.push(new AgreementFuelCard(agreementFuelCard));
        });
      }
  
      if (jsonObject.agreementTypeNames) {
        this.agreementTypeNames = new Array<string>();
        jsonObject.agreementTypeNames.forEach(agreementTypeName => {
          this.agreementTypeNames.push(agreementTypeName);
        });
      }
    }
  
    agreementFuelCardFormats: AgreementFuelCard[];
    agreementTypeNames: string[];
    code1:string;
    code2: string;  
    costCenterId: number | null;
    costCenterText: string;
    grossBenefitValue: number | null;
    reducedBenefitValue: boolean;
    extras: string[];
    // modelCode: string; // TODO Ta bort från CarInfoModel
    startMileage: number | null;
    endMileage: number | null;
    yearlyAgreedMileage: number | null;
    accountResidualValue: number;
    partId: number;
  }
