import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { CustomerPart } from '@domain/models/customer-part.model';
import { CustomerQuery } from '@domain/models/filters/customer-query.model';
import { InvoiceAddress } from '@domain/models/invoice-address.model';
import { Branch } from '@domain/models/branch.model';
import { SearchCustomerQuery } from '@domain/models/filters/search-customer-query.model';
import { QueryResult } from '@domain/models/query-result.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
  
  constructor(
    private _apiService: BaseApiService) { }

  getCustomers(): Observable<Array<CustomerPart>> {       
    return this._apiService.get("Customer").pipe(
      map(data => {
          var result = data.map(c => { return new CustomerPart(c); });
          return result;
      })
    );
  }

  searchCustomers(searchQuery: SearchCustomerQuery) : Observable<QueryResult<CustomerPart>> {
    return this._apiService.get(`Customer/SearchCustomers/${ searchQuery.toQueryString() }`).pipe(
      map(data => {
        let result = new QueryResult<CustomerPart>(data, CustomerPart);        
        return result;
      })
    );
  }
 
  getInvoiceAddresses(customerQuery: CustomerQuery): Observable<Array<InvoiceAddress>> {       
    return this._apiService.get(`Customer/${customerQuery.customerPartPVSeq}/InvoiceAddresses`).pipe(
      map(data => {
          var result = data.map(i => { return new InvoiceAddress(i); });
          return result;
      })
    );
  } 
    
  getBranches(customerQuery: CustomerQuery): Observable<Array<Branch>> {       
    return this._apiService.get(`Customer/${customerQuery.customerPartPVSeq}/Branches/${customerQuery.toQueryString()}`).pipe(
      map(data => {
        var result = data.map(b => { return new Branch(b); });
        return result;
      })
    );
  }
}
