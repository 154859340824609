import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/services/base-api.service';
import { Observable, map } from 'rxjs';
import { News } from '@domain/models/news.model';

@Injectable({
  providedIn: 'root'
})

export class NewsService {

  constructor(private _apiService: BaseApiService) { }

  getSingleNews(id: number): Observable<News> {
    return this._apiService.get("News/" + id).pipe(
      map(data => {
        const news = new News(data);
        return news;
      })
    );
  }

  getNews(): Observable<Array<News>> {
    return this._apiService.get("News").pipe(
      map(data => {
        const news = data.map(a => { return new News(a); });
        return news;
      })
    );
  }

  getFrontPageNews(): Observable<Array<News>> {
    return this._apiService.get("News/FrontPageNews").pipe(
      map(data => {
        const news = data.map(a => { return new News(a); });
        return news;
      })
    );
  }

  updateNewsArticle(news: News): Observable<any> {
    return this._apiService.put("News", news);
  }

  createNewsArticle(news: News): Observable<any> {
    return this._apiService.post("News", news);
  }

  deleteNewsArticle(id: number): Observable<any> {
    return this._apiService.delete("News/" + id);
  }
}
