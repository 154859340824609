<footer class="c-page-foot">
  <div class="o-page-wrap">
    <div class="c-page-foot__top-container">
      <nav>
        <ul class="c-breadcrumb">
          <li>
            <a routerLink="/">
              <i aria-hidden="true" class="c-icon c-icon--[house]"></i>
            </a>
          </li>
          <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
            <ng-container *ngIf="!last; else lastItem">
              <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.label }}</a>
            </ng-container>
            <ng-template #lastItem>
              {{ breadcrumb.label }}
            </ng-template>
          </li>
        </ul>
      </nav>
    </div>
    <div class="c-page-foot__meta-nav o-split o-split--collapse@s o-split--top">
      <nav class="u-mb@s">
        <ul>
          <li *ngIf="loggedIn"><a routerLink="/assistance">Assistans</a></li>
          <li *ngIf="loggedIn"><a routerLink="/faq">Vanliga frågor</a></li>
          <li *ngIf="loggedIn"><a routerLink="/support">Support</a></li>
          <li *ngIf="loggedIn"><a routerLink="/contact-information">Kontakt</a></li>
          <li><a href="https://www.vwfs.se/gdpr/" target="_blank">GDPR</a></li>
          <li><a href="https://www.vwfs.se/cookies/" target="_blank">Cookies</a></li>
        </ul>
      </nav>
      <div class="u-text-white u-mb u-ws-nowrap"> <span>&copy; VOLKSWAGEN FINANCIAL SERVICES {{ currentYearValue }} </span>
      </div>
    </div>
    <p class="u-mb-large u-text-white u-text-fs-2">
      Under det gemensamma varumärket Volkswagen Financial Services erbjuds serviceavtal genom Volkswagen Service
      Sverige AB, org nr 556944-2253 och bilfinansiering genom Volkswagen Finans Sverige AB (publ) org nr
      556258-8904, helägt dotterbolag inom Volkswagen Group. Volkswagen Försäkring är en del av If Skadeförsäkring
      AB (publ). Säte: Stockholm, Org.nr: 516401-8102. Volkswagen Försäkring är ett samarbete med Volkswagen Finans Sverige AB.
    </p>
  </div>
  <button  *ngIf="showScrollButton" (click)="scrollToTop()" class="btn-scrollToTop c-btn" title="Tillbaka till toppen">↑</button>
</footer>
