import { UserOrganizationInfo } from "./user-organization-info.model";

export class CreateMembershipUserRequest {
    userName : string;
    roles: Array<string>;
}

export class CreateFleetWebUserRequest extends CreateMembershipUserRequest {  
    customerId: number;
    frameAgreementId : number;
    userOrganizationInfo: Array<UserOrganizationInfo> = new Array<UserOrganizationInfo>();
}
