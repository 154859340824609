import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-reports-validation-message',
    templateUrl: './reports-validation-message.component.html',
    styleUrls: ['./reports-validation-message.component.scss']
})
export class ReportsValidationMessageComponent {

    @Input() errors: string[];
    @Input() warnings: string[];

    constructor(public modal: NgbActiveModal) { }
}