export class FleetSecurityResponse {
    constructor(jsonObject : any){
        if (jsonObject == null) {
            return;
          }
      
          this.success = jsonObject.success;
          this.message = jsonObject.message;          
          this.userId = jsonObject.userId;
    }
      
    success : boolean;
    message : string;
    userId: string;
}
