export class SearchFleetWebUserQuery {
  constructor(jsonObject: any = null) {
    this.pageSize = 20;
    this.page = 1;
    this.existingUsersOnly = true;
    this.includeDisabledFrameAgreements = false;

    if (jsonObject == null) {
      return;
    }

    this.pageSize = jsonObject.pageSize;
    this.page = jsonObject.page;

    this.frameAgreementId = jsonObject.frameAgreementId;
    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.socialSecurityNumber = jsonObject.socialSecurityNumber;
    this.email = jsonObject.email;
    this.existingUsersOnly = jsonObject.existingUsersOnly;
    this.includeDisabledFrameAgreements = jsonObject.includeDisabledFrameAgreements;
    this.frameAgreementName = jsonObject.frameAgreementName;
    this.frameAgreementQuotaAccountName = jsonObject.frameAgreementQuotaAccountName;
  }

  pageSize: number;
  page: number;

  frameAgreementId: number;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  email: string;
  existingUsersOnly: boolean;


  //Only used for filter persistance:
  includeDisabledFrameAgreements: boolean;
  frameAgreementName: string;
  frameAgreementQuotaAccountName: string;

  toQueryString() {
    var queryString =
      "?pagesize=" + this.pageSize +
      "&page=" + this.page;

    if (this.frameAgreementId) {
      queryString += "&frameAgreementId=" + this.frameAgreementId;
    }

    if (this.firstName) {
      queryString += "&firstName=" + this.firstName;
    }

    if (this.lastName) {
      queryString += "&lastName=" + this.lastName;
    }

    if (this.socialSecurityNumber) {
      queryString += "&socialSecurityNumber=" + this.socialSecurityNumber;
    }

    if (this.email) {
      queryString += "&email=" + this.email;
    }

    if (this.existingUsersOnly) {
      queryString += "&existingUsersOnly=" + this.existingUsersOnly;
    }

    return queryString;
  }
}
