export class Dealer {

    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
            return;
        }

        this.partId = jsonObj.partId;
        this.name = jsonObj.name;
        this.orgNumber = jsonObj.orgNumber;
    }

    partId: number;
    name: string;
    orgNumber: string;
}

