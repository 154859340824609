<div>
    <b class="me-2">{{car.marketProduct.name}}</b>
    <span class="badge badge-info badge-small me-2">{{car.license}}</span>
    <span>{{car.make}} {{car.model}}</span>
    <span *ngIf="!car.isActiveDriver" class="badge badge-warning ms-2">Avslutad</span>
</div>
<div>
    <a [routerLink]="['/cars/information', car.license, car.accountNumber]" class="c-btn c-btn--link link-min-area">
        <span class="c-btn__text">Bilinformation</span>
    </a>
    <ng-container *ngIf="canReportMileage || canReportToll">
        <span class="link-separator"></span>
        <a [routerLink]="['/cars/history', car.license]" class="c-btn c-btn--link link-min-area link-separator-right">
            <span class="c-btn__text">Rapporteringshistorik</span>
        </a>
    </ng-container>
    <span *ngIf="canReportMileageNoFuelBenefit || canReportMileage" class="link-separator"></span>
    <a *ngIf="canReportMileageNoFuelBenefit" [routerLink]="['/cars/mileage/nfb', car.license, car.accountNumber]" class="c-btn c-btn--link link-min-area link-separator-right">
        <span class="c-btn__text">Rapportera mätarställning</span>
    </a>
    <a *ngIf="canReportMileage" [routerLink]="['/cars/mileage', car.license, car.accountNumber]" class="c-btn c-btn--link link-min-area link-separator-right">
        <span class="c-btn__text">Rapportera km</span>
    </a>

    <ng-container *ngIf="canReportToll">
        <span *ngIf="canReportMileage" class="link-separator"></span>
        <a [routerLink]="['/cars/toll', car.license, car.accountNumber]" class="c-btn c-btn--link link-min-area link-separator-right">
            <span class="c-btn__text">Rapportera trängselskatt</span>
        </a>
    </ng-container>
</div>
