import { Part } from './part.model';

export class InsuranceCompanyPart extends Part{
    
    constructor(jsonObj : any = null) {
        super(jsonObj);

        if(jsonObj == null)
        {
            return;
        }
        
        this.name = jsonObj.name;        
    }

    name : string;
}