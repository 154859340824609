export class HeroModel {
  bgSize: string;
  imageUrl: string;
  pretitle: string;
  title: string;
  subtitle: string;

  buttonText: string;
  buttonUrl: string;

  secondButtonText: string;
  secondButtonUrl: string;

  thirdButtonText: string;
  thirdButtonUrl: string;
}
