import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { SearchFrameAgreementQuery } from '@domain/models/filters/search-frame-agreement-query.model';
import { FrameAgreementDetails } from '@domain/models/frame-agreement-details.model';
import { FrameAgreement } from '@domain/models/frame-agreement.model';
import { QueryResult } from '@domain/models/query-result.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FrameAgreementService {

  constructor(private _apiService: BaseApiService) { }
  
  searchFrameAgreements(searchQuery: SearchFrameAgreementQuery): Observable<QueryResult<FrameAgreement>> {
    return this._apiService.get(`FrameAgreement/Search${searchQuery.toQueryString()}`).pipe(
      map(data => {
        let result = new QueryResult<FrameAgreement>(data, FrameAgreement);
        return result;
      })
    );
  }

  getFrameAgreementDetails(frameAgreementId: number): Observable<FrameAgreementDetails> {
    return this._apiService.get(`FrameAgreement/${frameAgreementId}`).pipe(
      map(data => {
        let result = new FrameAgreementDetails(data);
        return result;
      })
    );
  }
}
