export class AddonsValidation {

  private addons: Array<string>;

  constructor(jsonObj: any = null) {
    if (jsonObj != null) {
      this.addons = jsonObj.Addons;
    }
  }

  get isValid() {
    return this.errors.length === 0;
  }

  get errors() {
    var errors = new Array<string>();

    if (this.addons) {
      this.addons.forEach(message => {
        errors.push(message);
      });
    }

    return errors;
  }
}
