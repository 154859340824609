export class ServiceLevel {
    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
            return;
        }

        this.sequence = jsonObj.sequence;
        this.code = jsonObj.code;
        this.name = jsonObj.name;       
    }

    sequence: number;
    code: number;
    name: string;
}
