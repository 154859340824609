import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserModel } from '../../../../core/models/user.model';
import { LoggingService } from '@core/services/logging.service';
import { Subscription } from 'rxjs';
import { DialogService } from '@core/services/dialog.service';
import { RouteMetadata } from '@shared/route-metadata';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit, OnDestroy {

  @Output() hideMenu = new EventEmitter<any>();
  @Input() active: boolean;

  private _componentSubscriptions = new Array<Subscription>();

  currentUser: UserModel;
  routeMetadata = RouteMetadata;

  constructor(private _router: Router,
    private _authenticationService: AuthenticationService,
    private _loggingService: LoggingService,
    private _dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this._componentSubscriptions.push(this._authenticationService.currentUser$.subscribe(user => {
      this.currentUser = user;
    }));
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
        s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);  
  }

  keepOrder = (a, b) => {
    return a;
  }
  
  logout() {   
    this._authenticationService.logout().subscribe({
      next: () => {
        this._router.navigate(["/login"]); 
      },
      error: (error) => {
        this._dialogService.showError(error, "Logga ut");
      },
      complete: () => {

      }
    });
  }

  closeMenu() {
    this.hideMenu.emit();
  }
}
