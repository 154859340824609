import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentType } from '@domain/models/content-type.model';

@Pipe({
  name: 'contenttypeicon'
})
export class ContentTypeIconPipe implements PipeTransform {

  constructor(private dom: DomSanitizer) { }
  transform(contentType: string, iconSize: string = null): any {

    var match = ContentType.KnownTypes.find(kt => kt.mimeType === contentType);

    var size = iconSize ?? 'c-icon--xsmall';
    if(match != null){
        return this.dom.bypassSecurityTrustHtml(`<i class="c-icon c-icon--[${match.icon}] ${size}"></i>`);
    }
    else{ 
        return this.dom.bypassSecurityTrustHtml(`<i class="c-icon c-icon--[document-checkmark] ${size}"></i>`);
    }
  }
}