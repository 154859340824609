import { ExternalReportingEmailType } from "./external-reporting-email-type.enum";

export enum ExternalReportProcessHistoryStatus {
    Success = 0,
    Denied = 1,
    Deleted = 2,
    Pending = 3
}

export class ExternalReportProcessHistoryModel {

  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }

    this.emailType = jsonObject.emailType;
    this.createdBy = jsonObject.createdBy;
    this.created = new Date(jsonObject.created);
    this.year = jsonObject.year;
    this.month = jsonObject.month;
    this.registrationNumber = jsonObject.registrationNumber;
    this.customerNumber = jsonObject.customerNumber;
    this.customerName = jsonObject.customerName;
    this.driverEmail = jsonObject.driverEmail;
    this.status = jsonObject.status;
    this.errorMessage = jsonObject.errorMessage;
    this.objectId = jsonObject.objectId;
    this.driverV21Sequence = jsonObject.driverV21Sequence;
    this.note = jsonObject.note;
   
  }

  emailType: number;
  createdBy: string;
  created: Date;
  year: number;
  month: number;
  registrationNumber: string;
  customerNumber: number;
  customerName: string;
  driverEmail: string;
  status: ExternalReportProcessHistoryStatus;
  errorMessage: string;
  objectId: number;
  driverV21Sequence: number;
  note: string;
  
  get date(){
    return new Date(this.year, this.month-1, 1);
  }   

  get emailTypeText(){
    switch (this.emailType){
      case ExternalReportingEmailType.MileageReportAdjusted:
        return "Kilometerrapport justerad";
        case ExternalReportingEmailType.MileageReportMileageEnd:
        return "Kilometerrapport kunde inte läsas in";
        case ExternalReportingEmailType.MileageReportMissing:
        return "Kilometerrapport saknas";
        case ExternalReportingEmailType.TollReportAdjusted:
        return "Trängselskatt justerad";
        default:
        return "";        
    }
  }

  get statusText() : string{
    switch(this.status)
    {
      case ExternalReportProcessHistoryStatus.Deleted: 
        return "Borttagen";
        case ExternalReportProcessHistoryStatus.Denied: 
        return this.errorMessage;
        case ExternalReportProcessHistoryStatus.Success: 
        return "Godkänd";
        case ExternalReportProcessHistoryStatus.Pending: 
        return "Köad";
        default:
          return '';
    }      
  }
}
