import { IValidation } from './validation.interface';

export class LesseeValidation implements IValidation { 
    
    CustomerPartPVSeq: Array<string>;
    AgreementId: Array<string>;
    BasketId: Array<string>;
    BranchNumber: Array<string>;
    InsuranceCompany: Array<string>;

    constructor(jsonObj: any = null) {
        if (jsonObj != null) {
            this.AgreementId = jsonObj.AgreementId;            
            this.CustomerPartPVSeq = jsonObj.CustomerPartPVSeq;            
            this.BasketId = jsonObj.BasketId;            
            this.BranchNumber = jsonObj.BranchNumber;            
            this.InsuranceCompany = jsonObj.InsuranceCompany;            
        }
    }

    get isValid(){
        return this.errors.length === 0; 
    }
    
    get errors(){

        var errors = new Array<string>();

        if(this.CustomerPartPVSeq)
        {
            this.CustomerPartPVSeq.forEach(mess => {
                errors.push(mess);
            });
        }      

        if(this.BasketId)
        {
            this.BasketId.forEach(mess => {
                errors.push(mess);
            });
        }      
        
        if(this.AgreementId)
        {
            this.AgreementId.forEach(mess => {
                errors.push(mess);
            });
        }      

        if(this.BranchNumber)
        {
            this.BranchNumber.forEach(mess => {
                errors.push(mess);
            });
        }      

        if(this.InsuranceCompany)
        {
            this.InsuranceCompany.forEach(mess => {
                errors.push(mess);
            });
        }      

        return errors;
    }
}
