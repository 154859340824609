import { Address } from './address.model';

export class InvoiceAddress{
    
    constructor(jsonObj : any = null) {
        if(jsonObj == null)
        {
            return;
        }
        
        this.id = jsonObj.id;        
        this.name = jsonObj.name;
        this.customerPartPVSeq = jsonObj.customerPartPVSeq;
        this.address = new Address(jsonObj.address);
    }

    id: number;
    customerPartPVSeq: number;
    name: string;
    address: Address;
}