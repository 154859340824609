import { Part } from './part.model';

export class DriverPart extends Part{
    
    constructor(jsonObj : any = null) {
        super(jsonObj);
        if (jsonObj == null) {
            return;
        }

        this.personalNumber = jsonObj.personalNumber;
        this.firstName = jsonObj.firstName;
        this.lastName = jsonObj.lastName;
    }

    firstName: string = null;
    lastName: string = null;
    personalNumber: string = null;
}
