import { IValidation } from './validation.interface';

export class LeasingParametersValidation implements IValidation{

    Price: Array<string>;
    VatDeduction: any;
    NumberOfTerms: any;
    ResidualValue: any;
    YearlyMileage: Array<string>;
    MileageInDuty: Array<string>;

    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.Price = jsonObj['Car.Price'];
            this.YearlyMileage = jsonObj['Car.YearlyMileage'];   
            this.MileageInDuty = jsonObj['Car.MileageInDuty'];
            this.VatDeduction = jsonObj.VatDeduction;
            this.NumberOfTerms = jsonObj.NumberOfTerms;
            this.ResidualValue = jsonObj.ResidualValue;
        }
    }

    get isValid(){        
        return this.errors.length == 0;
    }

    get errors(){
        var errors = new Array<string>();     
        
        if(this.Price)
        {
            this.Price.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.ResidualValue)
        {
            this.ResidualValue.forEach(mess => {
                errors.push(mess);
            });
        } 

        if(this.NumberOfTerms)
        {
            this.NumberOfTerms.forEach(mess => {
                errors.push(mess);
            });
        }      

        if(this.YearlyMileage)
        {
            this.YearlyMileage.forEach(mess => {
                errors.push(mess);
            });
        }

        if(this.MileageInDuty)
        {
            this.MileageInDuty.forEach(mess => {
                errors.push(mess);
            });
        }      
        
        if(this.VatDeduction)
        {
            this.VatDeduction.forEach(mess => {
                errors.push(mess);
            });
        }      
            
        return errors;
    }
}
