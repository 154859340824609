
export class SalaryDeductionValidation {

    SalaryDeductionGross: Array<string>;
    SalaryDeductionNet: Array<string>;

    constructor(jsonObj : any = null)
    {
        if(jsonObj != null)
        {
            this.SalaryDeductionGross = jsonObj.SalaryDeductionGross;
            this.SalaryDeductionNet = jsonObj.SalaryDeductionNet;
        }
    }
    
    get isValid(){
        return this.errors.length === 0; 
    }

    get errors(){
        var errors = new Array<string>();     
        
        if(this.SalaryDeductionGross)
        {
            this.SalaryDeductionGross.forEach(mess => {
                errors.push(mess);
            });
        }
        
        if(this.SalaryDeductionNet)
        {
            this.SalaryDeductionNet.forEach(mess => {
                errors.push(mess);
            });
        }

        return errors;
    }
}
