import { OfferDocumentModel } from './offer-document.model';
import { ExtraEquipment } from './extra-equipment.model';
import { MarketProduct } from './market-product.model';

export class CarModel {
  constructor(jsonObj: any = null) {
    this.extraEquipments = new Array<ExtraEquipment>();

    if (jsonObj == null) {
      return;
    }
    
    if (jsonObj.extraEquipments != null) {
      this.extraEquipments = jsonObj.extraEquipments.map(e => new ExtraEquipment(e));
    }

    this.driverId = jsonObj.driverId;
    this.license = jsonObj.license;
    
    if (jsonObj.marketProduct) {
      this.marketProduct = new MarketProduct(jsonObj.marketProduct);
    }
    
    this.make = jsonObj.make;
    this.model = jsonObj.model;
    this.modelShort = jsonObj.modelShort;
    this.modelCode = jsonObj.modelCode;
    this.newFlag = jsonObj.newFlag;
    this.productionYear = jsonObj.productionYear;
    this.active = jsonObj.active;

    if (jsonObj.startDate){
        this.startDate = new Date(jsonObj.startDate);
    }
    
    if (jsonObj.endDate){
      this.endDate = new Date(jsonObj.endDate);
    }
    
    if (jsonObj.agreementStartDate){
      this.agreementStartDate = new Date(jsonObj.agreementStartDate);
    }
   
    if (jsonObj.agreementEndDate){
      this.agreementEndDate = new Date(jsonObj.agreementEndDate);
    }

    this.carBenefitValue = jsonObj.carBenefitValue;
    this.reducedBenefitValue = jsonObj.reducedBenefitValue;
    this.extras = jsonObj.extras;
    this.serviceLevels = jsonObj.serviceLevels;
    this.price = jsonObj.price;
    this.mileage = jsonObj.mileage;
    this.mileageInDuty = jsonObj.mileageInDuty;
    this.makeId = jsonObj.makeId;
    this.modelId = jsonObj.modelId;
    this.modelMissing = jsonObj.modelMissing;
    this.makeMissingText = jsonObj.makeMissingText;
    this.yearlyMileage = jsonObj.yearlyMileage;
    this.offerDocument = jsonObj.offerDocument;
    this.hasOfferDocument = jsonObj.hasOfferDocument;
  }

  objectId: number;
  driverId: number;
  license: string;
  marketProduct: MarketProduct;
  make: string;
  mileage: number;
  mileageInDuty?: number;
  model: string;
  modelShort: string;
  modelCode: string;
  newFlag?: boolean = true;
  productionYear?: number;
  active: boolean;
  yearlyMileage?: number;
  startDate?: Date;
  endDate?: Date;
  agreementStartDate?: Date;
  agreementEndDate?: Date;
  carBenefitValue: number;
  reducedBenefitValue: boolean;
  extras: string[];
  serviceLevels: number[];
  price?: number = null;
  makeId?: number;
  modelId?: number;
  modelMissing: boolean;
  makeMissingText: string;
  offerDocument: OfferDocumentModel;
  extraEquipments: Array<ExtraEquipment>;
  hasOfferDocument: boolean;
  
  get totalPriceExtraEquipment(): number {
    if (this.extraEquipments && this.extraEquipments.length > 0) {
      return this.extraEquipments
        .map(e => e.price)
        .reduce((sum, current) => Number(sum) + Number(current), 0);
    }

    return 0;
  }

  get makeMissing(): boolean {
    return (this.make != null  && this.makeId == null ) ? true : false;
  }
}
