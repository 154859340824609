<div class="o-page-wrap c-mega-menu__outer-wrap u-ph-none@s">
  <div class="js-mega-menu-wrap c-mega-menu__inner-wrap js-is-loaded">
    <nav class="js-mega-menu c-mega-menu c-mega-menu--first-level-is-open js-is-loaded" [class.is-open]="active"
      appClickOutside (ClickOutside)="closeMenu()" [class.c-mega-menu--second-level-is-open]="secondLevelIsOpen()">
      <ul class="c-mega-menu__list c-mega-menu__list--level-one u-mb">
        <li class="c-mega-menu__list-title">
          Fleetweb
        </li>

        <ng-container *ngFor="let parentRoute of routeMetadata.Company.children | keyvalue:keepOrder; let parentIndex = index">

          <ng-container
            *ngIf="parentRoute.value.data && parentRoute.value.data.showInMenu && parentRoute.value.data.renderAsParent && this.hasPermission(parentRoute.value.data.permissions); else renderWithoutParent">
            <li>
              <span class="c-mega-menu__link c-mega-menu__link--level-one"
                [class.is-open-on-mobile]="this.secondLevelOpen[parentIndex]"
                [class.is-open-on-desktop]="this.secondLevelOpen[parentIndex]"
                (click)="toggleSecondLevelMenu(parentIndex)">
                <span id="menu-load-reports">
                  <span id="menu-load-reports">
                    {{parentRoute.value.data && parentRoute.value.data.title}}
                  </span>
                </span>
                <span class="c-mega-menu__arrow"></span>
              </span>
              <ul class="c-mega-menu__list c-mega-menu__list--level-two"
                [class.is-open-on-mobile]="this.secondLevelOpen[parentIndex]"
                [class.is-open-on-desktop]="this.secondLevelOpen[parentIndex]">
                <span id="menu-load-reports-level">
                  <ng-container *ngIf="parentRoute.value.children">
                    <ng-container *ngFor="let childRoute of parentRoute.value.children | keyvalue:keepOrder; let childIndex = index">

                      <app-menu-item (hideMenuInParentComponent)="closeMenu()" [route]="childRoute"></app-menu-item>

                      <ng-container *ngIf="childRoute.value.children">
                        <ng-container *ngFor="let secondChildRoute of childRoute.value.children | keyvalue:keepOrder">
                          <app-menu-item (hideMenuInParentComponent)="closeMenu()" [route]="secondChildRoute"></app-menu-item>
                        </ng-container>
                      </ng-container>

                    </ng-container>
                  </ng-container>
                </span>
              </ul>
            </li>
          </ng-container>
          <ng-template #renderWithoutParent>
            <app-menu-item (hideMenuInParentComponent)="closeMenu()" [route]="parentRoute"></app-menu-item>
          </ng-template>

        </ng-container>

        <li class="c-mega-menu__list-title">
          Information
        </li>
        <ng-container *ngFor="let parentRoute of routeMetadata.Home.children | keyvalue:keepOrder; let parentIndex = index">

          <ng-container *ngIf="parentRoute.value.data && parentRoute.value.data.showInMenu">
            <app-menu-item (hideMenuInParentComponent)="closeMenu()" [route]="parentRoute"></app-menu-item>
          </ng-container>
        </ng-container>

      </ul>
    </nav>
  </div>
</div>