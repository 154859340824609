import { CustomerPart } from "@domain/models/customer-part.model";
import { FormRoleModel } from "@domain/models/form-role.model";

export class News {

  constructor(jsonObject: any = null) {
    if (jsonObject == null) {
      return;
    }

    this.id = jsonObject.id;
    this.title = jsonObject.title;
    this.content = jsonObject.content;
    this.startDateString = jsonObject.startDateString;
    this.endDateString = jsonObject.endDateString;

    this.frontPage = jsonObject.frontPage;
    this.important = jsonObject.important;
    this.allCustomers = jsonObject.allCustomers;

    if (jsonObject.customers) {
      this.customers = new Array<CustomerPart>();

      jsonObject.customers.forEach(customer => {
        this.customers.push(new CustomerPart(customer));
      });
    }

    if (jsonObject.roles) {
      this.roles = new Array<FormRoleModel>();

      jsonObject.roles.forEach(role => {
        this.roles.push(new FormRoleModel(role));
      });
    }

    if (jsonObject.customerIds) {
      this.customerIds = new Array<number>();

      jsonObject.customerIds.forEach(customerId => {
        this.customerIds.push(customerId as number);
      });
    }

    this.active = jsonObject.active;
    this.hasBeenPublished = jsonObject.hasBeenPublished;

    this.createdAtUtc = new Date(jsonObject.createdAtUtc);
    this.createdBy = jsonObject.createdBy;
    this.updatedAtUtc = jsonObject.updatedAtUtc ? new Date(jsonObject.updatedAtUtc) : null;
    this.updatedBy = jsonObject.updatedBy;
  }

  id: number;
  title: string;
  content: string;

  startDateString: string;
  endDateString?: string;

  frontPage: boolean;
  important: boolean;
  allCustomers: boolean;

  //TODO: Det här är så konstig kod, för det är inte alls en CustomerPart på serversidan utan en NewsArticleCustomerFilter
  //Byt ut till lämpliga DTO:er för News-delarna.
  customers: CustomerPart[];

  roles: FormRoleModel[];

  customerIds: number[];

  active: boolean;
  hasBeenPublished: boolean;

  createdAtUtc?: Date;
  createdBy?: string;
  updatedAtUtc?: Date;
  updatedBy?: string;

  get noEndDate(): boolean {
    return this.endDateString == null;
  }
}
