export class AgreementFuelCard {
    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
            return;
        }

        this.sequence = jsonObj.sequence;
        this.name = jsonObj.name;
        this.v21Sequence = jsonObj.v21Sequence;
    }

    sequence: number;
    name: string;
    v21Sequence: number;
}
