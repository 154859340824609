import { CarInfoModel } from "./car-info.model";

export class CompanyCarInfoModel extends CarInfoModel {

  constructor(jsonObject: any) {
    super(jsonObject);

    this.costCenterText = jsonObject.costCenterText;
    this.residualValue = jsonObject.residualValue;
    this.currentMileage = jsonObject.currentMileage;
    this.endMileage = jsonObject.endMileage;
  }

  costCenterText: string;
  residualValue?: number;
  currentMileage?: number;
  endMileage?: number;
}
