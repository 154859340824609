import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {
    }

    @Output()
    public ClickOutside = new EventEmitter<MouseEvent>();

    @HostListener("document:click", ["$event", "$event.target"])
    @HostListener("document:touchstart", ["$event", "$event.target"])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement || !this._elementRef.nativeElement.classList.contains("is-open")) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.ClickOutside.emit(event);
        }
    }
}