import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeroModel } from '../models/hero.model';

@Injectable({
    providedIn: 'root'
})
export class HeroService {
    
    private contentSubject = new BehaviorSubject<HeroModel>(new HeroModel());
    content$ = this.contentSubject.asObservable();

    clickEvent: EventEmitter<any> = new EventEmitter();
    secondClickEvent: EventEmitter<any> = new EventEmitter();
    thirdClickEvent: EventEmitter<any> = new EventEmitter();

    constructor() { }

    set(content: HeroModel) {
        this.contentSubject.next(content);
    }

    setTitle(title: string) {
        this.contentSubject.value.title = title;
        this.contentSubject.next(this.contentSubject.value);
    }

    setSubtitle(subtitle: string) {
        this.contentSubject.value.subtitle = subtitle;
        this.contentSubject.next(this.contentSubject.value);
    }

    setButton(text: string, url: string = null) {
        this.contentSubject.value.buttonText = text;
        this.contentSubject.value.buttonUrl = url;
        this.contentSubject.next(this.contentSubject.value);
    }

    setSecondButton(text: string, url: string = null) {
        this.contentSubject.value.secondButtonText = text;
        this.contentSubject.value.secondButtonUrl = url;
        this.contentSubject.next(this.contentSubject.value);
    }
    
    setThirdButton(text: string, url: string = null) {
        this.contentSubject.value.thirdButtonText = text;
        this.contentSubject.value.thirdButtonUrl = url;
        this.contentSubject.next(this.contentSubject.value);
    }
}
