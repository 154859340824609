import { CarQuery } from './car-query-model';

export class CarAndDriverQuery extends CarQuery {

  constructor(jsonObject: any = null){    
    super(jsonObject);

    if(jsonObject === null) {
      return;
    }

    this.driverCustomerId = jsonObject.driverCustomerId;
    this.driverNameStartsWith = jsonObject.driverNameStartsWith;
    this.licenceOrDriverName = jsonObject.licenceOrDriverName;
    this.activeOrReportable = jsonObject.activeOrReportable;
    this.decorateWithFuelCards = jsonObject.decorateWithFuelCards;
    this.distinctLatestDriverPerAccount = jsonObject.distinctLatestDriverPerAccount;
    this.includeAccountsWithoutDrivers = jsonObject.includeAccountsWithoutDrivers;
    this.objectCustomerId = jsonObject.objectCustomerId;  
  }

  driverCustomerId: number = null;
  driverNameStartsWith: string = null;
  licenceOrDriverName: string = null;
  activeOrReportable: boolean = false;
  decorateWithFuelCards: boolean = false;
  distinctLatestDriverPerAccount: boolean = false;
  includeAccountsWithoutDrivers: boolean = false;
  objectCustomerId: number = null;

  public get isFiltering(): boolean {
    return this.includeInactiveAccounts ||
      this.extendedAccounts ||
      this.agreementIds.length > 0 ||
      (this.license != null && this.license.length > 0) ||
      this.accountStartBetween != null ||
      this.accountEndBetween != null ||
      (this.driverNameStartsWith != null && this.driverNameStartsWith.length > 0) ||
      (this.make != null && this.make.length > 0) ||
      (this.modelStartsWith != null && this.modelStartsWith.length > 0) ||
      this.productionYear != null ||
      (this.costCenter != null && this.costCenter.length > 0);
  }
}
