<div class="modal-header">
  <h5 class="modal-title">Information</h5>
  <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <ng-template [ngIf]="driver && !loading" [ngIfElse]="noDriver">
    <div class="o-layout">
      <div class="o-layout__item u-1/1 u-1/1@s">
        <div *ngIf="loading" class="c-card spinner-wrapper">
          <app-spinner [style]="'section'"></app-spinner>
        </div>
        <app-driver-detail-card class="mb-2" [driver]="driver"></app-driver-detail-card>
      </div>
    </div>
  </ng-template>

  <ng-template #noDriver>
    <div *ngIf="loading" class="spinner-wrapper">
      <app-spinner [style]="'section'"></app-spinner>
    </div>
    <app-error-card *ngIf="!loading"
                    [message]="errorMessage ? errorMessage : ''">
    </app-error-card>
  </ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Stäng</button>
</div>
