export class Agreement {

  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.id = jsonObj.id;
    this.name = jsonObj.name;
    this.isAdminContract = jsonObj.isAdminContract;
  }

  id: number;
  name: string;
  isAdminContract?: boolean;
}

