import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CostCenter } from '@domain/models/cost-center.model';
import { Organization } from '@domain/models/organization.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-edit-user-organization-costcenters-modal',
  templateUrl: './edit-user-organization-costcenters.component.html',
  styleUrls: ['./edit-user-organization-costcenters.component.scss']
})
export class EditUserOrganizationCostcentersComponent implements OnInit, OnDestroy {

  errorMessage: string;

  @Input() organization: Organization;
  @Input() initialCostCenterIds: Array<number>;
  @Input() hasErroneousCostCenters: boolean;

  public onClose: Subject<Array<number>> = new Subject();;
  close$ = this.onClose.asObservable();

  private _loadingHelper = new LoadingHelper();
  private _componentSubscriptions = new Array<Subscription>();
  private _costCentersFormArraySubscription: Subscription = null;
  
  formGroup: FormGroup;

  noLimitationFormControl: FormControl<boolean>;
  filterFormControl: FormControl<string>;
  costCentersFormArray: FormArray<FormControl<boolean>>;
  
  private _selectedCostCenterIds: Array<number>;
  private _filteredCostCenters: Array<CostCenter>;

  constructor(
    public modal: NgbActiveModal) { }

  ngOnInit() {
    this._selectedCostCenterIds = this.initialCostCenterIds.map(id => id);
    this._filteredCostCenters = this.organization.costCenters.map(cc => cc);
    this.initForm();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromCostCenterSelection();
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  private initForm() {
    const noLimitation = this.initialCostCenterIds.length === 0;
    this.filterFormControl = new FormControl<string>("");

    this.noLimitationFormControl = new FormControl<boolean>(noLimitation);
    this._componentSubscriptions.push(this.noLimitationFormControl.valueChanges.subscribe(value => {
      if(value) {
        this.selectedCostCenterIds.splice(0, this.selectedCostCenterIds.length);
        this.filterFormControl.setValue(null);
      }
      else{
        this.patchForm();
      }
    }));

    this._componentSubscriptions.push(this.filterFormControl.valueChanges.pipe(debounceTime(200)).subscribe(value => {
      this.refreshFilteredCostCenters();
      this.patchForm();
    }));    

    this.costCentersFormArray = new FormArray<FormControl<boolean>>([]);
    if(!noLimitation){
      this.patchForm();
    }

    this.formGroup = new FormGroup({
      noLimitations: this.noLimitationFormControl,
      filter: this.filterFormControl,
      costCenters: this.costCentersFormArray
    });
  }

  get isLoading() {
    return this._loadingHelper.isLoading;
  }

  get noLimitations() {
    return this.noLimitationFormControl.value;
  }

  get isErroneous(){
    return this.hasErroneousCostCenters;
  }

  get hasNoCostCenters(){
    return this.organization.costCenters.length === 0;
  }

  get showFilter(): boolean{
    return this.organization.costCenters.length > 10;
  }

  get showSelectAll(): boolean{
    return this.organization.costCenters.length > 1;
  }

  get selectDisabled(): boolean {
    return this.showSelectAll && 
    this.filteredCostCenters.every(cc => this._selectedCostCenterIds.find(s => s === cc.v21Sequence) != null);
  }

  get deselectDisabled(): boolean {
    return this.showSelectAll && 
    this.filteredCostCenters.every(cc => this._selectedCostCenterIds.every(s => s !== cc.v21Sequence));
  }
   
  get filteredCostCenters(): Array<CostCenter> {
    return this._filteredCostCenters;
  }

  refreshFilteredCostCenters(){
    if(this.filterFormControl.value){
      var searchString = this.filterFormControl.value;
      this._filteredCostCenters = this.organization.costCenters.filter(costCenter => {
        var isSelected = this._selectedCostCenterIds.filter(id => id === costCenter.v21Sequence).length > 0;
        return isSelected || costCenter.costCenterText.toLowerCase().search(searchString.toLowerCase()) !== -1;
      });
    }
    else{
      this._filteredCostCenters = this.organization.costCenters;
    } 
  }

  get selectedCostCenterIds () : Array<number>{
    return this._selectedCostCenterIds;
  }

  get isFiltering(): boolean{
    return this.filterFormControl.value != null && this.filterFormControl.value !== "";
  }
  
  patchForm(){
    this.unsubscribeFromCostCenterSelection();

    this.costCentersFormArray.clear();
    this.filteredCostCenters.forEach(costCenter => {
      var isSelected = this._selectedCostCenterIds.filter(id => id == costCenter.v21Sequence).length > 0;
      this.costCentersFormArray.push(new FormControl<boolean>(isSelected));
    });

    this.subscribeToCostCenterSelection();
  }

  private subscribeToCostCenterSelection() {
    this._costCentersFormArraySubscription = this.costCentersFormArray.valueChanges.subscribe(value => {
      
      this.costCentersFormArray.controls.forEach((control, index) => {
        var costCenterId = this.filteredCostCenters[index].v21Sequence;
        var selectedCostCenterIndex = this._selectedCostCenterIds.findIndex(id => id === costCenterId);

        if (control.value && selectedCostCenterIndex === -1) {
          this._selectedCostCenterIds.push(costCenterId);
        }
        else if (!control.value && selectedCostCenterIndex !== -1) {
          this._selectedCostCenterIds.splice(selectedCostCenterIndex, 1);
        }
      });      
    });
  }

  private unsubscribeFromCostCenterSelection() {
    if (this._costCentersFormArraySubscription) {
      this._costCentersFormArraySubscription.unsubscribe();
      this._costCentersFormArraySubscription = null;
    }
  }

  onClearFilter(){
    this.filterFormControl.setValue(null);
  }
  
  onCancel() {
    this.onClose.next(null);
    this.modal.close();
  }

  onChange() {
    var selectedCostCenterIds = this._selectedCostCenterIds;
    
    this.onClose.next(selectedCostCenterIds);
    this.modal.close();
  }

  onSelectAll(){
    this.costCentersFormArray.controls.forEach(control => {
      if (!control.value) { 
        control.setValue(true);   
      }
    });         
  }

  onDeselectAll(){
    this._selectedCostCenterIds.splice(0, this._selectedCostCenterIds.length);    
    this.patchForm();
  }
}
