import { Injectable } from "@angular/core";
import { UserModel } from '@core/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    private static currentUserKey = "currentUser";
    
    get currentUser(): UserModel {
        const userAsJson = sessionStorage.getItem(SessionStorageService.currentUserKey);
        try {
            return new UserModel(JSON.parse(userAsJson));
        } catch (ex) {
            return null;
        }
    }
    set currentUser(value: UserModel) {
        const userAsJson = JSON.stringify(value);
        sessionStorage.setItem(SessionStorageService.currentUserKey, userAsJson);
    }
  
    clearAll() {
        sessionStorage.clear();
    }
}