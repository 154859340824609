import { ReportingOverviewItem } from "./reporting-overview-item";

export enum MileageReportingState {
    NotAvailable,
    Available,
    Reported,
    History
}

export class MileageOverviewItem extends ReportingOverviewItem {

    constructor(jsonObject: any = null) {
       super(jsonObject);

        if(jsonObject == null)
        {
            return;
        }

        this.mileageStart = jsonObject.mileageStart;
        this.mileageEnd = jsonObject.mileageEnd;
    
        this.mileageTotal = jsonObject.mileageTotal;
        this.mileageInDuty = jsonObject.mileageInDuty;
        this.mileagePrivate = jsonObject.mileagePrivate;
    
        this.mileageRentalTotal = jsonObject.mileageRentalTotal;
        this.mileageRentalInDuty = jsonObject.mileageRentalInDuty;
        this.mileageRentalPrivate = jsonObject.mileageRentalPrivate;

        this.state = jsonObject.state;
    }

    mileageStart: number;
    mileageEnd: number;

    mileageTotal: number;
    mileageInDuty: number;
    mileagePrivate: number;

    mileageRentalTotal : number;
    mileageRentalInDuty : number;
    mileageRentalPrivate : number;   

    state: MileageReportingState;
}