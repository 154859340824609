import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'app-common-info-message',
  templateUrl: './common-info-message.component.html',
  styleUrls: ['./common-info-message.component.scss'],
  imports: [CommonModule]
})
export class CommonInfoMessageComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() error: boolean = false;

  constructor(public modal: NgbActiveModal) { }
}
