export class DriverModel {

  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.customerId = jsonObj.customerId;
    this.customerNumber = jsonObj.customerNumber;

    if (jsonObj.startDate) {
      this.startDate = new Date(jsonObj.startDate);
    }

    if (jsonObj.endDate) {
      this.endDate = new Date(jsonObj.endDate);
    }
  }

  customerId: number;
  customerNumber: number;
  startDate?: Date;
  endDate?: Date;
}
