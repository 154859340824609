import { QuerySort } from './QuerySort';
import { ReportingFilter } from './reporting-filter-model';
import { PeriodFilter } from './period-filter-model';

export class CarQuery {

  constructor(jsonObject: any = null) {

    this.pageSize = 20;
    this.page = 1;

    var initialSort = new QuerySort();
    initialSort.direction = 0;
    initialSort.field = "License";

    this.sortObjects = new Array<QuerySort>();
    this.sortObjects.push(initialSort);

    if(jsonObject == null) {        
      return;
    }

    this.page = jsonObject.page;
    this.pageSize = jsonObject.pageSize;
    this.sortObjects = jsonObject.sortObjects.map(s => new QuerySort(s));
    this.reportingFilter = jsonObject.reportingFilter;
    this.serviceLevelSequences = jsonObject.serviceLevelSequences == null ? null : jsonObject.serviceLevelSequences.map(s => s);
    this.activeDuringOneYearBackInTime = jsonObject.activeDuringOneYearBackInTime;
    this.activeDuringPeriod = jsonObject.activeDuringPeriod == null ? null : new PeriodFilter(jsonObject.PeriodFilter.from, jsonObject.PeriodFilter.to);
    this.activeAtDateString = jsonObject.activeAtDateString;
    this.activeDuringYear = jsonObject.activeDuringYear;
    this.activeDuringMonth = jsonObject.activeDuringMonth;
    this.includeInactiveAccounts = jsonObject.includeInactiveAccounts;
    this.excludeAdminAccounts = jsonObject.excludeAdminAccounts;
    this.expiringAccounts =  jsonObject.expiringAccounts;
    this.extendedAccounts = jsonObject.extendedAccounts;
    this.accountStartBetween = jsonObject.accountStartBetween == null ? null : new PeriodFilter(jsonObject.accountStartBetween.from, jsonObject.accountStartBetween.to);
    this.accountEndBetween = jsonObject.accountEndBetween == null ? null : new PeriodFilter(jsonObject.accountEndBetween.from, jsonObject.accountEndBetween.to);
    this.accountNumber = jsonObject.accountNumber;
    this.reportableAccounts = jsonObject.reportableAccounts;
    this.activeAccounts = jsonObject.activeAccounts;
    this.marketProductIds = jsonObject.marketProductIds == null ? null : jsonObject.marketProductIds.map(s => s);
    this.agreementIds = jsonObject.agreementIds == null ? null : jsonObject.agreementIds.map(s => s);
    this.costCenter = jsonObject.costCenter;
    this.objectRegistryId = jsonObject.objectRegistryId;
    this.license = jsonObject.license;
    this.licenseStartsWith = jsonObject.licenseStartsWith;
    this.make = jsonObject.make;
    this.model = jsonObject.model;
    this.modelStartsWith = jsonObject.modelStartsWith;
    this.productionYear = jsonObject.productionYear;
    this.includeExtras = jsonObject.includeExtras;
    this.decorateWithAgreementDetails = jsonObject.decorateWithAgreementDetails;
    this.decorateWithAgreementTypeNames = jsonObject.decorateWithAgreementTypeNames;
    this.decorateWithBasketInformation = jsonObject.decorateWithBasketInformation;
    this.decorateWithBranchNumber = jsonObject.decorateWithBranchNumber;
    this.decorateWithCurrentMileage = jsonObject.decorateWithCurrentMileage;
    this.decorateWithExtras = jsonObject.decorateWithExtras;
    this.decorateWithPartId = jsonObject.decorateWithPartId;
    this.decorateWithResidualValue = jsonObject.decorateWithResidualValue;
    this.decorateWithYearlyAgreedMilage = jsonObject.decorateWithYearlyAgreedMilage;    
  }

  page: number = 0;
  pageSize: number = 0;
  sortObjects: Array<QuerySort>;
  reportingFilter: ReportingFilter = null;
  serviceLevelSequences: Array<number> = new Array<number>();
  activeDuringOneYearBackInTime: boolean = null;
  activeDuringPeriod: PeriodFilter = null;
  activeAtDateString: string = null;
  activeDuringYear: number = null;
  activeDuringMonth: number = null;
  includeInactiveAccounts: boolean = false;
  excludeAdminAccounts: boolean = false;
  expiringAccounts: boolean = false;
  extendedAccounts: boolean = false;
  accountStartBetween: PeriodFilter = null;
  accountEndBetween: PeriodFilter = null;
  accountNumber: number = null;
  reportableAccounts: boolean = false;
  activeAccounts: boolean = false;
  marketProductIds: Array<number> = new Array<number>();
  agreementIds: Array<number> = new Array<number>();
  costCenter: string = null;
  objectRegistryId: number = null;
  license: string = null;
  licenseStartsWith: string = null;
  make: string = null;
  model: string = null;
  modelStartsWith: string = null;
  productionYear: number = null;
  includeExtras: boolean = false;
  decorateWithAgreementDetails: boolean = false;
  decorateWithAgreementTypeNames: boolean = false;
  decorateWithBasketInformation: boolean = false;
  decorateWithBranchNumber: boolean = false;
  decorateWithCurrentMileage: boolean = false;
  decorateWithExtras: boolean = false;
  decorateWithPartId: boolean = false;
  decorateWithResidualValue: boolean = false;
  decorateWithYearlyAgreedMilage: boolean = false;
}
