<div class="modal-header">
  <h5 class="modal-title">Information</h5>
  <button type="button" class="btn-close" aria-label="Stäng" data-dismiss="modal" (click)="closeModal()">
    
  </button>
</div>
<div class="modal-body">
  <ng-template [ngIf]="car && !loading" [ngIfElse]="noCar">
    <div class="o-layout">
      <div class="o-layout__item u-1/1 u-1/1@s">
        <div *ngIf="loading" class="c-card spinner-wrapper">
          <app-spinner [style]="'section'"></app-spinner>
        </div>
        <app-car-detail-card  [car]="car" [showDriver]="true" [showAffiliatesLink]="false"></app-car-detail-card>
      </div>
    </div>
  </ng-template>

  <ng-template #noCar>
    <div *ngIf="loading" class="spinner-wrapper">
      <app-spinner [style]="'section'"></app-spinner>
    </div>
    <app-error-card *ngIf="!loading"
                    [message]="errorMessage ? errorMessage : ''">
    </app-error-card>
  </ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Stäng</button>
</div>
