import { BaseReportModel } from "@domain/models/base-report.model"

export class MileageReportModel extends BaseReportModel {

    constructor(jsonObject: any = null){
        super(jsonObject);
        if(jsonObject == null) {
            return;
        }
        
        this.mileageStart = jsonObject.mileageStart;
        this.mileageEnd = jsonObject.mileageEnd;
        this.mileagePrivate = jsonObject.mileagePrivate;
        this.mileageRental = jsonObject.mileageRental;
        this.mileageRentalPrivate = jsonObject.mileageRentalPrivate;
        this.canUpdateMileageStart  = jsonObject.canUpdateMileageStart;
    
        this.previousYear = jsonObject.previousYear;
        this.previousMonth = jsonObject.previousMonth;
        this.previousMileageStart = jsonObject.previousMileageStart;
        this.previousMileageEnd = jsonObject.previousMileageEnd;
        
        this.nextYear = jsonObject.nextYear;
        this.nextMonth = jsonObject.nextMonth;
        this.nextMileageStart = jsonObject.nextMileageStart;
        this.nextMileageEnd = jsonObject.nextMileageEnd;
    }
    
    mileageStart?: number;
    mileageEnd?: number;
    mileagePrivate?: number;
    mileageRental?: number;
    mileageRentalPrivate?: number;
    canUpdateMileageStart: boolean;

    previousYear? : number;
    previousMonth? : number;
    previousMileageStart?: number;
    previousMileageEnd? : number;
    
    nextYear?: number;
    nextMonth?: number;
    nextMileageStart? : number;
    nextMileageEnd?: number;

    get mileageInDuty(){
        return this.mileageStart == null || this.mileageEnd == null || this.mileagePrivate == null ?
            null :
            this.mileageEnd - this.mileageStart - this.mileagePrivate;
    };

    isRental: boolean;
    isEdit: boolean;
    returnRoute?: string;
    returnRouteBreadcrumb?: string;
  
    get nextYearAndMonth(): Date {
        if(this.nextYear == null || this.nextMonth == null) {
            return null;
        }

        return new Date(this.nextYear, this.nextMonth -1, 1); //Date month is zero-based in JS
    }

    get previousYearAndMonth(): Date {
        if(this.previousYear == null || this.previousMonth == null) {
            return null;
        }

        return new Date(this.previousYear, this.previousMonth -1, 1); //Date month is zero-based in JS
    }
}
