export class FuelCard {
    constructor(jsonObject: any) {
        if (jsonObject == null) {
            return;
        }

        this.id = jsonObject.id;
        this.suborderId = jsonObject.suborderId;
        this.cardFormatId = jsonObject.cardFormatId;
        this.cardNumber = jsonObject.cardNumber;
        this.name = jsonObject.name;
    }

    id: number; // NOT NULL, Löpnummer och nyckel
    suborderId: number;//Avropsid
    cardFormatId: number; //Internt id för kort
    cardNumber: string;
    name: string;
}
