export class UserOrganizationInfo {
    constructor(jsonObject: any = null) {   
    
        if(jsonObject == null){
            return;
        }
        
        this.customerId = jsonObject.customerId;
        this.costCenterIds = jsonObject.costCenterIds ? jsonObject.costCenterIds.map(id => id) : new Array<number>();        
    }
      
    customerId: number;
    costCenterIds: Array<number> = new Array<number>();
  }
  