import { CostCenter } from "./cost-center.model";

export class Organization {
    
    constructor(jsonObject: any = null) {
        if (jsonObject == null) {
          return;
        }
    
        this.customerId = jsonObject.customerId;
        this.name = jsonObject.name;
        this.organizationNumber = jsonObject.organizationNumber;
        this.costCenters = jsonObject.costCenters ? jsonObject.costCenters.map(c => new CostCenter(c)) : new Array<CostCenter>();
    }

    customerId: number;
    name: string;
    organizationNumber: string;
    costCenters: Array<CostCenter> = new Array<CostCenter>();    
}