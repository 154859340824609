export class SalesLinkResponse {
    constructor(jsonObj: any = null) {
      if (jsonObj == null) {
        return;
      }
  
      this.link = jsonObj.link;    
    }
  
    link: string;  
  }
  