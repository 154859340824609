import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarService } from '@domain/services/car.service';
import { DriverDetailModel } from '@domain/models/driver-detail.model';
import { CarAndDriverQuery } from '@domain/models/filters/car-and-driver-query-model';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { ApplicationError } from '@core/models/application-error.model';

@Component({
  selector: 'app-driver-info-modal',
  templateUrl: './driver-info-modal.component.html',
  styleUrls: ['./driver-info-modal.component.scss']
})
export class DriverInfoModalComponent implements OnInit {
  @Input() carAndDriverQuery: CarAndDriverQuery;

  errorMessage: string;
  driver: DriverDetailModel;

  private _loadingHelper = new LoadingHelper();

  constructor(
    public modal: NgbActiveModal, 
    private _carService: CarService) { }

  ngOnInit() {
    this.getDriverDetail();
  }

  get loading() {
    return this._loadingHelper.isLoading;
  }

  closeModal() {
    this.modal.close();
  }

  private getDriverDetail() {
    this._loadingHelper.startLoading();
    this.errorMessage = null;

    this._carService.getDriverDetail(this.carAndDriverQuery).subscribe({
      next: data => {
        this.driver = data;        
      },
      error: error => {
        this._loadingHelper.stopLoading();
        this.errorMessage = ApplicationError.getMessage(error);        
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
}
