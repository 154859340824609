import { FrameAgreement } from "./frame-agreement.model";
import { Organization } from "./organization.model";

export class FrameAgreementDetails extends FrameAgreement {

    constructor(jsonObject: any = null) {
        super(jsonObject);
        if (jsonObject == null) {
          return;
        }
    
        this.organizations = jsonObject.organizations ? jsonObject.organizations.map(o => new Organization(o)): new Array<Organization>();
      }
          
      organizations : Array<Organization> = new Array<Organization>();      
}