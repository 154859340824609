import { Component, Input } from '@angular/core';
import { DriverDetailModel } from '../../../domain/models/driver-detail.model';

@Component({
  selector: 'app-driver-detail-card',
  templateUrl: './driver-detail-card.component.html',
  styleUrls: ['./driver-detail-card.component.scss']
})
export class DriverDetailCardComponent {
  @Input() driver: DriverDetailModel;
}
