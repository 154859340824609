import { ListSortDirection } from "./ListSortDirection";
export class QuerySort {

    constructor(jsonObject : any = null){
        if(jsonObject == null){
            return;
        }

        this.field = jsonObject.field;
        this.direction = jsonObject.direction;
    }

    field: string;
    direction: ListSortDirection;
}
