<div *ngIf="displaySuperUserMenu" class="super-user-menu-container">
    <app-super-user-menu></app-super-user-menu>
</div>

<div [class.super-user-main-container]="displaySuperUserMenu">
    <app-header [hidden]="hideAppHeader"></app-header>
    <ng-container *ngIf="displayHero">
        <app-hero></app-hero>
    </ng-container>

    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
