<nav [hidden]="!isVisible" class="mt-4 mb-4">  
  <div>
    <div class="d-flex justify-content-between align-items-center m-0">
      <div class="col">
        <button class="c-btn c-btn--secondary me-1" [disabled]="!previousEnabled" (click)="first()">&laquo;</button>        
        <button class="c-btn c-btn--secondary me-2" [disabled]="!previousEnabled" (click)="previous()" >&lt;</button>
        <button class="c-btn c-btn--secondary me-1" *ngFor="let page of pages" (click)="changePage(page)" [ngClass]="{'active': isActivePage(page)}">{{ page }}</button>          
        <button class="c-btn c-btn--secondary ms-1" [disabled]="!nextEnabled" (click)="next()">&gt;</button>
        <button class="c-btn c-btn--secondary ms-1" [disabled]="!nextEnabled" (click)="last()">&raquo;</button>
      </div>
      <div class="col text-center">
        <span class="no-cursor">Träffar/sida:</span>
        <button class="c-btn c-btn--secondary ms-1" *ngFor="let pageSize of pageSizes" (click)="changePageSize(pageSize, $event)" [ngClass]="{'active': isActivePageSize(pageSize)}">{{pageSize}}</button>                  
      </div>
      <div class="col text-end">
        <span *ngIf="settings.total === 1" class="totalItems no-cursor">Totalt: {{settings.total}} träff</span>
        <span *ngIf="settings.total > 1" class="totalItems no-cursor">Totalt: {{settings.total | number }} träffar</span>
      </div>
    </div>    
  </div>
</nav>

