import { UserOrganizationInfo } from "./user-organization-info.model";

export class UpdateMembershipUserRequest {
    userId : string;
    userName : string;
    roles: Array<string>;    
}

export class UpdateFleetWebUserRequest extends UpdateMembershipUserRequest {
    customerId: number;
    frameAgreementId : number;
    userOrganizationInfo: Array<UserOrganizationInfo> = new Array<UserOrganizationInfo>();
}
