import { Component, Input, Output, EventEmitter } from '@angular/core';

export class PaginationSettings {
    
    constructor(public total: number,
        public page: number,
        public pageSize: number) { }  
}

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  
    pages: number[] = [];
    pageSizes: number[] = [20, 50, 100];

    @Input() 
    get settings(): PaginationSettings {
        return this.pagerSettings;
    }
    set settings(settings: PaginationSettings) {
        this.pagerSettings = settings;
        this.updatePages();
    }          

    private pagerSettings: PaginationSettings;
    
    @Output() 
    pageChanged: EventEmitter<number> = new EventEmitter();

    @Output() 
    pageSizeChanged: EventEmitter<number> = new EventEmitter();

    isActivePage(page: number) {        
        return page === this.pagerSettings.page;
    }

    isActivePageSize(pageSize: number) {
        return pageSize === this.pagerSettings.pageSize;
    }

    get previousEnabled() {
        return this.pagerSettings.page > 1;
    }

    get nextEnabled() {        
        return this.pagerSettings.page < this.totalPages;
    }

    get isVisible() {
        return this.pagerSettings.total && this.pagerSettings.pageSize;
    }

    get totalPages() {
        return Math.ceil(this.pagerSettings.total / this.pagerSettings.pageSize);
    }

    updatePages() {
        this.pages = [];

        if (this.totalPages == 1) {
            this.pages.push(1);
        }  
        else if (this.totalPages == 2 && (this.pagerSettings.page == 1 || this.pagerSettings.page == 2)) {
            for (let i = 1; i < this.totalPages + 1; i++) {
                this.pages.push(i);
            }
        }      
        else if (this.totalPages > 2 && this.pagerSettings.page == 1) {
            for (let i = 1; i < this.pagerSettings.page + 3; i++) {
                this.pages.push(i);
            }
        }        
        else if (this.totalPages == this.pagerSettings.page && this.totalPages > 2) {
            for (let i = this.pagerSettings.page - 2; i < this.pagerSettings.page + 1; i++) {
                this.pages.push(i);
            }
        }
        else {
            if (this.pagerSettings.total >= this.pagerSettings.pageSize * this.pagerSettings.page && this.pagerSettings.page > 1) {
                for (let i = this.pagerSettings.page - 1; i < this.pagerSettings.page + 2; i++) {
                    this.pages.push(i);
                }
            }
        }
    }

    changePage(page: number) {
        this.pageChanged.emit(page);        
    }

    changePageSize(pageSize: number) {
        this.pageSizeChanged.emit(pageSize);        
    }

    first() {
        if(!this.previousEnabled){
            return;
        }

        this.changePage(1);
    }

    previous() {
        if(!this.previousEnabled){
            return;
        }

        this.changePage(--this.pagerSettings.page);
    }

    next() {
        if(!this.nextEnabled){
            return;
        }
        
        this.changePage(++this.pagerSettings.page);
    }
    
    last() {
        if(!this.nextEnabled){
            return;
        }
        this.changePage(this.totalPages);
    }
}
