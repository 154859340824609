export class BaseReportModel {

  constructor(jsonObject: any = null) {
         
    if(jsonObject == null)
    {
      return;
    }

    this.license = jsonObject.license;
    this.accountNumber = jsonObject.accountNumber;
    this.year = jsonObject.year;
    this.month = jsonObject.month;
  }

  license: string;
  accountNumber: number;
  month?: number;
  year?: number;
      
  get yearAndMonth(): Date {
    return new Date(this.year, this.month - 1, 1); //Date month is zero-based in JS
  }
}

