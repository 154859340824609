<div id="modal-5" class="c-modal" tabindex="-1" role="dialog">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__wrapper">
        <div class="c-modal__header">
            <button type="button" class="c-modal__close-btn close-5-modal" aria-label="Close" (click)="modal.close(false)"></button>
        </div>
      <div class="c-modal__content">
        <header>
          <h3 class="text-start">Logga ut alla användare</h3>
        </header>
        <p class="text-start">
          Är du säker på att du vill logga ut samtliga inloggade användare (inklusive dig själv) från FleetWeb?
        </p>
        <form [formGroup]="form">
          <fieldset class="o-fieldset mt-3 mb-4 text-start">                    
            <div class="o-fieldset__row">
              <div class="switch">
                <input type="checkbox" class="switch" id="switch-offline" formControlName="offline" >
                <label for="switch-offline">Försätt även FleetWeb i offline-läge</label>
                <small>Att försätta FleetWeb i offline-läge innebär att användarna inte kommer att kunna logga in igen tills dess att modulen tas online i Authway.</small>
                <small *ngIf="errorMessage" class="is-error">{{errorMessage}}</small>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="c-modal__footer d-flex justify-content-between">
        <button type="button" class="c-btn c-btn--secondary" (click)="modal.close(false)">
          <span class="c-btn__text">Avbryt</span>
        </button>
        <button type="button" class="c-btn close-5-btn-modal" aria-label="Close" (click)="ForceLogout()">
          <span class="c-btn__text">Ok</span>
        </button>
      </div>
    </div>
</div>
