import { Injectable } from "@angular/core";
import { CarAndDriverQuery } from "@domain/models/filters/car-and-driver-query-model";
import { SearchExternalReportQuery } from "@domain/models/filters/search-external-reports-query.model";
import { SearchFleetWebUserQuery } from "@domain/models/filters/search-fleetweb-user-query.model";
import { SearchInternalUserQuery } from "@domain/models/filters/search-internal-user-query.model";
import { SearchSuborderQuery } from "@domain/models/filters/search-suborder-query.model";

@Injectable()
export class LocalStorageService {

    private static carAndDriverQueryKey = "companyCarAndDriverQuery";
    private static searchSuborderQueryKey = "searchSuborderQuery";
    private static searchExternalMileageReportQueryKey = "searchExternalMileageReportQuery";
    private static searchExternalTollReportQueryKey = "searchExternalTollReportQuery";
    private static searchInternalUserQueryKey = "searchInternalUserQuery";
    private static searchCompanyFleetWebUserQueryKey = "searchCompanyFleetWebUserQuery";
    private static searchFleetWebUserQueryKey = "searchFleetWebUserQuery";

    
    get searchSuborderQuery() : SearchSuborderQuery {
        const queryAsJson = localStorage.getItem(LocalStorageService.searchSuborderQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
            new SearchSuborderQuery(JSON.parse(queryAsJson)) : 
            null;
    }
    set searchSuborderQuery(value: SearchSuborderQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.searchSuborderQueryKey, queryAsJson);
    }

    get carAndDriverQuery() : CarAndDriverQuery{
        const queryAsJson = localStorage.getItem(LocalStorageService.carAndDriverQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
          new CarAndDriverQuery(JSON.parse(queryAsJson)) : 
          null;               
    }
    set carAndDriverQuery(value: CarAndDriverQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.carAndDriverQueryKey, queryAsJson);
    }

    get searchExternalMileageReportQuery() : SearchExternalReportQuery{
        const queryAsJson = localStorage.getItem(LocalStorageService.searchExternalMileageReportQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchExternalReportQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchExternalMileageReportQuery(value : SearchExternalReportQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.searchExternalMileageReportQueryKey, queryAsJson);
    }

    get searchInternalUserQuery() : SearchInternalUserQuery{
        const queryAsJson = localStorage.getItem(LocalStorageService.searchInternalUserQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchInternalUserQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchInternalUserQuery(value : SearchInternalUserQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.searchInternalUserQueryKey, queryAsJson);
    }

    get searchCompanyFleetWebUserQuery() : SearchFleetWebUserQuery{
        const queryAsJson = localStorage.getItem(LocalStorageService.searchCompanyFleetWebUserQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchFleetWebUserQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchCompanyFleetWebUserQuery(value : SearchFleetWebUserQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.searchCompanyFleetWebUserQueryKey, queryAsJson);
    }

    get searchFleetWebUserQuery() : SearchFleetWebUserQuery{
        const queryAsJson = localStorage.getItem(LocalStorageService.searchFleetWebUserQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchFleetWebUserQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchFleetWebUserQuery(value : SearchFleetWebUserQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.searchFleetWebUserQueryKey, queryAsJson);
    }

    get searchExternalTollReportQuery() : SearchExternalReportQuery{
        const queryAsJson = localStorage.getItem(LocalStorageService.searchExternalTollReportQueryKey);
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchExternalReportQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchExternalTollReportQuery(value : SearchExternalReportQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(LocalStorageService.searchExternalTollReportQueryKey, queryAsJson);
    }

    clearSearchSuborderQuery() {
        localStorage.removeItem(LocalStorageService.searchSuborderQueryKey);
    }

    clearCarAndDriverQuery() {
        localStorage.removeItem(LocalStorageService.carAndDriverQueryKey);
    }

    clearAll() {
        localStorage.clear();
    }
}
