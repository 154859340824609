import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from '../../core/services/base-api.service';
import { SuborderListItem } from '../models/suborder-list-item.model';
import { QueryResult } from '../models/query-result.model';
import { SuborderQuery } from '@domain/models/filters/suborder-query.model';
import { SearchSuborderQuery } from '@domain/models/filters/search-suborder-query.model';
import { Suborder } from '@domain/models/suborder.model';
import { SuborderValidation } from '@domain/models/suborder-validation.model';
import { Addon } from "@domain/models/addon.model";
import { SearchSellerQuery } from '../models/filters/search-seller-query.model';
import { ValidationErrorResponse } from '@core/models/validation-error-response.model';
import { SellerPart } from '@domain/models/seller-part.model';

@Injectable({
  providedIn: "root"
})
export class SuborderService {

  constructor(private _apiService: BaseApiService) { }

  getSuborders(suborderQuery: SearchSuborderQuery): Observable<QueryResult<SuborderListItem>> {
    return this._apiService.get("Suborder" + suborderQuery.toQueryString())
      .pipe(map(
        data => {
          let suborderResult = new QueryResult<SuborderListItem>(data, SuborderListItem);
          return suborderResult;
        })
      );
  }

  getSuborder(suborderQuery: SuborderQuery): Observable<Suborder> {
    return this._apiService.get("Suborder/GetSuborder" + suborderQuery.toQueryString()).pipe(
      map(data => {
        let suborder = new Suborder(data);
        return suborder;
      })
    );
  }

  createSuborder(suborder: Suborder): Observable<any> {
    return this._apiService.post("Suborder/Create", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  copySuborder(suborderQuery: SuborderQuery): Observable<CopySuborderResponse> {
    return this._apiService.post("Suborder/Copy", suborderQuery).pipe(
      map(data => {
        if (data instanceof ValidationErrorResponse) {
          let response: CopySuborderResponse = {
            suborder: null,
            suborderValidation: new SuborderValidation(data.error)
          };
          return response;
        }

        let response: CopySuborderResponse = {
          suborder: new Suborder(data),
          suborderValidation: new SuborderValidation()
        };

        return response;
      })
    );
  }

  replaceExisting(license: string): Observable<any> {
    return this._apiService.post(`Suborder/ReplaceExisting/${license}`, license).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  getYearlyMileages(): Observable<any> {
    return this._apiService.get("Suborder/YearlyMileages");
  }

  updateLessee(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateLessee", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateMessage(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateMessage", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateSalaryDeduction(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateSalaryDeduction", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateLeasingParameters(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateLeasingParameters", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateDealer(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateDealer", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateDriver(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateDriver", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateAccounting(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateAccounting", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateCarSpecification(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateCarSpecification", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateAddons(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateAddons", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  updateFuelCards(suborder: Suborder): Observable<any> {
    return this._apiService.put("Suborder/UpdateFuelCards", suborder).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  uploadOfferDocument(suborderId: number, file: File): Observable<any> {
    return this._apiService.upload(`Suborder/${suborderId}/UploadOfferDocument`, file).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  getOfferDocument(suborderId: number): Observable<any> {
    return this._apiService.download(`Suborder/${suborderId}/OfferDocument`, "");
  }

  getOfferFileSize(): any {
    return this._apiService.get("Suborder/GetOfferFileSize");
  }

  deleteSuborder(id: number): Observable<void> {
    return this._apiService.delete(`Suborder/${id}`);
  }

  validate(id: number): Observable<SuborderValidation> {
    return this._apiService.post(`Suborder/${id}/Validate`, null).pipe(
      map(data => {
        if (data instanceof ValidationErrorResponse) {
          return new SuborderValidation(data.error);
        }
        return new SuborderValidation();
      })
    );
  }

  sign(id: number): Observable<any> {
    return this._apiService.post(`Suborder/${id}/Sign`, null).pipe(
      map(data => {
        return this.handleValidationResponse(data);
      })
    );
  }

  getAddons(): Observable<Array<Addon>> {
    return this._apiService.get("Suborder/GetAddons").pipe(
      map(data => {
        let addons = data.map(a => { return new Addon(a); });
        return addons;
      })
    );
  }

  private handleValidationResponse(data: any): any {
    if (data instanceof ValidationErrorResponse) {
      return new SuborderValidation(data.error);
    }

    return new Suborder(data);
  }

  searchSellers(searchSellerQuery: SearchSellerQuery): Observable<QueryResult<SellerPart>> {
    return this._apiService.get(`Suborder/SearchSellers/${searchSellerQuery.toQueryString()}`).pipe(
      map(data => {
        let result = new QueryResult<SellerPart>(data, SellerPart);
        return result;
      })
    );
  }
}

type CopySuborderResponse = {
  suborderValidation: SuborderValidation;
  suborder: Suborder;
};
