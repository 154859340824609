import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControlOptions, UntypedFormControl, Validator, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ResetPasswordModel } from '../../../domain/models/reset-password.model';
import { LoginModel } from '../../../domain/models/login.model';
import { ChangePasswordModel } from '@domain/models/change-password.model';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { ApplicationError } from '@core/models/application-error.model';
import { ApplicationService } from '@domain/services/application.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  userNameFormControl: UntypedFormControl;
  securityCodeFormControl: UntypedFormControl;
  passwordFormControl: UntypedFormControl;
  repeatPasswordFormControl: UntypedFormControl;
  patternValidator: ValidatorFn;
  lengthValidator: ValidatorFn;

  private _loadingHelper = new LoadingHelper();
  submitted = false;
  errorMessage: string;
  requiredPasswordLength = 4;
  passwordRegularExpression = "";
  isNew = false;
  isExpired = false;
  private _applicationOnline = null;
  
  constructor(private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _authenticationService: AuthenticationService,
    private _applicationService: ApplicationService) {
  }

  ngOnInit() {
    this.initForm();
    this.getPasswordValidation();
    this.getApplicationOnlineStatus();
  }

  get isLoading() : boolean{
    return this._loadingHelper.isLoading;
  }
  
  get isNotSamePassword() {
    return this.resetPasswordForm &&
      this.resetPasswordForm.errors &&
      this.resetPasswordForm.errors.notSame;
  }

  get f() { return this.resetPasswordForm.controls }

  get canLogin(){
    return !this.isLoading && this.applicationOnline;
  }
  
  get applicationOnline(){
    return this._applicationOnline;
  }

  initForm() {
    this.userNameFormControl = this._formBuilder.control("", [Validators.required]);
    this.securityCodeFormControl = this._formBuilder.control("", [Validators.required]);
    
    this.patternValidator = Validators.pattern(this.passwordRegularExpression);
    this.lengthValidator = Validators.minLength(this.requiredPasswordLength);

    this.passwordFormControl = this._formBuilder.control("", [
      Validators.required,
      this.patternValidator,
      this.lengthValidator
    ]);
    this.repeatPasswordFormControl = this._formBuilder.control("", [Validators.required]);

    this.resetPasswordForm = this._formBuilder.group(
      {
        userNameInput: this.userNameFormControl,
        securityCodeInput: this.securityCodeFormControl,
        passwordInput: this.passwordFormControl,
        passwordRepeatInput: this.repeatPasswordFormControl
      },
      { validator: this.sameInputValidator } as AbstractControlOptions
    );    

    this.route.queryParams.forEach(v => {
      if (v.new) {
        //boolean flag used to customize the UI to show texts and controls in a certain way
        this.isNew = true;
      }

      if (v.expired) {
        //boolean flag used to customize the UI to show texts and controls in a certain way
        this.isExpired = true;
      }

      if (v.username) {
        this.userNameFormControl.setValue(v.username);
      }

      if (v.code) {
        this.securityCodeFormControl.setValue(v.code);
      }
    });
  }

  getPasswordValidation = () => {
    this._loadingHelper.startLoading();
    this._authenticationService.getPasswordValidationInfo().subscribe({
      next: data => {   
        this.requiredPasswordLength = data.requiredLength;
        this.passwordRegularExpression = data.regularExpression;
        
        this.passwordFormControl.removeValidators([this.patternValidator, this.lengthValidator]);
        this.patternValidator = Validators.pattern(this.passwordRegularExpression);
        this.lengthValidator = Validators.minLength(this.requiredPasswordLength);
        this.passwordFormControl.addValidators([this.patternValidator, this.lengthValidator]);
        
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this.errorMessage =  ApplicationError.getMessage(error);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  getApplicationOnlineStatus() {
    this.errorMessage = "";
    this._applicationService.getStatus().subscribe({
      next: data => {
        this._applicationOnline = data;    
        if(!this._applicationOnline) {
          this.errorMessage = "FleetWeb är tillfälligt stängt för inloggning.";
        }
      },
      error: () => {
        //Intentionally not displaying error messages to the user when fetching online status fails.        
      },
      complete: () => {

      }
    });
  }

  sameInputValidator = (fg: UntypedFormGroup) => {
    const password = this.passwordFormControl.value;
    const repeatedPassword = this.repeatPasswordFormControl.value;

    var error = { notSame: true };

    return password === repeatedPassword
      ? null
      : error;
  };

  onSubmit() {
    this.errorMessage = "";
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      if(this.isNew || this.isExpired) {
          this.changePassword();
      }
      else {
          this.resetPassword();
      }
    }
  }

  private changePassword() {
    this._loadingHelper.startLoading();

    var changePasswordModel = new ChangePasswordModel();
    changePasswordModel.userName = this.f.userNameInput.value;
    changePasswordModel.oldPassword = this.f.securityCodeInput.value;
    changePasswordModel.newPassword = this.f.passwordInput.value;

    this._authenticationService.changePassword(changePasswordModel).subscribe({
      next: () => {
        let loginModel = new LoginModel();
        loginModel.username = changePasswordModel.userName;
        loginModel.password = changePasswordModel.newPassword;

        this.login(loginModel);                
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this.errorMessage = ApplicationError.getMessage(error);          
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private resetPassword() {
    this._loadingHelper.startLoading();

    var resetPasswordModel = new ResetPasswordModel();
    resetPasswordModel.userName = this.f.userNameInput.value;
    resetPasswordModel.securityCode = this.f.securityCodeInput.value;
    resetPasswordModel.newPassword = this.f.passwordInput.value;

    this._authenticationService.resetPassword(resetPasswordModel).subscribe({
      next: () => {
        let loginModel = new LoginModel();
        loginModel.username = resetPasswordModel.userName;
        loginModel.password = resetPasswordModel.newPassword;
        this.login(loginModel);        
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this.errorMessage = ApplicationError.getMessage(error);               
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  private login(loginModel: LoginModel) {
    this._loadingHelper.startLoading();

    this._authenticationService.login(loginModel).subscribe({
      next: () => {
        if (this._authenticationService.currentUserValue) {
          this.router.navigate(["/"]);
        }
      },
      error: (error) => {
        this._loadingHelper.stopLoading();        
        this.errorMessage = ApplicationError.getMessage(error);
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
}
