export class MarketProduct {

    constructor(jsonObj: any = null) {
        if (jsonObj == null) {
            return;
        }

        this.id = jsonObj.id;
        this.name = jsonObj.name;        
    }

    id: number;
    name: string;
}

export enum MarketProductIds {
    Personalbil = 1,
    Förmånsbil = 2,
    Verksamhetsbil = 3
}

