<div class="c-hero-teaser">
  <img src="assets/images/bg-img-desktop-hd.jpg" class="c-hero-teaser__image">
</div>
<div class="c-hero-teaser__content pt-2">
  <div class="o-page-wrap">
    <div class="c-hero-teaser__box u-pb-none">
      <div class="o-layout o-layout--middle u-mh-none u-mb-small">
        <div class="o-layout__item u-2/4 u-ph-none u-text-center">
          <img class="c-media-grid__image mt-3 mb-3" src="assets/images/fleet-support.svg">
        </div>
      </div>
      <form id="reset" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <h3>{{ isNew ? 'Du måste byta lösenord' : isExpired ? 'Ditt lösenord är för gammalt och behöver bytas' : 'Återställ lösenord' }}</h3>
        <fieldset class="o-fieldset u-mb">
          <div class="o-fieldset__row">
            <div>
              <label for="userNameInput">
                <span class="vertical-align-middle">Användarnamn</span>
              </label>
              <input id="userNameInput" formControlName="userNameInput" type="text" class="c-input__input"
                     placeholder="Användarnamn" [ngClass]="{ 'is-error': submitted && f.userNameInput.errors }" />
              <small *ngIf="!f.userNameInput.valid && (f.userNameInput.dirty || f.userNameInput.touched)" class="is-error">
                Ange användarnamn.
              </small>
            </div>
          </div>
          <div class="o-fieldset__row">
            <div>
              <label for="securityCodeInput">
                <span class="vertical-align-middle">{{ ( isNew || isExpired ) ? 'Nuvarande lösenord' : 'Säkerhetskod' }}</span>
              </label>
              <input id="securityCodeInput" formControlName="securityCodeInput" [type]="( isNew || isExpired ) ? 'password' : 'text'" class="c-input__input"
                     [placeholder]="( isNew || isExpired ) ? 'Nuvarande lösenord' : 'Säkerhetskod'" [ngClass]="{ 'is-error': submitted && f.securityCodeInput.errors }" />
              <small *ngIf="!f.securityCodeInput.valid && (f.securityCodeInput.dirty || f.securityCodeInput.touched)" class="is-error">
                {{ ( isNew || isExpired ) ? 'Ange nuvarande lösenord.' : 'Ange säkerhetskod.' }}
              </small>
            </div>
          </div>
          <div class="o-fieldset__row">
            <div>
              <label for="passwordInput">
                <span class="vertical-align-middle">Nytt lösenord</span>
              </label>
              <input id="passwordInput" formControlName="passwordInput" type="password" autocomplete="new-password" class="c-input__input"
                     placeholder="Nytt lösenord" [ngClass]="{ 'is-error': submitted && f.passwordInput.errors }" />
              <small *ngIf="!f.passwordInput.valid && (f.passwordInput.dirty || f.passwordInput.touched)" class="is-error">
                Ange ett korrekt lösenord med minst {{ requiredPasswordLength }} tecken.
              </small>
            </div>
          </div>
          <div class="o-fieldset__row">
            <div>
              <label for="passwordRepeatInput">
                <span class="vertical-align-middle">Upprepa nytt lösenord</span>
              </label>
              <input id="passwordRepeatInput" formControlName="passwordRepeatInput" type="password" autocomplete="new-password" class="c-input__input"
                     placeholder="Upprepa nytt lösenord" [ngClass]="{ 'is-error': submitted && (f.passwordRepeatInput.errors || isNotSamePassword) }" />
              <small *ngIf="!f.passwordRepeatInput.valid && (f.passwordRepeatInput.dirty || f.passwordRepeatInput.touched)" class="is-error">
                Upprepa lösenordet.
              </small>
              <small *ngIf="isNotSamePassword && (f.passwordRepeatInput.dirty || f.passwordRepeatInput.touched)" class="is-error">
                Ange samma lösenord som tidigare.
              </small>
            </div>
            <small *ngIf="errorMessage" class="is-error">{{errorMessage}}</small>
          </div>
        </fieldset>
        <div class="login-links pb-3 d-flex justify-content-end mt-auto mobile-btn-bar">
          <button type="submit" class="c-btn fob-button" [disabled]="!canLogin">
            <span class="c-btn__text">Byt lösenord och logga in</span>
          </button>
        </div>
        <app-spinner *ngIf="isLoading"></app-spinner>
      </form>
    </div>
  </div>
</div>
