export class CostCenter {

  constructor(jsonObj: any = null) {
    if (jsonObj == null) {
      return;
    }

    this.id = jsonObj.id;
    this.costCenterText = jsonObj.costCenterText;
    this.v21Sequence = jsonObj.v21Sequence;
  }

  id: number;
  costCenterText: string;
  v21Sequence: number;
}
