import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportStepContentComponent } from '../../../../../shared/components/report/report-step-content/report-step-content.component';
import { MileageReportModel } from '../../../../../domain/models/mileage-report.model';
import { AvailableMonthReport } from '../../../../../domain/models/available-month-report.model';
import { MileageReportService } from '../../../../../domain/services/mileage-report.service';
import { LoggingService } from '@core/services/logging.service';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subscription } from 'rxjs'; 
import { ApplicationError } from '@core/models/application-error.model';
import { DialogService } from '@core/services/dialog.service';

@Component({
    selector: 'app-mileage-report-select-month',
    templateUrl: './mileage-report-select-month.component.html',
    styleUrls: ['./mileage-report-select-month.component.scss']
})
export class MileageReportSelectMonthComponent extends ReportStepContentComponent<MileageReportModel> implements OnInit, OnDestroy {

    availableMonths: AvailableMonthReport[];
    errorMessage: string;
    private _loadingHelper = new LoadingHelper();
    private _componentSubscriptions = new Array<Subscription>();

    constructor(
        private _mileageReportService: MileageReportService,
        loggingService: LoggingService,
        dialogService: DialogService) {

        super(loggingService, dialogService);
    }

    ngOnInit() {
        this._componentSubscriptions.push(this._loadingHelper.loadingChanged.subscribe((isLoading) => {
            this.loadingEvent.emit(isLoading);            
        }));

        if (this.reportModel.isEdit) {
            let selectedMonth: AvailableMonthReport = {
                month: new Date(this.reportModel.year, this.reportModel.month - 1, 1),
                isValid: true,
                selected: true
            }
            this.availableMonths = [];
            this.availableMonths.push(selectedMonth);
        }
        else
            this.getAvailableMonths();

        this._componentSubscriptions.push(this.loadMonthEvent.subscribe(event => {
            if (event) {
                this.getAvailableMonths();
            }
        }));
    }

    ngOnDestroy(): void {
        this._componentSubscriptions.forEach(s => {
            s.unsubscribe();
          });
    
        this._componentSubscriptions.splice(0);  
    }

    toggleSelected(month: AvailableMonthReport) {
        let previous = month.selected;

        if (this.getSelectedMonth())
            this.getSelectedMonth().selected = false;
        month.selected = !previous;
    }

    override submit() {
        let selectedMonth = this.getSelectedMonth();
        if (selectedMonth) {
            this.reportModel.month = selectedMonth.month.getMonth() + 1; //Date month is zero-based in JS
            this.reportModel.year = selectedMonth.month.getFullYear();
            this.submitEvent.emit(true);
        }
    }

    getSelectedMonth(): AvailableMonthReport {
        let selectedMonth = this.availableMonths.filter(month => month.selected);
        if (selectedMonth)
            return selectedMonth[0];
        else
            return null;
    }

    getValidMonth(): AvailableMonthReport {
        return this.availableMonths.filter(month => month.isValid)[0];
    }

    get suggestedMonth(): AvailableMonthReport{
        let validMonths = this.availableMonths.filter(x => x.isValid);
        return validMonths.length > 0 ? validMonths[0] : null;
    }

    toggleRental() {
        this.rentalToggleEvent.emit(true);
    }

    private getAvailableMonths() {
        this.availableMonths = [];
        this._loadingHelper.startLoading();

        this._mileageReportService.getAvailableMonths(this.reportModel.license).subscribe({
            next: data => {
                if (data.length > 0) {
                    this.availableMonths = data;
                } 
                else {  
                    this.errorMessage = "Just nu finns det inga valbara månader öppna för rapportering.";
                    return;
                }
                
                if (this.reportModel.month) {
                    if (this.suggestedMonth) {
                        this.suggestedMonth.selected = true;
                    }
                }                
            },
            error: error => {
                this._loadingHelper.stopLoading();
                this.errorMessage = ApplicationError.getMessage(error);                
            },
            complete: () => {
                this._loadingHelper.stopLoading();
            }
        });
    }
}
