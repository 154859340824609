import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { LoginModel } from "../../../domain/models/login.model";
import { News } from "../../../domain/models/news.model";
import { NewsService } from "../../../domain/services/news.service";
import { Permission } from "@core/models/permissions.enum";
import { LoadingHelper } from "@shared/helpers/loading.helper";
import { ApplicationError } from "@core/models/application-error.model";
import { ApplicationService } from "@domain/services/application.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public resetForm: UntypedFormGroup;
  public userNameControl : UntypedFormControl;
  public passwordControl : UntypedFormControl;
  public resetPasswordUserNameControl : UntypedFormControl;

  public frontPageNews: News[];
  public loginSubmitted = false;
  public resetSubmitted = false;
  public resetPerformed = false;

  public errorMessage: string;
  
  private _loadingHelper = new LoadingHelper();
  private showLogin = true;  
  private returnUrl: string;

  private _applicationOnline = null;
  
  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _authenticationService: AuthenticationService,
    private _newsService: NewsService,
    private _applicationService: ApplicationService
  ) { }

  ngOnInit() {
    this.initForm();

    if (this._authenticationService.currentUserValue && !this._authenticationService.currentUserValue.isImpersonating && this._authenticationService.currentUserValue.hasPermission(Permission.ImpersonateLogin)) {
      this.router.navigate(["/super-user/impersonate"]);
      return;
    }

    if (this._authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
      return;
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.getFrontPageNews();

    this.getApplicationOnlineStatus();
  }

  get loading()  {
    return this._loadingHelper.isLoading;
  }

  get showFrontPageNews () {
    return this.hasFrontPageNews && this.showLogin;
  }

  get hasFrontPageNews () {
    return this.frontPageNews && this.frontPageNews.length > 0;
  }

  get displayReset() {
    return !this.displayLogin;
  }

  get displayLogin() {
    return this.showLogin;
  }

  get canLogin(){
    return !this.loading && this.applicationOnline;
  }
  
  get applicationOnline(){
    return this._applicationOnline;
  }

  private initForm() {

    this.userNameControl = new UntypedFormControl("", [Validators.required]);
    this.passwordControl = new UntypedFormControl("", [Validators.required]);
    this.resetPasswordUserNameControl = new UntypedFormControl("", [Validators.required]);

    this.loginForm = this.formBuilder.group({
      username: this.userNameControl,
      password: this.passwordControl
    });

    this.resetForm = this.formBuilder.group({
      username: this.resetPasswordUserNameControl
    });
  }

  public toggleDisplay = () => {
    this.resetComponent();
    this.showLogin = !this.showLogin;
  }

  getFrontPageNews() {
    this.frontPageNews = [];
    this._newsService.getFrontPageNews().subscribe({
      next: data => {
        this.frontPageNews = data;    
      },
      error: () => {
        //Intentionally not displaying error messages to the user when fetching front page news fails.        
      },
      complete: () => {

      }
    });
  }

  getApplicationOnlineStatus() {
    this.errorMessage = "";
    this._applicationService.getStatus().subscribe({
      next: data => {
        this._applicationOnline = data;    
        if(!this._applicationOnline) {
          this.errorMessage = "FleetWeb är tillfälligt stängt för inloggning.";
        }
      },
      error: () => {
        //Intentionally not displaying error messages to the user when fetching online status fails.        
      },
      complete: () => {

      }
    });
  }
 
  resetComponent() {    
    this.loginSubmitted = false;
    this.resetSubmitted = false;
    this.resetPerformed = false;

    this.errorMessage = "";
    this.loginForm.reset();
    this.resetForm.reset();

    this.getApplicationOnlineStatus();
  }

  login() {
    this.loginSubmitted = true;

    if (!this.loginForm.valid) {
      return;
    }
    
    this._loadingHelper.startLoading();
    var loginModel = new LoginModel();
    loginModel.username = this.userNameControl.value;
    loginModel.password = this.passwordControl.value;
      
    this._authenticationService.login(loginModel).subscribe({
      next: data => {
        if (data && data.requireNewPassword) {
          this.router.navigate(['/reset-password'], { queryParams: { username: loginModel.username, new: true } });
        } else if (data && data.isPasswordExpired) {
          this.router.navigate(['/reset-password'], { queryParams: { username: loginModel.username, expired: true } });
        } else {
          this.router.navigate([this.returnUrl ? this.returnUrl : '/']);
        }
      },
      error: error => {
        this._loadingHelper.stopLoading();
        this.errorMessage = ApplicationError.getMessage(error);        
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }

  reset() {
    this.resetSubmitted = true;

    if (!this.resetForm.valid) {
      return;      
    }

    this._loadingHelper.startLoading();
    var resetPasswordUserName = this.resetPasswordUserNameControl.value;
    
    this._authenticationService.requestPasswordReset(resetPasswordUserName).subscribe({
      next: () => {
        this.resetPerformed = true;        
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this.errorMessage = ApplicationError.getMessage(error);
      },
      complete: () => {
        this._loadingHelper.stopLoading();        
      },
    });
  }
}
