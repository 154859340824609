export class SearchCustomerQuery {
    
    constructor() {
        this.pageSize = 15;
        this.page = 1;
        this.searchText = null;
        this.partIds = new Array<number>();
        this.excludePartIds = new Array<number>();
    }

    pageSize: number;
    page: number;
    searchText: string;
    excludePartIds: number[];
    partIds: number[];

    toQueryString() {
        var queryString = 
        "?pagesize=" + this.pageSize + 
        "&page=" + this.page;
        
        if(this.searchText) {
            queryString += "&searchText=" +  this.searchText;
        }

        this.partIds.forEach(id => {
            queryString += "&partIds=" + id;            
        }); 

        this.excludePartIds.forEach(id => {
            queryString += "&excludePartIds=" + id;            
        }); 

        return queryString;
    }
}
