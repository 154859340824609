import { DriverModel } from "./driver.model";
import { Address } from "./address.model"
export class DriverDetailModel extends DriverModel {

  constructor(jsonObject?) {
    super(jsonObject);

    if (jsonObject == null) {
      return;
    }

    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.employeeNumber = jsonObject.employeeNumber;

    if (jsonObject.contactAddress) {
      this.contactAddress = new Address(jsonObject.contactAddress);
    }
  }

  firstName: string;
  lastName: string;
  employeeNumber: string;
  contactAddress: Address
}
