<div class="c-modal">
  <div class="c-modal__overlay"></div>
  <div class="c-modal__wrapper">
    <div class="c-modal__header">
      <button type="button" class="c-modal__close-btn close-modal" aria-label="Close"
              (click)="modal.close(file)"></button>
    </div>
    <div class="c-modal__content" style="overflow:hidden;">
      <header class="">
        <h3 class="text-start">{{title}}</h3>
      </header>

      <div *ngIf="!file" class="bg-white p-4 mb-4 text-center" appDragAndDrop
           (FileDropped)="uploadFile($event)">
        <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" [accept]="accept">
        <p><b>Dra och släpp din fil här</b></p>
        <p>eller</p>
        <button type="button" (click)="fileInput.click()" class="c-btn c-btn--secondary ps-3 pe-3 pt-2 pb-2">
          <span class="ms-2">Välj fil</span>          
          <span class="ms-2" [innerHTML]="suggestedContentType | contenttypeicon : 'c-icon--small'"></span>
        </button>
        <p *ngIf="replaceFile" class="mt-3">En fil finns redan bifogad. Ladda upp en ny fil om du vill ersätta den befintliga.</p>
        <p *ngIf="!errorMessage" class="mt-3"><small>OBS! Endast en fil upp till max {{ maxFileSizeInMb }} MB i format {{ acceptedExtensionsText }} kan laddas upp.</small></p>
        <p *ngIf="errorMessage" class="mt-3"><small class="is-error">{{ errorMessage }}</small></p>
      </div>
      <div *ngIf="file" class="bg-white p-4 mb-4 text-center">
        <div class="text-start">
          <div class="d-flex justify-content-between">
            <a class="c-link c-download-link" (click)="openFile()">
              <span class="c-link__body">
                <span class="me-1" [innerHTML]="file.type | contenttypeicon"></span>
                <span>{{ file.name }}</span>
              </span>
            </a>
            <button (click)="removeFile()" class="c-modal__close-btn"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="c-modal__footer  d-flex justify-content-between">
      <button type="button" class="c-btn c-btn--secondary" aria-label="Close"
              (click)="modal.close()">
        Avbryt
      </button>
      <button type="button" class="c-btn close-btn-modal" aria-label="Close"
              (click)="modal.close(file)" [disabled]="!file">
        Spara
      </button>
    </div>
  </div>
</div>
