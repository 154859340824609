import { Part } from './part.model';

export class SellerPart extends Part{
    
    constructor(jsonObj : any = null) {
        super(jsonObj);
        if (jsonObj == null) {
            return;
        }

        this.name = jsonObj.name;
        this.email = jsonObj.email;
        this.phone = jsonObj.phone;
    }

  name: string = null;
  phone: string = null;
  email: string = null;

}
